
import Bugsnag from '@bugsnag/js';
import {
  Box,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import {useGetPackageById} from 'api/packages';
import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import PackageForm from './components/edit/PackageForm';


export default function PackageEdit() {
  const {packageId} = useParams();
  const toast = useToast();

  const [
    {
      data: packageData,
      loading,
      error,
    },
  ] = useGetPackageById(packageId);


  useEffect(
    () => {
      if (error) {
        const errorMessage = error.message;
        toast({
          title: 'Could not get package',
          description: errorMessage,
          status: 'error',
          duration: 3000,
        });
        Bugsnag.notify(new Error(errorMessage));
      }
    },
    [error, toast],
  );

  return (
    <Box>
      {packageData && <PackageForm packageData={packageData}/>}
      {loading && <Spinner/>}
    </Box>
  );
}
