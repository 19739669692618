export const textStyles= {
  components: {
    Text: {
      variants: {
        stat: {
          color: 'grey',
          fontSize: '15px',
          ml: '5px',
        },
        title: {
          fontSize: '20px',
          fontWeight: 'bold',
          marginBottom: '20px',
          textAlign: 'center',
          mt: '0px',
        },
        custom: {
          bg: 'white',
          borderRadius: '20px',
          p: '10px',
          pl: '18px',
          h: '2.75rem',
          mb: 4,
          w: {base: '100%', md: '300px', lg: '420px'},
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          border: '1px solid rgba(0, 0, 0, 0.1)',
        },
      },
    },
  },
};
