import React, {useCallback, useEffect} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';

import Bugsnag from '@bugsnag/js';
import {useDeleteCriteria} from 'api/quality-control';

export default function DeleteCriteriaModal({
  deleteData,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (successful?: boolean) => void;
  deleteData: string;
}) {
  const [{loading: isDeleteInProgress, error: deleteErrors}, deleteCriteria]
  = useDeleteCriteria(deleteData);
  const toast = useToast();

  useEffect(() => {
    if (deleteErrors) {
      toast({
        title: 'Delete was unsuccessful!',
        description: deleteErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [deleteErrors, toast]);

  const callDelete = useCallback(async () => {
    try {
      await deleteCriteria();
      onClose(true);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  }, [deleteCriteria]);

  return (
    <Modal isOpen={isOpen && !!deleteData} onClose={() => onClose()}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Delete criteria</ModalHeader>
        <ModalCloseButton />
        <ModalBody>Are you sure you want to delete this criteria?</ModalBody>
        <ModalFooter>
          <Button onClick={() => onClose()} variant='photonMedium'>
            Cancel
          </Button>
          <Button mr={3} isLoading={isDeleteInProgress} onClick={callDelete} variant='photonLight'>
            Delete
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
