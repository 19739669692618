import {Button, Flex, FormControl, FormLabel, Input, Link, Select, Text, useToast} from '@chakra-ui/react';
import {useUpdateProjectPrices} from 'api/projects';
import {useCallback, useEffect, useState} from 'react';
import {MdDeleteForever, MdModeEdit} from 'react-icons/md';
import {CustomFieldStyle} from '../variables/styles';
import {NavLink} from 'react-router-dom';
import {ContractualPenaltyReason, contractualPenaltyReasons, ProjectPrice, ProjectPriceType} from '../constants';
import {ProjectEventType} from '../types';
import {IoAdd} from 'react-icons/io5';
import Bugsnag from '@bugsnag/js';


const getContractualChangeDefaultReason = (reason: string) => {
  if (!reason) {
    return;
  }
  return Object.keys(ContractualPenaltyReason).find((r) => r === reason) ?? ContractualPenaltyReason.OTHER;
};


function CreativeContractualFeeField(props: {
  type: ProjectPriceType;
  projectData: any;
  refetch: Function;
  event?: ProjectEventType
  setNewField?: Function
}) {
  const {type, projectData, refetch, event, setNewField} = props;
  const [isEditing, setIsEditing] = useState(false);
  const [editedPrice, setEditedPrice] = useState<number>(0);
  const [contractualPenaltyReason, setContractualPenaltyReason] = useState(
    getContractualChangeDefaultReason(event?.details.reason),
  );
  const [otherReason, setOtherReason] = useState(event?.details?.reason);
  const toast = useToast();

  const [{error: updateProjectPricesError}, updateProjectPrices] = useUpdateProjectPrices(
    projectData._id,
  );

  const handleEdit = () => {
    setIsEditing(true);
    setEditedPrice(projectData[type]);
  };

  useEffect(() => {
    if (updateProjectPricesError) {
      toast({
        title: 'Error during price correction',
        status: 'error',
        duration: 3000,
      });
    }
  }, [updateProjectPricesError]);

  const handleSavePrice = async () => {
    const currentPrice = event?.details?.creativeFee || 0;
    if (currentPrice === editedPrice) {
      toast({
        title: 'Updated price can\'t be the same as the current.',
        status: 'error',
        duration: 3000,
      });
      return;
    }
    if (
      !contractualPenaltyReason
      || (
        type === ProjectPrice.CREATIVE_CONTRACTUAL_FEE
        && contractualPenaltyReason === ContractualPenaltyReason.OTHER
        && !otherReason
      )
    ) {
      toast({
        title: 'Price correction reason is mandatory.',
        status: 'error',
        duration: 3000,
      });
      return;
    }
    const data = {
      updatedPrice: editedPrice,
      type,
      eventId: event?._id,
      contractualPenaltyReason: contractualPenaltyReason !== 'other'
        ? contractualPenaltyReason
        : otherReason,
    };
    try {
      await updateProjectPrices({data});
      toast({
        title: `${type}: ${currentPrice} --> ${editedPrice}`,
        status: 'success',
        duration: 3000,
      });
      refetch();
      setIsEditing(false);
      setNewField(false);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const handleDelete = async () => {
    if (!event) {
      setNewField(false);
      return;
    }
    const data = {
      type: ProjectPrice.CREATIVE_CONTRACTUAL_FEE,
      eventId: event._id,
    };
    try {
      await updateProjectPrices({data});
      toast({
        title: `Creative fee successfully deleted`,
        status: 'success',
        duration: 3000,
      });
      refetch();
      setIsEditing(false);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const onPriceChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    let newPrice = parseFloat(e.target.value);
    if (isNaN(newPrice)) {
      newPrice = undefined;
    }
    setEditedPrice(newPrice);
  }, []);

  return (
    <Flex direction='column'>
      <Link as={NavLink} to={`/intel/creative/${event?.details.creativeId}`}>
        {event?.details.creativeName}
      </Link>
      {isEditing
        ? <Flex direction='column' w={'100%'}>
          <Select
            onChange={(e: any) => setContractualPenaltyReason(e.target.value)}
            placeholder={'Select reason'}
            value={contractualPenaltyReason}
            bg='white'
            mb='10px'
          >
            {contractualPenaltyReasons?.map((reason: { value: string; label: string }) => (
              <option key={reason.value} value={reason.value}>
                {reason.label}
              </option>
            ))}
          </Select>
          {
            contractualPenaltyReason === ContractualPenaltyReason.OTHER
            && <Input
              placeholder='Other reason'
              bg='white'
              value={otherReason}
              mb={4}
              onChange={(e) => setOtherReason(e.target.value)}
            />
          }
          <Flex>
            <Input
              type='number'
              w='100%'
              bg='white'
              value={editedPrice?.toString() || event?.details?.creativeFee.toString()}
              mb={4}
              onChange={onPriceChange}
            />
            <Button variant='photonMedium' ml='5px' onClick={() => setIsEditing(false)}>
              Cancel
            </Button>

            <Button
              variant='photonLight'
              mt='0px'
              ml='5px'
              onClick={handleSavePrice}
            >
              Send
            </Button>
          </Flex>
        </Flex>
        : <Flex w='100%'>
          <Flex>
            <Flex {...CustomFieldStyle} w='342px'>
              <Text>{event?.details?.creativeFee || 'Add new contractual fee'} </Text>
            </Flex>
            <Button ml='5px' onClick={handleEdit}>
              <MdModeEdit size={20}/>
            </Button>
            <Button ml='5px' onClick={handleDelete}>
              <MdDeleteForever size={20}/>
            </Button>
          </Flex>
        </Flex>
      }
    </Flex>
  );
}

export default function ContractualFeeHandler(props: {
  label: string;
  projectData: any;
  refetch: Function;
}) {
  const {label, projectData, refetch} = props;
  const [newField, setNewField] = useState(false);

  return (
    <FormControl>
      {
        (
          projectData.photographer
          || projectData.events.some((e: ProjectEventType) => e.event === ProjectPrice.CREATIVE_CONTRACTUAL_FEE)
        )
        && <FormLabel display='flex' justifyContent={'space-between'} w='400px'>
          {label}
          {projectData.photographer && <Button w='50px' me='7px' onClick={() => setNewField(true)}>
            <IoAdd size={25}/>
          </Button>}
        </FormLabel>
      }
      <Flex direction='column'>
        {
          projectData.events
            .filter((e: ProjectEventType) => e.event === ProjectPrice.CREATIVE_CONTRACTUAL_FEE)
            .map(
              (e: ProjectEventType) => <Flex key={e._id}>
                <CreativeContractualFeeField
                  type={ProjectPrice.CREATIVE_CONTRACTUAL_FEE}
                  projectData={projectData}
                  event={e}
                  refetch={refetch}
                  setNewField={setNewField}
                />
              </Flex>,
            )
        }
        {
          newField
          && <CreativeContractualFeeField
            type={ProjectPrice.CREATIVE_CONTRACTUAL_FEE}
            projectData={projectData}
            refetch={refetch}
            setNewField={setNewField}
          />
        }
      </Flex>
    </FormControl>
  );
}
