import {Box, Flex, FormLabel, Text} from '@chakra-ui/react';
import {IoMdClose} from 'react-icons/io';
import {MdDone} from 'react-icons/md';
import {ShootingType, ProjectDriver, timeUnitLabels} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';

export default function DeliveryDeadlinesAndPricingViewTab(props: { packageData: PackageType }) {
  const {packageData} = props;


  return (
    <Flex
      mt='50px'
      direction={{base: 'column', md: 'row'}}
      justifyContent={'space-around'}
    >
      <Box>
        <FormLabel>Duration:</FormLabel>
        <Text
          variant='custom'
        >
          {`${packageData.duration?.amount} ${timeUnitLabels[packageData.duration?.unit]}`}
        </Text>

        {
          packageData.shootingType === ShootingType.VIDEO
           && <>
             <FormLabel>Length of video</FormLabel>
             <Text variant='custom'>
               {`${packageData.videoLength?.amount} ${timeUnitLabels[packageData.videoLength.unit]}`}
             </Text>
           </>
        }

        <FormLabel>Photographer's delivery deadline</FormLabel>
        <Text variant='custom'>
          {`${packageData.photographerDeliveryTime?.amount} ${timeUnitLabels[packageData.photographerDeliveryTime?.unit]}`}
        </Text>
        {
          packageData.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME)
          && packageData.photographerDeliveryTime?.atHour !== null
          && <Text variant='custom'>
            {`At hour: ${packageData.photographerDeliveryTime.atHour}:00`}
          </Text>
        }

        <FormLabel>Delivery deadline</FormLabel>
        <Text variant='custom'>
          {`${packageData.deliveryTime?.amount} ${timeUnitLabels[packageData.deliveryTime?.unit]}`}
        </Text>
        {
          packageData.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME)
          && packageData.deliveryTime?.atHour !== null
          && <Text variant='custom'>
            {`At hour: ${packageData.deliveryTime.atHour}:00`}
          </Text>
        }

        {
          packageData.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME) && (
            <>
              <FormLabel>Delivery on workday</FormLabel>
              {
                packageData.projectDrivers?.includes(ProjectDriver.DELIVERY_ON_WORKDAY) ? (
                  <MdDone size={30} color="green" />
                ) : (
                  <IoMdClose size={30} color="red" />
                )
              }
            </>
          )
        }
      </Box >
      <Box>
        {
          packageData.shootingType === ShootingType.PHOTO
          && <>
            <FormLabel>Images to be delivered</FormLabel>
            <Text variant='custom'>{packageData.deliveredPicsNum}</Text>
            <FormLabel>Retouched images</FormLabel>
            <Text variant='custom'>{packageData.retouchedPicsNum}</Text>
          </>
        }

        <FormLabel>Client price</FormLabel>
        <Text variant='custom'>
          {`${packageData.price?.amount} ${packageData.price?.currency}`}
        </Text>

        <FormLabel>Photographer price</FormLabel>
        <Text variant='custom'>
          {`${packageData.priceOfPhotographer?.amount} ${packageData.priceOfPhotographer?.currency}`}
        </Text>
      </Box>
    </Flex>
  );
}
