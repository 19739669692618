import {Text, Flex, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/react';
import {useGetAvailableCreativesForPackage} from 'api/staff-api';
import {AsyncSelect} from 'chakra-react-select';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {loadCreativeOptionsWithDebounce} from 'utils/load-creative-options';
import {creativeSelectorStyles} from 'views/creative/helpers/getCities';
import {PackageType, Photographer} from 'views/packages/types';

type PhotographerOption = {
  value: Photographer,
  label: string
}

export default function AddPhotographerModal(props: {
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
}) {
  const {packageState, isOpen, onClose, setPackageState} = props;
  const loadCreativeOptions = loadCreativeOptionsWithDebounce();
  const [{data: creativesData}, getCreatives] = useGetAvailableCreativesForPackage();
  const [selectedCreatives, setSelectedCreatives] = useState([]);

  const handleChange = (data: PhotographerOption[])=> {
    setSelectedCreatives(data.map((c: PhotographerOption)=>c.value));
  };

  useEffect(() => {
    getCreatives({params: {excludedIds, search: ''}});
  }, [getCreatives]);

  const addCreativesToPackage = () => {
    setPackageState(
      (prev)=> (
        {
          ...prev,
          photographers: [...prev.photographers || [], ...selectedCreatives],
        }
      ),
    );
    setSelectedCreatives([]);
    onClose();
  };

  const excludedIds = [...packageState.photographers || [], ...selectedCreatives].map((c)=>c.id);
  const defaultOptions = creativesData
    ?.filter((c: Photographer) => !excludedIds.includes(c.id))
    .map((c: Photographer) => ({value: c, label: c.name}));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align='center'>Add photographers to package</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent='center'>
            <AsyncSelect
              isMulti
              placeholder='Select photographers'
              loadOptions={(searchText, callback) =>
                loadCreativeOptions(
                  searchText,
                  excludedIds,
                  getCreatives,
                  callback,
                )
              }
              onChange={(e) => handleChange(e as PhotographerOption[])}
              chakraStyles={creativeSelectorStyles}
              components={{
                DropdownIndicator: () => null,
              }}
              value={selectedCreatives?.map(
                (c) => (
                  {
                    value: c,
                    label: c.name,
                  }
                ),
              )}
              defaultOptions={defaultOptions}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex
            w='100%'
            justifyContent='center'
          >
            <Button mr='5px' w='140px' variant='photonMedium' onClick={() => onClose()}>
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={addCreativesToPackage}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
}
