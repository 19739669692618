import {FormLabel, Input, Box, Flex, GridItem, Select, Switch} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {MdDeleteForever} from 'react-icons/md';
import {ExtraType, ProjectDriver, ExtraUnit, extraUnitLabels, Currency} from 'views/packages/constants';
import {Extra, PackageType} from 'views/packages/types';
import {PriceEditor} from './PriceEditor';

export default function ExtraEditor(props: {
    packageState: PackageType
    setPackageState: Dispatch<SetStateAction<PackageType>>
    handleSwitch: (checked: boolean, driver: string) => void
}) {
  const {packageState, setPackageState, handleSwitch} = props;

  const handleExtraDataChange = (
    value: string,
    field: keyof Extra,
    index: number,
    convertToNumber?: boolean,
  ) => {
    setPackageState(
      (prev) => {
        const updatedExtras = [...prev.extras];
        updatedExtras[index] = {
          ...updatedExtras[index],
          [field]: convertToNumber
            ? (value ? parseFloat(value.replace(',', '.')) : 0)
            : value,
        };

        return {...prev, extras: updatedExtras};
      })
    ;
  };

  const deleteExtra = (index: number) => {
    setPackageState(
      (prev)=> (
        {
          ...prev,
          extras: prev.extras.filter((e, i) => i !== index),
        }
      ),
    );
  };

  return (
    <>
      {
        packageState.extras?.map(
          (e, i)=> (
            <GridItem
              key={i}
              display='flex'
              flexDirection='column'
              alignItems='center'
            >
              {
                packageState.extraTypes?.includes(ExtraType.BASIC_EXTRA_IMAGES)
                && e.name === 'extraPics'
                  ? <Box w='400px' key={i}>
                    <FormLabel fontWeight='bold'>Basic extra images</FormLabel>

                    <FormLabel mb='0px'>Compensation for -7 images submitted for client,</FormLabel>
                    <FormLabel>-15 images submitted for photographer</FormLabel>
                    <Switch
                      mb={4}
                      size='md'
                      defaultChecked={packageState.projectDrivers?.includes(ProjectDriver.PRICING_EXTRA_PICS_WITH_CORRECTIONS)}
                      onChange={(e)=>handleSwitch(e.target.checked, ProjectDriver.PRICING_EXTRA_PICS_WITH_CORRECTIONS)}
                    />
                    <FormLabel>Item appearing on the invoice</FormLabel>
                    <Input
                      variant='formInput'
                      onChange={(e)=> handleExtraDataChange(e.target.value, 'invoiceName', i)}
                      defaultValue={packageState.extras[i]?.invoiceName}
                    />

                    <FormLabel>Currency</FormLabel>
                    <Select
                      bg='white'
                      borderRadius='15px'
                      mb={4}
                      onChange={(e)=> handleExtraDataChange(e.target.value, 'currency', i)}
                      defaultValue={packageState.extras[i]?.currency}
                      placeholder='Select currency'
                    >
                      {
                        Object.values(Currency)?.map(
                          (c: string) => (
                            <option key={c} value={c}>
                              {c}
                            </option>
                          ),
                        )
                      }
                    </Select>

                    <PriceEditor
                      packageState={packageState}
                      label={'Client extra price'}
                      handleExtraDataChange={handleExtraDataChange}
                      id={'amount'}
                      extraIndex={i}
                    />

                    <PriceEditor
                      packageState={packageState}
                      label={'Photographer extra price'}
                      handleExtraDataChange={handleExtraDataChange}
                      id={'creativeAmount'}
                      extraIndex={i}
                    />
                  </Box>
                  : <Box w='400px'>
                    <Flex>
                      <FormLabel fontWeight='bold'>Other extra</FormLabel>
                      <Box cursor='pointer'>
                        <MdDeleteForever size={20} onClick={()=>deleteExtra(i)}/>
                      </Box>
                    </Flex>

                    <FormLabel>Extra name</FormLabel>
                    <Input
                      variant='formInput'
                      onChange={(e)=> handleExtraDataChange(e.target.value, 'name', i)}
                      defaultValue={packageState.extras[i]?.name }
                    />

                    <FormLabel>Item appearing on the invoice </FormLabel>
                    <Input
                      variant='formInput'
                      onChange={(e)=> handleExtraDataChange(e.target.value, 'invoiceName', i)}
                      defaultValue={packageState.extras[i]?.invoiceName }
                    />

                    <FormLabel>Currency</FormLabel>
                    <Select
                      bg='white'
                      borderRadius='15px'
                      mb={4}
                      onChange={(e)=> handleExtraDataChange(e.target.value, 'currency', i)}
                      defaultValue={packageState.extras[i]?.currency}
                      placeholder='Select currency'

                    >
                      {
                        Object.values(Currency)?.map(
                          (c: string) => (
                            <option key={c} value={c}>
                              {c}
                            </option>
                          ),
                        )
                      }
                    </Select>

                    <FormLabel>Unit</FormLabel>
                    <Select
                      placeholder='Select unit'
                      bg='white'
                      borderRadius='15px'
                      mb={4}
                      onChange={(e)=> handleExtraDataChange(e.target.value, 'unit', i)}
                    >
                      {
                        Object.values(ExtraUnit).map(
                          (value: string) => (
                            <option key={value} value={value}>
                              {extraUnitLabels[value]}
                            </option>
                          ),
                        )
                      }
                    </Select>

                    <PriceEditor
                      packageState={packageState}
                      label={'Client extra price'}
                      handleExtraDataChange={handleExtraDataChange}
                      id={'amount'}
                      extraIndex={i}
                    />

                    <PriceEditor
                      packageState={packageState}
                      label={'Photographer extra price'}
                      handleExtraDataChange={handleExtraDataChange}
                      id={'creativeAmount'}
                      extraIndex={i}
                    />
                  </Box>
              }
            </GridItem>
          ),
        )
      }
    </>
  );
}
