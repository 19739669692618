import {Select} from '@chakra-ui/react';
import {UseFormRegister} from 'react-hook-form';
import {Currency} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';

export default function CurrencySelector(props: {
  register: UseFormRegister<PackageType>
  id: 'price'
  | 'priceOfPhotographer'
  | 'dynamicPricing'
  | 'manualEditingPrice'
  | 'editingPrice'
}) {
  const {register, id} = props;
  return (
    <Select
      {...register(`${id}.currency`)}
      bg='white'
      borderRadius='15px'
      mb={4}
      placeholder='Select currency'
    >
      {
        Object.values(Currency)?.map(
          (c: string) => (
            <option key={c} value={c}>
              {c}
            </option>
          ),
        )
      }
    </Select>
  );
}
