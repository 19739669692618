import {FormLabel, Text, Box, Grid, GridItem, Flex} from '@chakra-ui/react';
import {IoMdClose} from 'react-icons/io';
import {MdDone} from 'react-icons/md';
import {extraUnitLabels, ProjectDriver} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';

export default function ExtrasViewTab(props: { packageData: PackageType }) {
  const {packageData} = props;

  return (
    <>
      <Box>
        <Text align='center' fontSize='16px' mb='40px' fontWeight='bold'>
          {packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC) && 'Dynamic extras'}
          {!packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC) && (
            <>
              {packageData.extras.length === 1 && packageData.extras[0]?.name === 'extraPics' && 'Basic extra images'}
              {packageData.extras.length > 1 && packageData.extras?.some((extra) => extra.name === 'extraPics') && 'Basic extra images & Other extras'}
              {packageData.extras.length === 1 && packageData.extras[0]?.name !== 'extraPics' && 'Other extras'}
              {packageData.extras.length > 1 && !packageData.extras?.some((extra) => extra.name === 'extraPics') && 'Other extras'}
            </>
          )}
        </Text>
      </Box>
      {
        packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC)
        && <Flex justifyContent={'space-evenly'}>
          <Box mb={4}>
            <FormLabel>Compensation by expected number of photos</FormLabel>
            {
              packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_WITH_CORRECTION) ? (
                <MdDone size={30} color="green" />
              ) : (
                <IoMdClose size={30} color="red" />
              )
            }
          </Box>
          <Box mb={4}>
            <FormLabel mb='0px'>Compensation for -7 images submitted for client,</FormLabel>
            <FormLabel> -15 images submitted for photographer</FormLabel>                {
              packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_WITH_THRESHOLD_CORRECTION) ? (
                <MdDone size={30} color="green" />
              ) : (
                <IoMdClose size={30} color="red" />
              )
            }
          </Box>
          <Box mb={4}>
            <FormLabel>Over limit fixed extra calculation</FormLabel>
            {
              packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT)
                ? (
                  <MdDone size={30} color="green" />
                ) : (
                  <IoMdClose size={30} color="red" />
                )
            }
          </Box>
        </Flex>
      }

      <Grid
        templateColumns="repeat(2, 1fr)"
        gap={4}
        autoFlow="row dense"
      >
        {
          packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC) && (
            <>
              <GridItem display='flex' flexDirection='column' alignItems='center' >
                <Box>


                  <FormLabel>Multiplier</FormLabel>
                  <Text variant='custom'>{packageData.dynamicPricing.rate}</Text>

                  <FormLabel>Starting extra image price for client</FormLabel>
                  <Text variant='custom'>
                    {`${packageData.dynamicPricing.unitPriceForClient} ${packageData.dynamicPricing.currency}`}
                  </Text>

                  <FormLabel>Starting extra image price for photographer</FormLabel>
                  <Text variant='custom'>
                    {`${packageData.dynamicPricing.unitPriceForCreative} ${packageData.dynamicPricing.currency}`}
                  </Text>

                  {
                    packageData.manualEditingEnabled && <>
                      <FormLabel>Starting extra image price for editor</FormLabel>
                      <Text variant='custom'>
                        {`${packageData.dynamicPricing.unitPriceForEditor} ${packageData.dynamicPricing.currency}`}
                      </Text>
                    </>
                  }
                </Box>
              </GridItem>
              <GridItem display='flex' flexDirection='column' alignItems='center' >
                <Box>

                  {
                    packageData.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT)
                    && <>
                      <FormLabel>Dynamic extra image upper limit</FormLabel>
                      <Text variant='custom'>
                        {packageData.dynamicPricing.upperLimit}
                      </Text>

                      <FormLabel>Fixed extra image price for client over limit</FormLabel>
                      <Text variant='custom'>
                        {`${packageData.dynamicPricing.aboveLimitUnitPriceForClient} ${packageData.dynamicPricing.currency}`}
                      </Text>

                      <FormLabel>Fixed extra image price for photographer over limit</FormLabel>
                      <Text variant='custom'>
                        {`${packageData.dynamicPricing.aboveLimitUnitPriceForCreative} ${packageData.dynamicPricing.currency}`}
                      </Text>

                      {
                        packageData.manualEditingEnabled
                        && <>
                          <FormLabel>Fixed extra image price for editor over limit</FormLabel>
                          <Text variant='custom'>
                            {`${packageData.dynamicPricing.aboveLimitUnitPriceForEditor} ${packageData.dynamicPricing.currency}`}
                          </Text>
                        </>
                      }
                    </>
                  }
                </Box>
              </GridItem>
            </>

          )
        }

        {
          packageData.extras?.some((extra) => extra.name === 'extraPics')
          && <GridItem display='flex' flexDirection='column' alignItems='center'>
            <Box>
              <FormLabel fontWeight='bold'>Basic extra images</FormLabel>

              <FormLabel>Compensation for -7 images submitted</FormLabel>
              {packageData.projectDrivers?.includes(ProjectDriver.PRICING_EXTRA_PICS_WITH_CORRECTIONS) ? (
                <MdDone size={30} color="green" />
              ) : (
                <IoMdClose size={30} color="red" />
              )}
              <FormLabel>Item appearing on the invoice</FormLabel>
              <Text variant='custom'>
                {packageData.extras.find((e)=>e.name === 'extraPics').invoiceName}
              </Text>

              <FormLabel>Currency</FormLabel>
              <Text variant='custom'>
                {packageData.extras.find((e)=>e.name === 'extraPics').currency}
              </Text>

              <FormLabel>Client extra price</FormLabel>
              <Text variant='custom'>
                {packageData.extras.find((e)=>e.name === 'extraPics').amount}
              </Text>

              <FormLabel>Photographer extra price</FormLabel>
              <Text variant='custom'>
                {packageData.extras.find((e)=>e.name === 'extraPics').creativeAmount}
              </Text>
            </Box>
          </GridItem>
        }

        {
          packageData.extras?.some((extra) => extra.name !== 'extraPics')
          && packageData.extras.filter((e)=> e.name !== 'extraPics').map(
            (e)=> <GridItem display='flex' flexDirection='column' alignItems='center' >
              <Box>
                <FormLabel fontWeight='bold'>Other extra</FormLabel>

                <FormLabel>Extra name</FormLabel>
                <Text variant='custom'>{e.name}</Text>

                <FormLabel>Item appearing on the invoice</FormLabel>
                <Text variant='custom'>{e.invoiceName}</Text>

                <FormLabel>Unit</FormLabel>
                <Text variant='custom'>{extraUnitLabels[e.unit]}</Text>

                <FormLabel>Currency</FormLabel>
                <Text variant='custom'>{e.currency}</Text>

                <FormLabel>Client extra price</FormLabel>
                <Text variant='custom'>{e.amount}</Text>

                <FormLabel>Photographer extra price</FormLabel>
                <Text variant='custom'>{e.creativeAmount}</Text>
              </Box>
            </GridItem>,
          )
        }
      </Grid>
    </>
  );
}
