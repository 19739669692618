import {Box, Flex, useDisclosure} from '@chakra-ui/react';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {EditingType} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';
import {getEditingSchema} from '../../shemas';
import {Dispatch, SetStateAction, useState} from 'react';
import FormErrors from '../../FormErrors';
import FormStepperButtons from '../../TabStepperButtons';
import AddEditorsModal from './AddEditorsModal';
import AiEditing from './AiEditing';
import ManualEditing from './ManualEditing';

export default function EditingEditTab(props: {
  prevFormStep: () => void,
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
  startFinishProcess: () => void
}) {
  const {packageState, setPackageState, prevFormStep, startFinishProcess} = props;
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [configErrors, setConfigErrors] = useState({});

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    resolver: zodResolver(getEditingSchema(packageState)),
    defaultValues: packageState,
  });

  const onSubmit = (data: PackageType) => {
    const newErrors: Record<string, { message: string }> = {};

    packageState.imageEditingConfigs?.forEach(
      (config) => {
        if (!config.name) {
          newErrors['name'] = {message: 'Config name is required'};
        }
      });

    if (Object.keys(newErrors).length > 0) {
      setConfigErrors(newErrors);
      return;
    }
    setPackageState((prevState) => (
      {
        ...prevState,
        ...data,
      }
    ));

    startFinishProcess();
  };

  const handleNumberInputChange = (stringValue: string, name: string) => {
    const value = stringValue ? parseFloat(stringValue.replace(',', '.')) : 0;
    setValue(name as keyof PackageType, value);
  };

  return (
    <Box w='100%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box>
          {
            packageState.editingSettings.includes(EditingType.MANUAL_EDITING)
            && <ManualEditing
              packageState={packageState}
              setPackageState={setPackageState}
              register={register}
              onOpen={onOpen}
            />
          }

          {
            packageState.editingSettings.includes(EditingType.AI_EDITING)
            && <AiEditing
              packageState={packageState}
              setPackageState={setPackageState}
              handleNumberInputChange={handleNumberInputChange}
              register={register}
            />
          }
        </Box>

        <Flex
          w='100%'
          justifyContent='center'
          direction='column'
          alignItems='center'
        >
          <FormErrors errors={{...errors, ...configErrors}}/>
          <FormStepperButtons prevFormStep={prevFormStep} isLastStep={true}/>
        </Flex>
      </form>
      <AddEditorsModal isOpen={isOpen} onClose={onClose} packageState={packageState} setPackageState={setPackageState}/>
    </Box>
  );
}
