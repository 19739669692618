import MenuLayout from 'layouts/MenuLayout';
import RoleGuard from 'layouts/RoleGuard';
import {adminRoles} from 'variables/roles';
import EditorFeedbacks from './EditorFeedbacks';
import {ListQueryProvider} from 'contexts/useListQueryContext';
import {EditorFeedbackFilterTypes} from './types';
import {defaultEditorFeedbackFilters} from './EditorFeedbackFilters';


export default function EditorFeedbacksPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <ListQueryProvider<EditorFeedbackFilterTypes>
        defaultFilters={defaultEditorFeedbackFilters}
        defaultSortField={'editorDeliveryDate'}
      >
        <MenuLayout page={<EditorFeedbacks />} />
      </ListQueryProvider>
    </RoleGuard>
  ); ;
}
