import React, {useCallback, useEffect, useState} from 'react';
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Select,
  Textarea,
} from '@chakra-ui/react';
import {z} from 'zod';
import Bugsnag from '@bugsnag/js';
import {CreateCriteria, UpdateCriteria, useCreateCritaria, useUpdateCriteria} from 'api/quality-control';
import {deliveryCriteriaCategoryLabels, DeliveryCriteriaStatus, deliveryCriteriaStatusLabels} from './constants';
import {Criteria} from './types';


export const defaultForm = {
  _id: '',
  name: '',
  status: '',
  category: '',
};

const validators = {
  name: z.string().min(1, 'Name is required, minimum 3 characters'),
  status: z.enum([DeliveryCriteriaStatus.ACTIVE, DeliveryCriteriaStatus.DEACTIVATED], {errorMap: () => ({message: 'Must select a valid status'})}),
};

const formValidator = z.object({
  name: validators.name,
  status: validators.status,
});

export default function CreateQualityControlCriteriaModal({
  selectedCriteria,
  isOpen,
  onClose,
  toCreate,
}: {
  selectedCriteria?: Criteria
  isOpen: boolean;
  onClose: (successfulUpdate?: boolean) => void;
  toCreate?: boolean
}) {
  const toast = useToast();
  const [formData, setFormData] = useState({
    _id: selectedCriteria?._id || defaultForm._id,
    name: selectedCriteria?.name || defaultForm.name,
    status: selectedCriteria?.status || defaultForm.status,
    category: selectedCriteria?.category || defaultForm.category,
  });
  const [errors, setErrors] = useState('');

  const [
    {
      loading: isCreateInProgress,
      error: createErrors,
    },
    createCriteria,
  ] = useCreateCritaria();

  const [
    {
      loading: isUpdateInProgress,
      error: updateErrors,
    },
    updateCriteria,
  ] = useUpdateCriteria(selectedCriteria?._id);

  useEffect(() => {
    if (selectedCriteria) {
      setFormData({
        _id: selectedCriteria?._id || defaultForm._id,
        name: selectedCriteria?.name || defaultForm.name,
        status: selectedCriteria?.status || defaultForm.status,
        category: selectedCriteria?.category || defaultForm.category,
      });
    }
  }, [selectedCriteria]);

  useEffect(() => {
    if (createErrors || updateErrors) {
      toast({
        title: `${createErrors ? 'Create' : 'Update'} was unsuccessful!`,
        description: createErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [createErrors, updateErrors, toast]);

  const callCreate = useCallback(async () => {
    try {
      const templateData = formData as CreateCriteria;
      try {
        formValidator.parse(templateData);
      } catch (e) {
        Bugsnag.notify(e as Error);
      }

      await createCriteria({data: templateData});
      toast({
        title: `Criteria created successfully`,
        status: 'success',
        duration: 3000,
      });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  }, [formData, createCriteria, setFormData, onClose]);


  const callUpdate = useCallback(async () => {
    try {
      const templateDate = formData as UpdateCriteria;
      try {
        formValidator.parse(formData);
      } catch (e) {
        Bugsnag.notify(e as Error);
        return;
      }
      await updateCriteria({data: templateDate});
      toast({
        title: `Criteria updated successfully`,
        status: 'success',
        duration: 3000,
      });
      setFormData(defaultForm);
      onClose(true);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  }, [formData, updateCriteria, setFormData, onClose]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setFormData(defaultForm);
        setErrors('');
        onClose();
      }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {`${toCreate ? 'Create' : 'Update'} quality control criteria`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl id='name' mb='10px'>
            <FormLabel>Name</FormLabel>
            <Textarea
              value={formData.name}
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  name: e.target.value,
                }))
              }
              placeholder='Select name'

            />
          </FormControl>

          <FormControl id='status' mb='10px'>
            <FormLabel>Status</FormLabel>
            <Select
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  status: e.target.value,
                }))
              }
              placeholder='Select status'
              value={formData.status}
            >
              {
                Object.entries(deliveryCriteriaStatusLabels).map(([k, v]) =>
                  <option key={k} value={k}>
                    {v}
                  </option>,
                )
              }
            </Select>
          </FormControl>

          <FormControl id='status' mb='10px'>
            <FormLabel>Category</FormLabel>
            <Select
              onChange={(e) =>
                setFormData((prev: any) => ({
                  ...prev,
                  category: e.target.value,
                }))
              }
              placeholder='Select status'
              value={formData.category}
            >
              {
                Object.entries(deliveryCriteriaCategoryLabels).map(
                  ([k, v]) =>
                    <option key={k} value={k}>
                      {v}
                    </option>,
                )
              }
            </Select>
          </FormControl>

          {errors && (
            <Alert status='error' my={2}>
              <AlertIcon />
              <AlertTitle mr={2}>{errors}</AlertTitle>
            </Alert>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            mr={3}
            isLoading={isCreateInProgress || isUpdateInProgress}
            onClick={toCreate ? callCreate : callUpdate}
            variant='photonLight'
            isDisabled = {Object.entries(formData).some(([k, v])=> k !== '_id' && !v)}
          >
            Save
          </Button>
          <Button
            variant='photonMedium'
            onClick={() => {
              setFormData(defaultForm);
              setErrors('');
              onClose();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
