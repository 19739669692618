import MenuLayout from 'layouts/MenuLayout';
import RoleGuard from 'layouts/RoleGuard';
import {adminRoles} from 'variables/roles';
import ClientOwnerModifyRequestList from './ClientOwnerModifyRequestList';

export default function ClientOwnerModifyRequestListPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<ClientOwnerModifyRequestList />} />
    </RoleGuard>
  );
}
