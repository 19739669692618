import {FormLabel, Flex, Input, Select, InputGroup, InputRightElement, Text} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {UseFormRegister, UseFormSetValue} from 'react-hook-form';
import {hourOptions, TimeUnit, timeUnitLabels} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';

export default function DeliveryDeadlineEditor(props: {
  handleNumberInputChange: (numberString: string, name: string) => void
  setPackageState: Dispatch<SetStateAction<PackageType>>
  packageState: PackageType,
  register: UseFormRegister<PackageType>
  deliveryAtTimeEnabled: boolean
  setValue: UseFormSetValue<PackageType>
  label: string
  id: 'deliveryTime' | 'photographerDeliveryTime'
}) {
  const {
    handleNumberInputChange,
    deliveryAtTimeEnabled,
    packageState,
    register,
    setValue,
    label,
    id,
  } = props;

  const handleAtHourChange =(value: string)=> {
    handleNumberInputChange(value, `${id}.atHour`);
    setValue(`${id}.unit` as keyof PackageType, TimeUnit.DAYS);
  };

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Flex>
        <InputGroup>
          <Input
            variant='formInput'
            type='number'
            onChange={(e)=>handleNumberInputChange(e.target.value, `${id}.amount`)}
            defaultValue={packageState[id]?.amount}
            mr={1}
            w='207px'
          />
          <InputRightElement width="3rem" pr='20px'>
            {
              deliveryAtTimeEnabled
            && <Text>
              {timeUnitLabels[TimeUnit.DAYS]}
            </Text>
            }
          </InputRightElement>
        </InputGroup>
        {
          deliveryAtTimeEnabled
            ? <Select
              bg='white'
              borderRadius='15px'
              mb={4}
              onChange={(e)=>handleAtHourChange(e.target.value)}
              defaultValue= {packageState[id]?.atHour}
            >
              <option key={''} value={''}>
                {`Select hour`}
              </option>
              {
                hourOptions.map(
                  (hour: number) => (
                    <option key={hour} value={hour}>
                      {`${hour}:00`}
                    </option>
                  ),
                )
              }
            </Select>
            : <Select
              {...register(`${id}.unit`)}
              bg='white'
              borderRadius='15px'
              mb={4}
            >
              <option key={''} value={''}>
                {`Select unit`}
              </option>
              {
                Object.values(TimeUnit)?.map(
                  (unit: string) => (
                    <option key={unit} value={unit}>
                      {timeUnitLabels[unit]}
                    </option>
                  ),
                )
              }
            </Select>
        }
      </Flex>
    </>

  );
}
