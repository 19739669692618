import React, {useCallback, useEffect} from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';

import {useDeleteRecruitment} from 'api/recruitment';
import Bugsnag from '@bugsnag/js';


export default function DeleteRecruitmentModal({
  deleteData,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (successful?: boolean) => void;
  deleteData: string;
}) {
  const [{loading: isDeleteInProgress, error: deleteErrors}, deleteRecruitment]
    = useDeleteRecruitment();
  const toast = useToast();

  useEffect(() => {
    if (deleteErrors) {
      toast({
        title: 'Delete was unsuccessful!',
        description: deleteErrors.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [deleteErrors, toast]);

  const callDelete = useCallback(async () => {
    try {
      await deleteRecruitment({data: {_id: deleteData}});
      onClose(true);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  }, [deleteRecruitment]);

  return <Modal isOpen={isOpen && !!deleteData} onClose={() => onClose()}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Delete recruitment</ModalHeader>
      <ModalCloseButton />
      <ModalBody>Are you sure you want to delete this recruitment?</ModalBody>
      <ModalFooter>
        <Button mr={3} isLoading={isDeleteInProgress} onClick={callDelete} variant='photonMedium'>
          Delete
        </Button>
        <Button onClick={() => onClose()} variant='photonLight'>
          Cancel
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>;
}
