import {Text, FormLabel, Flex, Input, Image, Box, Divider, Button, Switch, Grid, GridItem, Select} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {UseFormRegister} from 'react-hook-form';
import {IoMdAdd} from 'react-icons/io';
import {MdDeleteForever} from 'react-icons/md';
import {FilePreview, ImageEditingConfig, PackageType} from 'views/packages/types';
import PriceEditor from '../delivery-deadlines-and-pricing/PriceEditor';
import {useGetAllTemplates} from 'api/photoroom-templates';

export default function AiEditing(props: {
  setPackageState: Dispatch<SetStateAction<PackageType>>
  handleNumberInputChange: (numberString: string, name: string) => void
  packageState: PackageType,
  register: UseFormRegister<PackageType>
}) {
  const {handleNumberInputChange, packageState, setPackageState, register} = props;
  const [
    {data: templates},
  ] = useGetAllTemplates();

  const handleEditingConfigChange = (
    value: string | number | string[] | boolean | FilePreview,
    field: keyof ImageEditingConfig,
    index: number,
    convertToNumber?: boolean,
  ) => {
    setPackageState((prev) => {
      const updatedConfigs = [...prev.imageEditingConfigs];
      updatedConfigs[index] = {
        ...updatedConfigs[index],
        [field]: convertToNumber ? Number(value): value,
      };

      return {...prev, imageEditingConfigs: updatedConfigs};
    });
  };

  const handleRemoveConfig = (index: number) => {
    setPackageState((prev) => {
      return {
        ...prev,
        imageEditingConfigs: prev.imageEditingConfigs.filter((c, i)=> i !== index),
      };
    });
  };

  const addEditingConfig = () => {
    setPackageState((prev) => {
      const newConfigObj={
        name: '',
        templates: [] as string[],
        notMandatory: true,
      };
      const updatedConfigs = [...prev.imageEditingConfigs || [], newConfigObj];

      return {
        ...prev,
        imageEditingConfigs: updatedConfigs,
      };
    });
  };

  const handleConfigTemplateChange = (
    value: string,
    index: number,
    configObj: ImageEditingConfig,
    configIndex: number,
  ) => {
    const updatedTemplates = configObj.templates;
    updatedTemplates[index] = value;
    handleEditingConfigChange(updatedTemplates, 'templates', configIndex);
  };

  const handleRemoveTemplate = (
    template: string,
    configIndex: number,
    configObj: ImageEditingConfig,
  ) => {
    const updatedTemplates = configObj.templates.filter((t)=> t!== template);
    handleEditingConfigChange(updatedTemplates, 'templates', configIndex);
  };

  const addTemplate = (
    configIndex: number,
    configObj: ImageEditingConfig,
  ) => {
    const updatedTemplates = [...configObj.templates || [], ''];
    handleEditingConfigChange(updatedTemplates, 'templates', configIndex);
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>, configIndex: number) => {
    const file = event.target.files[0];
    const filePreview: FilePreview = {
      file: file,
      previewUrl: URL.createObjectURL(file),
    };
    handleEditingConfigChange(filePreview, 'examplePicture', configIndex);
  };

  return (
    <Flex
      justifyContent='center'
      direction='column'
    >
      <Text
        fontWeight='bold'
        align='center'
      >
        Ai editing
      </Text>
      <Flex
        direction='column'
        alignSelf='center'
      >
        <PriceEditor
          packageState={packageState}
          setPackageState={setPackageState}
          register={register}
          handleNumberInputChange={handleNumberInputChange}
          label={`Price per image`}
          id={'editingPrice'}
        />
        <Button variant='photonLight' onClick={()=> addEditingConfig()} mb={8}>
          Add config <IoMdAdd size={20} />
        </Button>
      </Flex>

      <Grid
        templateColumns={{md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}}
        gap={4}
        autoFlow="row dense"
      >
        {
          packageState.imageEditingConfigs?.map(
            (c, configIndex)=>(
              <GridItem
                key={configIndex}
                w='100%'
                display='flex'
                justifyContent='center'
              >
                <Flex direction='column' w='400px' key={configIndex}>
                  <Divider borderWidth="3px" borderColor="#a6a4ff" mb={4}/>
                  <FormLabel>Name of image category</FormLabel>
                  <Input
                    variant='formInput'
                    onChange={(e)=> handleEditingConfigChange(e.target.value, 'name', configIndex)}
                    defaultValue={packageState.imageEditingConfigs[configIndex]?.name}
                  />

                  <Flex alignItems='center' mb={1}>
                    <FormLabel>Photoroom template(s)</FormLabel>
                    <Button onClick={()=> addTemplate(configIndex, c)}>
                      <IoMdAdd size={20} />
                    </Button>
                  </Flex>

                  <Box mb={4}>
                    {
                      c.templates.map(
                        (t, templateIndex) => (
                          <Flex key={templateIndex}>
                            <Select
                              bg='white'
                              borderRadius='15px'
                              mb={4}
                              mr={1}
                              onChange={(e)=> handleConfigTemplateChange(e.target.value, templateIndex, c, configIndex)}
                              value={t}
                            >
                              {templates?.map((template: {name: string, _id: string}) => (
                                <option key={template._id} value={template._id}>
                                  {template.name}
                                </option>
                              ))}
                            </Select>
                            <Button onClick ={()=> handleRemoveTemplate(t, configIndex, c)}>
                              <MdDeleteForever size={20}/>
                            </Button>
                          </Flex>

                        ),
                      )
                    }
                  </Box>
                  <FormLabel>Maximum number of images to upload</FormLabel>
                  <Input
                    variant='formInput'
                    onChange={(e)=>handleEditingConfigChange(e.target.value, 'limit', configIndex, true)}
                    defaultValue={c.limit}
                  />

                  <FormLabel>Uploading is mandatory</FormLabel>
                  <Switch
                    mb={4}
                    size='md'
                    defaultChecked={!c.notMandatory}
                    onChange={(e)=>handleEditingConfigChange(!e.target.checked, 'notMandatory', configIndex)}
                  />

                  <Flex alignItems='center' mb={1}>
                    <FormLabel>Tumbnail picture</FormLabel>
                    {
                      (c.examplePicture?.url || c.examplePicture?.previewUrl)
                        ? <Button>
                          <MdDeleteForever onClick={()=>handleEditingConfigChange(null, 'examplePicture', configIndex)} size={20} />
                        </Button>
                        : <Box p={4} borderRadius="md">
                          <Input
                            type="file"
                            accept="image/*"
                            onChange={(e)=>handleUpload(e, configIndex)}
                            style={{display: 'none'}}
                            id="file-upload"
                          />
                          <label
                            htmlFor="file-upload"
                            style={{
                              padding: '8px 12px',
                              border: '1px solid white',
                              borderRadius: '10px',
                              backgroundColor: '#a6a4ff',
                              color: 'black',
                              cursor: 'pointer',
                            }}
                          >
                            Upload Picture
                          </label>
                        </Box>
                    }
                  </Flex>
                  {
                    c.examplePicture?.url
                    && <Image
                      maxW='200px'
                      src={c.examplePicture.url}
                      alt={`Reference ${c.examplePicture.url}`}
                      borderRadius="md"
                      boxShadow="md"
                      width="100%"
                    />
                  }
                  {
                    c.examplePicture?.previewUrl
                    && <Image
                      maxW='200px'
                      src={c.examplePicture.previewUrl}
                      alt={`Reference ${c.examplePicture.previewUrl}`}
                      borderRadius="md"
                      boxShadow="md"
                      width="100%"
                    />
                  }
                  <Button
                    mt='10px'
                    variant='photonMedium'
                    onClick ={()=> handleRemoveConfig(configIndex)}
                  >
                    Remove config <MdDeleteForever size={20}/>
                  </Button>
                </Flex>
              </GridItem>
            ),
          )
        }
      </Grid>
    </Flex>
  );
}
