import useAxios from './basic/use-axios';
import {UseAxiosResult} from 'axios-hooks';
import {AxiosResponse} from 'axios';

export type CreateRecruitment = {
  area: string;
  photographersWanted: number;
  isActive: boolean;
};

export type UpdateRecruitment = CreateRecruitment & {
  _id: string;
};

export type DeleteRecruitment = {
  _id: string;
};

export type ServerResponse = {
  message: string;
};

export const useCreateRecruitment = (): UseAxiosResult<
  AxiosResponse,
  CreateRecruitment,
  ServerResponse
> =>
  useAxios(
    {
      url: '/admin/recruitment',
      method: 'POST',
    },
    {manual: true},
  );

export const useUpdateRecruitment = (): UseAxiosResult<
  AxiosResponse,
  UpdateRecruitment,
  ServerResponse
> =>
  useAxios(
    {
      url: '/admin/recruitment',
      method: 'PUT',
    },
    {manual: true},
  );

export const useDeleteRecruitment = (): UseAxiosResult<
  AxiosResponse,
  DeleteRecruitment,
  ServerResponse
> =>
  useAxios(
    {
      url: '/admin/recruitment',
      method: 'DELETE',
    },
    {manual: true},
  );

export const useGetRecruitments = () =>
  useAxios(
    {
      url: '/admin/recruitment',
      method: 'GET',
    },
    {manual: true},
  );
