import {TimeUnit, hourOptions, Currency, ShootingType, ProjectDriver, EditingType} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';
import {z} from 'zod';

export const BriefSchema = z.object({
  brief: z.union([z.string().url(), z.string().length(0)]).nullable(),
});


export const BasicDataSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  clientId: z.string().min(1, 'Client is required'),
  category: z.string().min(1, 'Category is required'),
  shootingType: z.string().min(1, 'Shooting type is required'),
  description: z.string().nullable(),
  photographerDescription: z.string().nullable(),
});


export const getEditingSchema = (packageState: PackageType)=> {
  return z.object({
    editingPrice: packageState.editingSettings.includes(EditingType.AI_EDITING)
      ? z.object({
        amount: z
          .number({invalid_type_error: 'Amount must be a number'})
          .nullable()
          .optional(),
        currency: z.nativeEnum(Currency, {
          errorMap: () => ({message: 'Invalid currency selected'}),
        }),
      })
      : z.unknown(),
    editorBrief: packageState.editingSettings.includes(EditingType.MANUAL_EDITING)
      ? z.union([z.string().url(), z.string().length(0)]).nullable()
      : z.unknown(),
  });
};

export const getDeliveryDeadlinesAndPricingSchema = (packageState: PackageType) => {
  return z.object({
    name: z.string().min(1, 'Name is required'),
    duration: z.object({
      amount: z
        .number({invalid_type_error: 'Amount must be a number'})
        .min(1, 'Amount must be at least 1'),
      unit: z.nativeEnum(TimeUnit, {
        errorMap: () => ({message: 'Invalid time unit'}),
      }),
    }),
    retouchedPicsNum: z.number({invalid_type_error: 'Must be a number'}).optional().or(z.null()),
    deliveredPicsNum: z.number({invalid_type_error: 'Must be a number'}).optional().or(z.null()),
    videoLength: packageState.shootingType === ShootingType.VIDEO
      ? z.object({
        amount: z
          .number({invalid_type_error: 'Amount must be a number'})
          .min(1, 'Amount must be at least 1'),
        unit: z.nativeEnum(TimeUnit, {
          errorMap: () => ({message: 'Invalid time unit'}),
        }),
      })
        .refine((data) => !!data.amount && !!data.unit, {
          message: 'videoLength is required when shootingType is video',
        })
      : z.unknown(),
    photographerDeliveryTime: packageState.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME)
      ? z.object({
        atHour: z
          .number({invalid_type_error: 'Hour must be a number'})
          .min(0, 'Hour must be between 0 and 24')
          .max(24, 'Hour must be between 0 and 24')
          .refine((hour) => hourOptions.includes(hour), {
            message: 'Invalid hour selected',
          }),
        amount: z
          .number({invalid_type_error: 'Amount must be a number'}),
        unit: z.nativeEnum(TimeUnit, {
          errorMap: () => ({message: 'Invalid time unit'}),
        }),
      })
      : z.unknown(),
    deliveryTime: packageState.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME)
      ? z.object({
        atHour: z
          .number({invalid_type_error: 'Hour must be a number'})
          .min(0, 'Hour must be between 0 and 24')
          .max(24, 'Hour must be between 0 and 24')
          .refine((hour) => hourOptions.includes(hour), {
            message: 'Invalid hour selected',
          }),
        amount: z
          .number({invalid_type_error: 'Amount must be a number'}),
        unit: z.nativeEnum(TimeUnit, {
          errorMap: () => ({message: 'Invalid time unit'}),
        }),
      })
      : z.unknown(),
    price: z.object({
      amount: z
        .number({invalid_type_error: 'Amount must be a number'})
        .positive('Amount must be greater than 0'),
      currency: z.nativeEnum(Currency, {
        errorMap: () => ({message: 'Invalid currency selected'}),
      }),
    }),
    priceOfPhotographer: z.object({
      amount: z
        .number({invalid_type_error: 'Amount must be a number'})
        .positive('Amount must be greater than 0'),
      currency: z.nativeEnum(Currency, {
        errorMap: () => ({message: 'Invalid currency selected'}),
      }),
    }),
    manualEditingPrice: packageState.editingSettings.includes(EditingType.MANUAL_EDITING)
      ? z.object({
        amount: z
          .number({invalid_type_error: 'Amount must be a number'})
          .positive('Amount must be greater than 0'),
        currency: z.nativeEnum(Currency, {
          errorMap: () => ({message: 'Invalid currency selected'}),
        }),
      })
      : z.unknown(),
    editingPrice: packageState.editingSettings.includes(EditingType.AI_EDITING)
      ? z.object({
        amount: z
          .number({invalid_type_error: 'Amount must be a number'})
          .nullable()
          .optional(),
        currency: z.nativeEnum(Currency, {
          errorMap: () => ({message: 'Invalid currency selected'}),
        }),
      })
      : z.unknown(),
  }).refine((data) => {
    const retouchedValid = data.retouchedPicsNum !== undefined && data.retouchedPicsNum >= 1;
    const deliveredValid = data.deliveredPicsNum !== undefined && data.deliveredPicsNum >= 1;
    return retouchedValid || deliveredValid;
  }, {
    message: `Give value for 'retouched images' or 'images to be delivered'`,
    path: ['retouchedPicsNum', 'deliveredPicsNum'],
  });
};


export const getExtrasSchema = (packageState: PackageType) => {
  const pricingDynamicFixOverLimitEnabled = packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT);
  const manualEditingEnabled = packageState.manualEditingEnabled;
  return z.object({
    dynamicPricing: packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC)
      ? z.object({
        rate: z
          .number({invalid_type_error: 'Multiplier must be a number'})
          .min(0.1, 'Multiplier must be at least 0'),
        currency: z.string().min(1, 'Currency is required'),
        unitPriceForClient: z
          .number({invalid_type_error: 'Price must be a number'})
          .min(0.1, 'Price must be greater than 0'),
        unitPriceForCreative: z
          .number({invalid_type_error: 'Price must be a number'})
          .min(0.1, 'Price must be greater than 0'),
        unitPriceForEditor: manualEditingEnabled
          ? z.number({invalid_type_error: 'Price must be a number'})
            .min(0.1, 'Price must be greater than 0')
          : z.unknown(),
        aboveLimitUnitPriceForClient: pricingDynamicFixOverLimitEnabled
          ? z.number({invalid_type_error: 'Price must be a number'})
            .min(0.1, 'Price must be greater than 0')
          : z.unknown(),
        upperLimit: pricingDynamicFixOverLimitEnabled
          ? z.number({invalid_type_error: 'Limit must be a number'})
            .min(1, 'Limit must be greater than 0')
          : z.unknown(),
        aboveLimitUnitPriceForCreative: pricingDynamicFixOverLimitEnabled
          ? z.number({invalid_type_error: 'Price must be a number'})
            .min(0.1, 'Price must be greater than 0')
          : z.unknown(),
        aboveLimitUnitPriceForEditor: pricingDynamicFixOverLimitEnabled && manualEditingEnabled
          ? z.number({invalid_type_error: 'Price must be a number'})
            .min(0.1, 'Price must be greater than 0')
          : z.unknown(),
      })
      : z.unknown(),
  });
};
