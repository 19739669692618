import {Flex, useSteps, Text, Box, useDisclosure, Spinner} from '@chakra-ui/react';
import {useEffect, useState} from 'react';
import {PackageType} from 'views/packages/types';
import {StepType} from './FormStepper';
import PackageBasicDataEditTab from './edit-tabs/basic';
import DeliveryDeadlinesAndPricingEditTab from './edit-tabs/delivery-deadlines-and-pricing';
import ExtrasEditTab from './edit-tabs/extras';
import {EditingType, ExtraType} from 'views/packages/constants';
import EditingEditTab from './edit-tabs/editing';
import PhotographersEditTab from './edit-tabs/photographers';
import TitleSelector from './TitleSelector';
import {IoArrowBack} from 'react-icons/io5';
import FinishPackageFormModal from './FinishPackageFormModal';
import ExitPackageFormModal from './ExitPackageFormModal';
import {useLocalStorage} from 'hooks/useLocalStorage';
import FinishPackageFormWithWarningModal from './FinishPackageFormWithWarningModal';
import {useNavigate} from 'react-router-dom';

const defaultSteps: StepType[] = [
  {name: 'basic', title: 'General information'},
  {name: 'deadlines_and_pricing', title: 'Delivery deadlines & Pricing'},
  {name: 'photographers', title: 'Photographers'},
  {name: 'extras', title: 'Extras'},
  {name: 'editing', title: 'Editing'},
];

export default function PackageForm(props: {packageData?: PackageType }) {
  const {packageData} = props;
  const {isOpen, onOpen, onClose} = useDisclosure();
  const [previousPath] = useLocalStorage('previousPath', '/intel/packages');
  const {isOpen: isFinishModalOpen, onOpen: onFinishModalOpen, onClose: onFinishModalClose} = useDisclosure();
  const {isOpen: isWarningModalOpen, onOpen: onWarningModalOpen, onClose: onWarningModalClose} = useDisclosure();
  const [packageState, setPackageState] = useState<PackageType>(packageData as PackageType);
  const [steps, setSteps] = useState(defaultSteps);
  const navigate = useNavigate();
  const {activeStep, setActiveStep} = useSteps({
    index: 0,
    count: steps.length,
  });

  const nextFormStep = () => setActiveStep(activeStep + 1);
  const prevFormStep = () => setActiveStep(activeStep - 1);

  useEffect(
    () => {
      if (packageData) {
        setPackageState(packageData);
      }
    },
    [packageData],
  );

  useEffect(() => {
    setSteps(() => {
      let updatedSteps = defaultSteps;

      if (packageState?.extraTypes?.includes(ExtraType.NO_EXTRA)) {
        updatedSteps = updatedSteps.filter((s) => s.name !== 'extras');
      }

      if (packageState?.editingSettings?.includes(EditingType.NO_EDITING)) {
        updatedSteps = updatedSteps.filter((s) => s.name !== 'editing');
      }

      return updatedSteps;
    });
  }, [packageState]);

  const handleIconClick = () => {
    let hasDifferences;
    if (packageData) {
      hasDifferences = (Object.keys(packageState) as Array<keyof PackageType>).some(
        (key) => packageState[key] !== packageData[key],
      );
    } else {
      hasDifferences = Object.keys(packageState).length > 1;
    }

    if (hasDifferences) {
      onOpen();
    } else {
      const path = previousPath.split('/intel')[1];
      navigate(`/intel${path}`);
    }
  };

  const startFinishProcess = ()=> {
    if (packageState.projectExistsWithPackageId) {
      onWarningModalOpen();
    } else {
      onFinishModalOpen();
    }
  };

  const isEdit = !!packageData?.name;

  return (
    <Flex
      direction={'column'}
      justifyContent={'space-around'}
      mt={{base: '80px', md: 0}}
    >
      <Flex
        justifyContent="center"
        alignItems="center"
        position="relative"
        p="10px"
        mt='80px'
      >
        <Box
          cursor={'pointer'}
          position="absolute"
          left="10px"
          onClick={handleIconClick}
        >
          <IoArrowBack size={25} />
        </Box>

        <Text
          fontWeight="bold"
          fontSize="20px"
        >
          {isEdit ? `Updating package: ${packageState?.name}`: 'Creating package'}
        </Text>
      </Flex>
      <TitleSelector steps = {steps} activeStep={activeStep} setActiveStep={setActiveStep} />

      {
        packageState && Object.keys(packageState).length > 0 ? (
          <>
            {
              steps[activeStep].name === 'basic' && (
                <PackageBasicDataEditTab
                  nextFormStep={nextFormStep}
                  packageState={packageState}
                  setPackageState={setPackageState}
                />
              )
            }
            {
              steps[activeStep].name === 'deadlines_and_pricing' && (
                <DeliveryDeadlinesAndPricingEditTab
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                  packageState={packageState}
                  setPackageState={setPackageState}
                />
              )
            }
            {
              steps[activeStep].name === 'photographers' && (
                <PhotographersEditTab
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                  packageState={packageState}
                  setPackageState={setPackageState}
                  startFinishProcess={startFinishProcess}
                />
              )
            }
            {
              steps[activeStep].name === 'extras' && (
                <ExtrasEditTab
                  nextFormStep={nextFormStep}
                  prevFormStep={prevFormStep}
                  packageState={packageState}
                  setPackageState={setPackageState}
                  startFinishProcess={startFinishProcess}
                />
              )
            }
            {
              steps[activeStep].name === 'editing' && (
                <EditingEditTab
                  prevFormStep={prevFormStep}
                  packageState={packageState}
                  setPackageState={setPackageState}
                  startFinishProcess={startFinishProcess}
                />
              )
            }
          </>
        )
          : <Flex justifyContent='center'>
            <Spinner/>
          </Flex>
      }
      <ExitPackageFormModal
        onClose={onClose}
        isOpen={isOpen}
      />
      <FinishPackageFormModal
        packageState={packageState}
        isOpen={isFinishModalOpen}
        onClose={onFinishModalClose}
        isEdit={isEdit}
      />
      <FinishPackageFormWithWarningModal
        packageState={packageState}
        isOpen={isWarningModalOpen}
        onClose={onWarningModalClose}
      />
    </Flex>
  );
}
