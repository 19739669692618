import React from 'react';

import {useDisclosure, Button} from '@chakra-ui/react';
import UpdateOrCreateRecruitmentModal from './UpdateOrCreateRecruitmentModal';


export default function RecruitmentListMenu(props: { [x: string]: any }) {
  const {onRefresh} = props;

  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  return (
    <>
      <Button variant='photonLight' w='100px' onClick={() => onOpenCreateModal()}>
        Add
      </Button>
      <UpdateOrCreateRecruitmentModal
        isOpen={isOpenCreateModal}
        onClose={(success) => {
          onCloseCreateModal();
          if (success) {
            onRefresh();
          }
        }}
        toCreate
      />
    </>
  );
}
