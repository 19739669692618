import MenuLayout from 'layouts/MenuLayout';
import RoleGuard from 'layouts/RoleGuard';
import {adminRoles} from 'variables/roles';
import PackageForm from './components/edit/PackageForm';
import {PackageType} from './types';


export default function PackageCreatePage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonJury.key,
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<PackageForm packageData={{name: ''} as PackageType} />} />
    </RoleGuard>
  );
}
