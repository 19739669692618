import React, {KeyboardEvent, useRef} from 'react';
import {
  Button,
  Text,
  Box,
  Select,
  Flex,
  Grid,
  Checkbox,
} from '@chakra-ui/react';
import {useSearchParams} from 'react-router-dom';
import {DateInputFilterTypes, EditorFeedbackFilterTypes, TextInputFilterTypes} from './types';
import {useGetClientCountries} from 'api/clients';
import {sortCountries} from 'utils/sort-countries';
import useCountryList from 'hooks/useCountryList';
import moment from 'moment';
import {DateFilterInput, FilterTextInput, handleResetFilters} from './components';
import {textInputs} from './constants';
import PackageFilterInput from 'components/PackageFilterInput';

export const defaultEditorFeedbackFilters = {
  projectName: '',
  clientName: '',
  packageId: '',
  country: '',
  creative: '',
  editor: '',
  fromDate: moment().subtract(30, 'd').startOf('d').toDate(),
  toDate: moment().endOf('d').toDate(),
  notHandledYet: false,
};

const EditorFeedbackFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
  filters: EditorFeedbackFilterTypes;
  handleSearch: (updatedFilters: EditorFeedbackFilterTypes) => void;
  setFilters: React.Dispatch<React.SetStateAction<EditorFeedbackFilterTypes>>;
}) => {
  const [,] = useSearchParams();
  const [{data: clientCountries}] = useGetClientCountries();
  const sortedCountries = sortCountries(clientCountries);
  const {getCountryName} = useCountryList();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleFilterChange = (value: string | Date | boolean, name: keyof EditorFeedbackFilterTypes) => {
    const updatedFilters = {
      ...filters,
      [name]: value,
    };
    setFilters(updatedFilters);


    if (textInputs.includes(name) && value) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      };
      timeoutRef.current = setTimeout(() => {
        handleSearch(updatedFilters as EditorFeedbackFilterTypes);
      }, 1000);
    } else {
      handleSearch(updatedFilters as EditorFeedbackFilterTypes);
    }
  };

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  return (
    <Box w='100%'>
      <Grid
        templateColumns='repeat(auto-fit, minmax(300px, 1fr))'
        gap='10px 10px'
        m='0px 60px 0px 20px'
        justifyItems='center'
        alignItems='center'
      >
        <>
          {[
            {label: 'Project name / ID', key: 'projectName'},
            {label: 'Client', key: 'clientName'},
            {label: 'Photographer', key: 'creative'},
            {label: 'Editor', key: 'editor'},
          ].map(({label, key}) => (
            <FilterTextInput
              key={key}
              label={label}
              filterKey={key as keyof TextInputFilterTypes}
              filters={filters}
              handleFilterChange={handleFilterChange}
              handleSearch={handleSearch}
              handleEnterPress={handleEnterPress}
            />
          ))}
        </>

        <PackageFilterInput
          packageId={filters.packageId}
          handleFilterChange={handleFilterChange}
        />

        <Flex direction='column' mb={4} width='300px'>
          <Text>Country:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'country')}
            placeholder='Select country'
            value={filters.country}
          >
            {sortedCountries?.map((country: string) => (
              <option key={country} value={country}>
                {getCountryName(country)}
              </option>
            ))}
          </Select>
        </Flex>
        <DateFilterInput
          label="From date (editor delivery)"
          filterKey={'fromDate' as keyof DateInputFilterTypes}
          filters={filters}
          handleFilterChange={handleFilterChange}
        />

        <DateFilterInput
          label="To date (editor delivery)"
          filterKey={'toDate' as keyof DateInputFilterTypes}
          filters={filters}
          handleFilterChange={handleFilterChange}
        />

        <Flex mt='5px' direction='column'>
          <Checkbox
            isChecked={filters.notHandledYet}
            onChange={(e) => handleFilterChange(e.target.checked, 'notHandledYet')}
          >
                Show only the projects not yet managed
          </Checkbox>
        </Flex>

      </Grid>
      <Flex direction='row' width='300px' m='10px 0px 10px 20px'>
        <Button
          onClick={() => handleResetFilters(setFilters, handleSearch)}
          variant='photonMedium'
          ml='20px'
          w='130px'
        >
            Reset filters
        </Button>
      </Flex>
    </Box>
  );
};
export default EditorFeedbackFilters;
