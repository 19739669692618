import useAxios from './basic/use-axios';
import {UseAxiosResult} from 'axios-hooks';
import {AxiosResponse} from 'axios';


export type CreateCriteria = {
  name: string;
  status: string;
};

export type UpdateCriteria = CreateCriteria & {
  _id: string;
};

export type ServerResponse = {
  message: string;
};

export type DeleteCriteria = {
  _id: string;
};

export const useCreateCritaria = (): UseAxiosResult<AxiosResponse, CreateCriteria, ServerResponse> => useAxios(
  {
    url: '/admin/quality-control',
    method: 'POST',
  },
  {manual: true},
);

export const useUpdateCriteria = (criteriaId: string): UseAxiosResult<AxiosResponse, UpdateCriteria, ServerResponse> => useAxios(
  {
    url: `/admin/quality-control/${criteriaId}`,
    method: 'PUT',
  },
  {manual: true},
);

export const useDeleteCriteria = (criteriaId: string): UseAxiosResult<AxiosResponse, DeleteCriteria, ServerResponse> => useAxios(
  {
    url: `/admin/quality-control/${criteriaId}`,
    method: 'DELETE',
  },
  {manual: true},
);

export const useGetCriterias = () => useAxios(
  {
    url: '/admin/quality-control',
    method: 'GET',
  },
);
