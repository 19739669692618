import React, {useCallback, useEffect} from 'react';

import {
  Box,
  Button,
  Flex,
  Text,
  useToast,
} from '@chakra-ui/react';
import {Cell} from 'react-table';
import {useGetPackageList} from 'api/packages';
import {PackageListFilterTypes} from './types';
import ListTable from 'components/ListTable';
import {useListQueryContext} from 'contexts/useListQueryContext';
import PackageListFilters from './components/PackageListFilters';
import {clearFilters} from 'utils/clear-filters';
import moment from 'moment';
import {
  packageCategoryLabels,
  packageStatusLabels,
  packageTypeLabels,
} from './constants';
import ClipboardIcon from 'components/ClipbordIcon';
import PackageManager from './components/PackageManager';
import PackageListMenu from './components/PackageListMenu';
import {Link} from 'react-router-dom';

type Row = {
  _id: string;
  status: string;
};

export default function PackageList() {
  const {
    pageNumber,
    perPage,
    filters,
    setFilters,
    updateFilterSearchParams,
    sorting,
    setPageNumber,
  } = useListQueryContext<PackageListFilterTypes>();

  const [{data, loading, error}, getPackageList] = useGetPackageList();
  const toast = useToast();

  const callGetPackageList = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: PackageListFilterTypes,
    ) => {
      try {
        const cleanedFilters = clearFilters(filters);
        await getPackageList({
          params: {
            page,
            perPage,
            sorting,
            filters: cleanedFilters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getPackageList],
  );

  useEffect(() => {
    if (error) {
      toast({
        title: 'Error getting packages',
        status: 'error',
        duration: 3000,
      });
    }
  }, [error, toast]);

  const handleSearch = (updatedFilters?: PackageListFilterTypes) => {
    setPageNumber(1);
    callGetPackageList(1, perPage, sorting, updatedFilters || filters);
    updateFilterSearchParams(updatedFilters);
  };

  useEffect(() => {
    callGetPackageList(pageNumber, perPage, sorting, filters);
  }, [pageNumber, perPage, sorting]);

  const columnsData = {
    _id: {
      columnDef: {
        Header: 'ID',
        accessor: '_id',
      },
      renderer: (cell: Cell) => {
        return <ClipboardIcon value={cell.value} />;
      },
    },
    name: {
      columnDef: {
        Header: 'Title',
        accessor: 'name',
      },
    },
    clientName: {
      columnDef: {
        Header: 'Client',
        accessor: 'clientName',
      },
    },
    status: {
      columnDef: {
        Header: 'Status',
        accessor: 'status',
      },
      renderer: (cell: Cell) => {
        return (
          <Text fontWeight='bold' color='black'>
            {packageStatusLabels[cell.value]}
          </Text>
        );
      },
    },
    category: {
      columnDef: {
        Header: 'Category',
        accessor: 'category',
      },
      renderer: (cell: Cell) => {
        return (
          <Text fontWeight='bold' color='black'>
            {packageCategoryLabels[cell.value]}
          </Text>
        );
      },
    },
    shootingType: {
      columnDef: {
        Header: 'Type',
        accessor: 'shootingType',
      },
      renderer: (cell: Cell) => {
        return (
          <Text fontWeight='bold' color='black'>
            {packageTypeLabels[cell.value]}
          </Text>
        );
      },
    },
    createdAt: {
      columnDef: {
        Header: 'Created',
        accessor: 'createdAt',
      },
      renderer: (cell: Cell) => {
        return (
          <Text fontWeight='bold' color='black'>
            {moment(cell.value).format('DD.MM.YYYY')}
          </Text>
        );
      },
    },
    actions: {
      columnDef: {
        Header: 'Actions',
        accessor: 'actions',
      },
      renderer: (cell: Cell<Row, unknown>) => {
        const id = cell.row.original._id;
        const status = cell.row.original.status;
        return <Flex>
          <Link to={`/intel/package/${id}/view`}>
            <Button variant='photonLight' mt='0px'>
            View
            </Button>
          </Link>
          <Link to={`/intel/package/${id}/edit`}>
            <Button variant='photonMedium'>
              Edit
            </Button>
          </Link>
          <PackageManager packageId={id} status={status} refetch={handleSearch} />
        </Flex>;
      },
    },
  };

  return (
    <Box pt={{base: '30px', md: '80px', xl: '80px'}} mt={{base: '90px', md: '10px'}}>
      <ListTable
        variant='underlineRows'
        label={'Packages'}
        menuComp={<PackageListMenu />}
        columnsData={Object.values(columnsData).map((column: any) => column.columnDef)}
        renderersData={columnsData}
        tableData={data?.data}
        total={data?.total}
        filterComp={
          <PackageListFilters
            filters={filters}
            setFilters={setFilters}
            handleSearch={handleSearch}
          />
        }
        isLoading={loading}
      />
    </Box>
  );
}
