import {Flex, FormLabel, Text, HStack, Tag, TagLabel, Button, Link} from '@chakra-ui/react';
import {FaLink} from 'react-icons/fa';
import {RiMailSendLine} from 'react-icons/ri';
import {PackageType} from 'views/packages/types';

export default function PhotographersViewTab(props: { packageData: PackageType }) {
  const {packageData} = props;

  return (
    <Flex direction={'column'} justifyContent={'space-around'}>
      {
        packageData.brief
        && <Link isExternal fontWeight='bold' href={packageData.brief} mb={4} w='100px'>
          <Flex alignItems='center'>
            <Text mr='5px'>Open brief</Text>
            <FaLink />
          </Flex>
        </Link>
      }

      <FormLabel>Photographers:</FormLabel>
      {
        packageData.photographers.length > 0
          ? <>
            <Button
              variant='photonMedium'
              alignItems='center'
              w='320px'
              mb={4}
            >
              <Link
                href={`mailto:${'hello@photon.rocks'}?bcc=${packageData.photographers.map((p)=>p.email).join(',')}`}
                fontWeight='bold'
                target='_blank'
                display='flex'
                alignItems='center'
                pt='15px'
                mb={4}
              >
                <Text mr='5px'>Send email to all photographers</Text>
                <RiMailSendLine />
              </Link>
            </Button>
            <HStack wrap="wrap" spacing={2}>
              {
                packageData.photographers.map(
                  (p, index) => (
                    <Tag key={index} height='35px' variant="solid" bg='#a6a4ff'>
                      <TagLabel>{p.name} ({p.country})</TagLabel>
                    </Tag>
                  ),
                )
              }
            </HStack>
          </>
          : <Text>Ther's no photographer on package</Text>
      }
    </Flex>
  );
}
