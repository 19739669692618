import {Flex, Textarea, Input, Box} from '@chakra-ui/react';
import {useState} from 'react';
import {CiNoWaitingSign} from 'react-icons/ci';
import {FaRegSave} from 'react-icons/fa';
import {IoMdClose} from 'react-icons/io';
import {MdDone, MdOutlineCancel} from 'react-icons/md';
import {Contact} from 'views/projects/types';

export const CustomInput = ({
  initialValue,
  name,
  id,
  onUpdate,
  handleSave,
  currency,
}: {
    initialValue: string | number;
    name?: string;
    id?: string;
    onUpdate: (value: string | number, name?: string, id?: string) => void;
    handleSave: (id: string) => void
    currency?: string;
  }) => {
  const [value, setValue] = useState(initialValue);
  const [showSave, setShowSave] = useState(true);

  const handleBlur = () => {
    onUpdate(value, name, id);
  };


  const handleChange =(value: string | number )=>{
    setValue(value);
    setShowSave(true);
  };

  return <Flex alignItems='center'>
    {
      currency
      && <Flex color='black' alignItems='center'>
        <Input
          onChange={(e) => handleChange(e.target.value)}
          onBlur={handleBlur}
          variant='main'
          value={value}
          width='80px'
          type='number'
          padding='10px'
          textAlign='right'
          m='5px'
        />
        {currency === 'HUF' ? 'Ft' : '€'}
      </Flex>
    }
    {
      !currency
      && <Textarea
        onChange={(e) => handleChange(e.target.value)}
        onBlur={handleBlur}
        value={value}
        width='300px'
        borderColor='secondaryGray.100'
        height='50px'
        color='black'
        bg='white'
        borderRadius='20px'
      />
    }

    <Flex
      direction='column'
      w='40px'
      h='50px'
      alignItems='center'
      justifyContent='space-around'
      display={initialValue !== value && showSave ? 'flex': 'none'}>
      <FaRegSave
        size='20'
        onClick={()=>{
          handleSave(id);
          setShowSave(false);
        }}
        cursor='pointer'
      />
      <MdOutlineCancel
        size='20'
        onClick={()=>{
          handleChange(initialValue);
          setShowSave(false);
        }}
        cursor='pointer'
      />
    </Flex>
  </Flex>;
};

export const CustomIconTwoOption = ({
  name,
  id,
  initialValue,
  onUpdate,
  handleSave,
}: {
      name?: string;
      id?: string;
      initialValue: boolean;
      onUpdate: (value: boolean, name?: string, id?: string) => void;
      handleSave: (id: string, name?: string, value?: number | string | boolean, ) => void
    }) => {
  const [value, setValue] = useState(initialValue);

  const handleClick =()=>{
    onUpdate(!value, name, id);
    setValue((v)=> !v);
    handleSave(id, name, !value);
  };

  return (
    <Box onClick={()=> handleClick()}>
      { value ? <MdDone size={30} color='green' /> : <IoMdClose size={30} color='red' />}
    </Box>);
};

export const CustomIconThreeOption = ({
  name,
  id,
  initialValue,
  onUpdate,
  handleSave,
}: {
      name?: string;
      id?: string;
      initialValue: boolean | null | undefined;
      onUpdate: (value: boolean, name?: string, id?: string) => void;
      handleSave: (id: string, name?: string, value?: number | string | boolean, ) => void
    }) => {
  const [value, setValue] = useState(initialValue);

  const handleClick =(newValue: boolean | null)=>{
    onUpdate(newValue, name, id);
    setValue(newValue);
    handleSave(id, name, newValue);
  };

  return (
    <Box >
      { value === true && <MdDone size={30} color='green' onClick={()=> handleClick(false)}/>}
      { value === false && <IoMdClose size={30} color='red' onClick={()=> handleClick(null)} />}
      { (value === undefined || value === null) && <CiNoWaitingSign size={30} color='grey' onClick={()=> handleClick(true)}/>}
    </Box>);
};

const RowColor = {
  TRANSPARENT: 'transparent',
  YELLOW: '#fffb87',
  LIGHT_GREEN: '#b9fabc',
  ORANGE: '#facaaa',
  GREEN: '#91cc97',
  RED: '#fa7579',
  LIGHT_RED: '#f7a6a8',
};

const calculateBackGroundColor = (data: Contact)=> {
  let bg = RowColor.TRANSPARENT;
  if (data.whatsApp || data.email) {
    bg = RowColor.YELLOW;
  } if (data.interested) {
    bg = RowColor.LIGHT_GREEN;
  } if (data.alternativeDates) {
    bg = RowColor.ORANGE;
  } if (data.travelCosts) {
    bg = RowColor.GREEN;
  } if (data.alternativeDates && data.travelCosts) {
    bg = RowColor.LIGHT_RED;
  } if (data.interested === false) {
    bg = RowColor.RED;
  }
  return bg;
};

export const extendContactsWithBackgroundColor = (data: Contact[]) => {
  const extendedData = data.map((x)=> {
    return {...x, bg: calculateBackGroundColor(x)};
  });
  return extendedData;
};

