
export const PROD_ENV = 'production';

export default {
  apiUrl: import.meta.env.VITE_APP_API_URL || 'https://api.thephoton.hu/api',
  environment: import.meta.env.VITE_APP_ENV || PROD_ENV,
  landingUrl: import.meta.env.VITE_APP_LANDING_URL || 'https://photon.rocks',
  newAdminUrl: import.meta.env.VITE_APP_NEW_ADMIN_URL || 'https://admin.photon.rocks',
  photonEmailAddress: import.meta.env.PHOTON_EMAIL_ADDRESS || 'hello@photon.rocks',
};
