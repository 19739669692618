import React, {useCallback, useEffect, useState} from 'react';

import {useSearchParams} from 'react-router-dom';

import {
  Box,
  Button,
  IconButton,
  Flex,
  Text,
} from '@chakra-ui/react';
import {AiOutlineDelete} from 'react-icons/ai';

import DevelopmentTable from '../../components/DevelopmentTable';
import {adminRoles} from '../../variables/roles';
import RoleGuard from '../../layouts/RoleGuard';
import {Cell} from 'react-table';
import {useAuth} from '../../hooks/useAuth';
import {useGetRecruitments} from 'api/recruitment';
import RecruitmentListMenu from './RecruitmentListMenu';
import UpdateOrCreateRecruitmentModal, {defaultForm} from './UpdateOrCreateRecruitmentModal';
import DeleteRecruitmentModal from './DeleteRecruitmentModal';
import RecruitmentListFilters from './RecruitmentListFilters';
import useCountryList from 'hooks/useCountryList';
import {encodeQueryItems} from '../../utils/decode-url-params';


export default function RecruitmentTable() {
  const {user} = useAuth();
  const {getCountryName} = useCountryList();

  const [params, setSearchParams] = useSearchParams();
  const [filters, setFilters] = useState<Record<string, string>>(
    Object.fromEntries(
      params.entries().map(
        ([k, v]) => [k, v && decodeURIComponent(v)],
      ),
    ),
  );

  const [
    {data: recruitmentsData, loading: recruitmentsLoading},
    getRecruitmentList,
  ] = useGetRecruitments();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState(defaultForm);
  const [deleteData, setDeleteData] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(
    () => {
      if (Object.keys(filters).length > 0) {
        const encodedFilters = encodeQueryItems(filters);
        getRecruitmentList({params: {filters: encodedFilters}});
      } else {
        getRecruitmentList();
      }
    },
    [filters],
  );

  const updateFilter = useCallback(
    (updatedFilters: Record<string, string>) => {
      const encodedFilters = encodeQueryItems(updatedFilters);
      setSearchParams(new URLSearchParams(encodedFilters));
      setFilters(updatedFilters);
    },
    [setSearchParams, setFilters],
  );

  const openEditModal = (id: string) => {
    const data = Object.assign(
      {},
      recruitmentsData.find((r: any) => r._id === id),
    );

    setFormData(data);
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (id: string) => {
    setDeleteData(id);
    setIsDeleteModalOpen(true);
  };

  const columnsDataRecruitment = {
    area: {
      columnDef: {
        Header: 'City',
        accessor: 'area',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {cell.row.original.area.split(', ')[0]}
          </Text>
        );
      },
    },
    country: {
      columnDef: {
        Header: 'Country',
        accessor: 'country',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {getCountryName(cell.row.original.area.split(', ')[1])}
          </Text>
        );
      },
    },
    photographersWanted: {
      columnDef: {
        Header: 'Photographers wanted',
        accessor: 'photographersWanted',
      },
    },
    shortage: {
      columnDef: {
        Header: 'Shortage of photographers',
        accessor: 'shortage',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {cell.row.original.activePhotographers - cell.row.original.photographersWanted}
          </Text>
        );
      },
    },
    isActive: {
      columnDef: {
        Header: 'Status',
        accessor: 'isActive',
      },
      renderer: (cell: Cell<any, any>) => {
        return (
          <Text fontWeight='bold' color='black'>
            {cell.row.original.isActive ? 'Active' : 'Inactive'}
          </Text>
        );
      },
    },
    _id: {
      columnDef: {
        Header: 'Actions',
        accessor: '_id',
        width: 250,
      },
      renderer: (cell: Cell<any, any>) => {
        const id = cell.value;
        return (
          <RoleGuard roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
            <Flex gap={10} justifyContent='flex-start' width={200}>
              <Button variant='photonDark' onClick={() => openEditModal(id)}>
                Edit
              </Button>
              {id !== user.userId && (
                <IconButton
                  onClick={() => openDeleteModal(id)}
                  colorScheme='photonOffWhite'
                  color='photonDeepBlue'
                  aria-label='Delete recruitment'
                  icon={<AiOutlineDelete size={20} />}
                />
              )}
            </Flex>
          </RoleGuard>
        );
      },
    },
  };

  return (
    <Box pt={{base: '30px', md: '80px', xl: '80px'}}>
      <DevelopmentTable
        variant='underlineRows'
        menuComp={<RecruitmentListMenu onRefresh={() => updateFilter({})} />}
        label={`Recruitment`}
        columnsData={Object.values(columnsDataRecruitment).map(
          (column: any) => column.columnDef,
        )}
        renderersData={columnsDataRecruitment}
        tableData={recruitmentsData}
        total={recruitmentsData?.length}
        filterComp={
          <RecruitmentListFilters
            filters={filters}
            setFilters={updateFilter}
          />
        }
        isLoading={recruitmentsLoading}
      />

      <UpdateOrCreateRecruitmentModal
        selectedRecruitment={formData}
        isOpen={isEditModalOpen}
        onClose={(successful?: boolean) => {
          setIsEditModalOpen(false);
          if (successful) {
            updateFilter({});
          }
        }}
      />

      {
        deleteData
        && <DeleteRecruitmentModal
          deleteData={deleteData}
          isOpen={isDeleteModalOpen}
          onClose={(successful?: boolean) => {
            setIsDeleteModalOpen(false);
            setDeleteData('');
            if (successful) {
              updateFilter({});
            }
          }}
        />
      }
    </Box>
  );
}
