import {HStack, Box, Text, Flex} from '@chakra-ui/react';
import {Dispatch, SetStateAction, useEffect, useRef, useState} from 'react';
import {StepType} from './FormStepper';

export default function TitleSelector({
  steps,
  activeStep,
  setActiveStep,
}: {
    steps: StepType[],
    activeStep: number
    setActiveStep: Dispatch<SetStateAction<number>>

}) {
  const [activeTitle, setActiveTitle] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current && steps.length > 0) {
      const container = containerRef.current;
      const title = container.children[activeTitle] as HTMLElement;

      if (title) {
        title.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'center',
        });
      }
    }
  }, [activeTitle, steps]);


  useEffect(() => {
    setActiveTitle(activeStep);
  }, [activeStep]);

  const handleTitleClick = (index: number) => {
    if (index < activeStep) {
      setActiveTitle(index);
      setActiveStep(index);
    }
  };

  return (
    <Flex w='100%' alignItems='center' justifyContent='center'>
      <HStack
        ref={containerRef}
        spacing={8}
        overflow="hidden"
        mt={6}
        mb={10}
        mx={4}
        transition="transform 0.3s ease"
        bg='ed'
      >
        {steps.map((step, index) => (
          <Box
            key={index}
            transition="transform 0.3s ease"
            transform={index === activeTitle ? 'scale(1.1)' : 'scale(0.8)'}
            userSelect="none"
            color={index === activeTitle ? '#6b52ff' : '#a6a4ff'}
            cursor={index < activeStep ? 'pointer' : 'default'}
            mx='5px'
            onClick={()=>handleTitleClick(index)}
          >
            <Text fontSize={index === activeTitle ? 'xl' : 'md'} fontWeight="bold">
              {step.title}
            </Text>
          </Box>
        ))}
      </HStack>
    </Flex>


  );
}
