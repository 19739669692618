import {Photographer} from '../views/packages/types';

export const loadCreativeOptionsWithDebounce = () => {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return (
    search: string,
    excludedIds: string[],
    getCreatives: Function,
    callback: any,
  ) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      getCreatives({params: {excludedIds, search}}).then((creatives: {data: Photographer[]} ) => {
        callback(creatives.data.map((c)=> ({value: c, label: c.name})));
      });
    }, 500);
  };
};


