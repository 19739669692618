// eslint-disable-next-line
export const decodeStringArrayParam = (queryParams: any, name: string) => {
  // eslint-disable-next-line
  let value = Object.fromEntries(queryParams.entries())[name] || ([] as any);
  if (typeof value === 'string') {
    value = decodeURIComponent(value).split(',');
  }
  if (name === 'workingArea') {
    value = value.map((v: string) => decodeURIComponent(v).replace('-', ','));
  }
  return value;
};

// eslint-disable-next-line
export const decodeStringParam = (queryParams: any, name: string) => {
  // eslint-disable-next-line
  let value = Object.fromEntries(queryParams.entries())[name] || ([] as any);
  value = decodeURIComponent(value);
  return value;
};

export const encodeQueryItems = (queryItems: Record<string, string>): Record<string, string> => {
  const encodedObj = {...queryItems};

  for (const key in queryItems) {
    if (Object.hasOwn(queryItems, key)) {
      const value = queryItems[key];
      if (value) {
        encodedObj[key] = encodeURIComponent(value);
      } else {
        delete encodedObj[key];
      }
    }
  }

  return encodedObj;
};

// eslint-disable-next-line
export const decodeBooleanParam = (queryParams: any, name: string) => {
  return Object.fromEntries(queryParams.entries())[name] === 'true';
};
