import {ChevronLeftIcon, ChevronRightIcon} from '@chakra-ui/icons';
import {Flex, Button} from '@chakra-ui/react';


export default function FormStepperButtons(props: {
    prevFormStep: () => void,
    isLastStep?: boolean
}) {
  const {prevFormStep, isLastStep} = props;
  return (
    <Flex
      w='300px'
      justifyContent='space-around'
      my='30px'
    >
      <Button
        w='100px'
        ml='0px'
        variant="photonFormDark"
        pr="28px"
        rightIcon={<ChevronLeftIcon w="30px" h="30px" />}
        onClick={prevFormStep}
      >
        Prev
      </Button>
      <Button
        w='100px'
        ml='0px'
        variant="photonFormDark"
        pr="28px"
        rightIcon={<ChevronRightIcon w="30px" h="30px" />}
        type='submit'
      >
        {isLastStep ? 'Finish' : 'Next'}
      </Button>
    </Flex>
  );
}
