import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import {useState} from 'react';

export const ConfirmDocumentRegenerationModal = ({
  handleProceed,
  loading,
  isOpen,
  onClose,
}: {
    handleProceed?: (sendOut: boolean) => void;
    loading?: boolean;
    isOpen?: boolean;
    onClose?: () => void
  }) => {
  const [sendOut, setSendOut] = useState(true);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          textAlign='center'
        >
          Are you sure you want to issue the document again?
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          display='flex'
          flexDirection='column'
        >
          <Checkbox
            alignSelf='center'
            mb={4}
            size={'md'}
            isChecked={sendOut}
            onChange={(e) => {
              setSendOut(e.target.checked);
            }}
          >
            <Text align='center'>
            Send out related emails
            </Text>
          </Checkbox>
          <Text align='center'>
          This may take a while, you can close the window.

          </Text>
        </ModalBody>
        <ModalFooter
          display='flex'
          justifyContent='center'
        >
          <Button
            variant='photonMedium'
            mr={3}
            onClick={onClose}
          >
          Cancel
          </Button>
          <Button
            variant='photonDark'
            isLoading={loading}
            w='auto'
            onClick={()=>handleProceed(sendOut)}
          >
          Proceed
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal> );
};
