import {Editor} from '../views/packages/types';

export const loadEditorOptionsWithDebounce = () => {
  let timer: ReturnType<typeof setTimeout> | undefined;

  return (
    search: string,
    excludedIds: string[],
    getEditors: Function,
    callback: any,
  ) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      getEditors({params: {excludedIds, search}}).then((editors: {data: Editor[]} ) => {
        callback(editors.data.map((c)=> ({value: c, label: c.name})));
      });
    }, 500);
  };
};


