import Bugsnag from '@bugsnag/js';
import {
  Text,
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@chakra-ui/react';
import {useUpdatePackage, useUploadFile} from 'api/packages';
import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {PackageType} from 'views/packages/types';
import {uploadFilesAndFormatPackageFormDataBeforeSubmit} from './formatPackageForm';

const ErrorMessage = {
  UPDATE: 'Update is unsuccessful!',
  CREATE: 'Creation is unsuccessful!',
  UPLOAD: 'Upload is unsuccessful!',
};

export default function FinishPackageFormWithWarningModal(props: {
  packageState: PackageType,
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
}) {
  const {isOpen, onClose, packageState}= props;
  const navigate = useNavigate();
  const [{error: updateError, loading: updateLoading}, updatePackage] = useUpdatePackage(packageState?._id);
  const [{error: uploadError, loading: uploadLoading}, uploadFile] = useUploadFile(packageState?._id);
  const toast = useToast();

  const handleClose = ()=> {
    navigate('/intel/packages');
  };

  const handleSubmitForm = async () => {
    try {
      const formattedData = await uploadFilesAndFormatPackageFormDataBeforeSubmit(packageState, uploadFile);

      await updatePackage({data: formattedData});

      onClose();
      navigate('/intel/packages');

      toast({
        title: 'Package successfully updated',
        status: 'success',
        duration: 3000,
      });
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };


  useEffect(() => {
    if (updateError || uploadError) {
      let errorText;

      if (updateError) {
        errorText = ErrorMessage.UPDATE;
      } else if (uploadError) {
        errorText = ErrorMessage.UPLOAD;
      }

      toast({
        title: errorText,
        status: 'error',
        duration: 3000,
      });
    }
  }, [updateError, uploadError]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align='center'>
           Warning!
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody textAlign='center'>
          <Text fontWeight='bold' mb='10px'>
            At least one project has already been created with this package.
            If you continue to modify it, it may affect all the previous projects.
          </Text>

          <Text fontWeight="bold" mb="10px">
              If you want to change critical details (e.g. pricing),
              go back and duplicate the package instead and modify the details of the new package.
            <br />
              Otherwise, the pricing of the previous projects may also change.
          </Text>

          <Text>
            Please confirm you want to save the changes!
          </Text>

        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button
              mr='5px'
              w='140px'
              variant='photonMedium'
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={handleSubmitForm}
              isLoading={updateLoading || uploadLoading}
            >
              Save
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
}

