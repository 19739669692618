import {
  Button,
  Flex,
  Link,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import moment from 'moment';

import {useCallback, useEffect, useState} from 'react';
import BillingPeriodSelector from 'views/dashboard/components/BillingPeriodSelector';
import YearSelector from 'views/dashboard/components/YearSelector';
import {DownloadIcon} from '@chakra-ui/icons';
import {currencyFormatter} from 'utils/number-formatter';
import {downloadFile} from 'utils/download-file';
import Card from 'components/card/Card';
import {useLocation, useNavigate} from 'react-router-dom';
import {
  useGetAllCostReportZip,
  useGetCostReports,
  useGetCostReportsCsv,
  useRegenerateClientCostReport,
} from 'api/clients';
import useCountryList from 'hooks/useCountryList';
import {FaRedo} from 'react-icons/fa';
import {ConfirmDocumentRegenerationModal} from 'components/ConfirmDocumentRegenerationModal';
import Bugsnag from '@bugsnag/js';
import {CustomSpinner} from './Invoices';


export default function CostReports() {
  const location = useLocation();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(location.search);
  const [year, setYear] = useState(urlParams.get('year') || moment().format('YYYY'));
  const [period, setPeriod] = useState(urlParams.get('period') || '');
  const {getCountryName} = useCountryList();
  const [currentRow, setCurrentRow] = useState<{ docId: string, clientId: string }>();
  const {isOpen, onOpen, onClose} = useDisclosure();
  const toast = useToast();

  const [{data: costReportData}, getCostReports] = useGetCostReports();
  const [{loading}, getCostReportZip] = useGetAllCostReportZip();
  const [{loading: regenerateLoading, error}, regenerateDoc] = useRegenerateClientCostReport();
  const [{loading: csvLoading}, getCostReportsCsv] = useGetCostReportsCsv();

  const callGetCostReportsCsv = useCallback(async (year: string, period: string) => {
    // eslint-disable-next-line
    let result: any;
    if (year && period) {
      try {
        result = await getCostReportsCsv({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(
            result.data,
            `client_invoices${year}_${period}.csv`,
            'application/octet-stream',
          );
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const isRowRegenerating = (docId: string) => {
    return regenerateLoading && currentRow.docId === docId;
  };

  const handleRegenerate = async (sendOut: boolean) => {
    try {
      await regenerateDoc({
        data: {
          clientId: currentRow.clientId,
          docId: currentRow.docId,
          sendOut,
        },
      });
      toast({
        title: 'Document regenerated succesfully',
        status: 'success',
        duration: 3000,
      });
      onClose();
      callGetCostReports(year, period);
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const callGetCostReports = useCallback(async (year: string, period: string) => {
    if (year && period) {
      try {
        await getCostReports({
          params: {
            year,
            period,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  const callgetCostReportsZip = useCallback(async (year: string, period: string) => {
    let result;
    if (year && period) {
      try {
        result = await getCostReportZip({
          params: {
            year,
            period,
          },
          responseType: 'arraybuffer',
        });
        if (result && result.data) {
          downloadFile(result.data, `cost_reports${year}_${period}.zip`, 'application/zip');
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  useEffect(
    () => {
      callGetCostReports(year, period);
    },
    [year, period],
  );

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Failed to regenerate document',
          status: 'error',
          duration: 3000,
        });
      }
    },
    [error],
  );

  useEffect(() => {
    const updatedUrl = `${location.pathname}?year=${year}&period=${period}`;
    navigate(updatedUrl);
  }, [year, period]);

  const isButtonDisabled = !year || !period || !costReportData || costReportData?.length === 0;

  return (
    <Card>
      <Text fontSize='18px' fontWeight='bold' m='10px'>
        Cost reports
      </Text>
      <Flex justifyContent='space-between'>
        <Flex>
          <YearSelector year={year} setYear={setYear}/>
          <BillingPeriodSelector year={year} billingPeriod={period} setBillingPeriod={setPeriod}/>
        </Flex>
        <Flex>
          <Button
            variant='photonLight'
            onClick={() => callgetCostReportsZip(year, period)}
            mt='5px'
            isDisabled={isButtonDisabled}
            isLoading={loading}
          >
            Download all
          </Button>
          <Button
            variant='photonMedium'
            onClick={() => callGetCostReportsCsv(year, period)}
            m='5px 10px'
            isDisabled={isButtonDisabled}
            isLoading={csvLoading}
            w='130px'
          > Export
          </Button>
        </Flex>
      </Flex>
      <Flex justifyContent='center'>
        {costReportData?.length > 0 ? (
          <TableContainer w='100%' mt='50px'>
            <Table variant='simple'>
              <Thead>
                <Tr borderBottom={`solid #E2E8F0`}>
                  <Th>Client</Th>
                  <Th textAlign='center'>Country</Th>
                  <Th textAlign='center'>Billing period</Th>
                  <Th textAlign='center'>HUF</Th>
                  <Th textAlign='center'>EUR</Th>
                  <Th textAlign='center'>Regenerate</Th>
                </Tr>
              </Thead>
              <Tbody>
                {costReportData.map((row: any, i: string) => (
                  <Tr key={i}>
                    <Td>{row.client}</Td>
                    <Td textAlign='center'>{getCountryName(row.country)}</Td>
                    <Td textAlign='center'>{row.date}</Td>
                    <Td>
                      {row?.certs?.HUF && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.HUF.netValue
                              && `${currencyFormatter(row.certs.HUF.netValue, 'HUF')} HUF`}
                          </Text>
                          {
                            isRowRegenerating(row.docId)
                              ? <CustomSpinner/>
                              : <Link isExternal href={row.certs.HUF.url}>
                                <DownloadIcon/>
                              </Link>
                          }
                        </Flex>
                      )}
                    </Td>
                    <Td>
                      {row?.certs?.EUR && (
                        <Flex justifyContent='center'>
                          <Text mr='10px'>
                            {row.certs.EUR.netValue
                              && `${currencyFormatter(row.certs.EUR.netValue, 'EUR')} EUR`}
                          </Text>
                          {
                            isRowRegenerating(row.docId)
                              ? <CustomSpinner/>
                              : <Link isExternal href={row.certs.EUR.url}>
                                <DownloadIcon/>
                              </Link>
                          }
                        </Flex>
                      )}
                    </Td>
                    <Td textAlign='center' display='flex' justifyContent='center'>
                      {isRowRegenerating(row.docId)
                        ? <CustomSpinner/>
                        : <FaRedo
                          cursor='pointer'
                          onClick={() => {
                            setCurrentRow(row);
                            onOpen();
                          }}/>
                      }
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
            <ConfirmDocumentRegenerationModal
              isOpen={isOpen}
              onClose={onClose}
              loading={regenerateLoading}
              handleProceed={handleRegenerate}
            />
          </TableContainer>
        ) : (
          <Text textAlign={'center'} mt='50px'>
            There is no document available for this period.
          </Text>
        )}
      </Flex>
    </Card>
  );
}
