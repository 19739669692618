import useAxios, {ServerClientInstance} from './basic/use-axios';
import Bugsnag from '@bugsnag/js';

export const getPackages = async (searchText?: string) => {
  try {
    const packagesResponse = await ServerClientInstance.get(
      '/admin/package/names',
      {params: {search: searchText}},
    );
    return packagesResponse.data;
  } catch (e) {
    Bugsnag.notify(e as Error);
    return [];
  }
};

export const useGetCreativesOfPackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/creatives/`,
      method: 'GET',
    },
    {manual: true},
  );

export const useRemoveCreativeFromPackage = () =>
  useAxios(
    {
      url: '/admin/package/remove-creative',
      method: 'POST',
    },
    {manual: true},
  );
export const useGetEditorsOfPackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/editors/`,
      method: 'GET',
    },
    {manual: true},
  );

export const useGetPackageById = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}`,
      method: 'GET',
    },
  );

export const useGetClientPackages = () =>
  useAxios(
    {
      url: '/admin/package/client',
      method: 'GET',
    },
    {manual: true},
  );

export const useAddCreativeToPackage = () =>
  useAxios(
    {
      url: '/admin/package/add-creative',
      method: 'POST',
    },
    {manual: true},
  );

export const useGetPackageList = () =>
  useAxios({
    url: '/admin/package/list',
    method: 'GET',
  });

export const useManagePackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/manage/`,
      method: 'PUT',
    },
    {manual: true},
  );

export const useUploadRefPic = () =>
  useAxios(
    {
      url: `/admin/package/upload-ref-pic`,
      method: 'POST',
    },
    {manual: true},
  );

export const useUpdatePackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}`,
      method: 'PUT',
    },
    {manual: true},
  );

export const useCreatePackage = () =>
  useAxios(
    {
      url: `/admin/package/create`,
      method: 'POST',
    },
    {manual: true},
  );

export const useUploadFile = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/ref-pic-upload`,
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
    {manual: true},
  );

export const useDuplicatePackage = (packageId: string) =>
  useAxios(
    {
      url: `/admin/package/${packageId}/duplicate`,
      method: 'POST',
    },
    {manual: true},
  );
