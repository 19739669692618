import {
  Box,
  Flex,
  FormLabel,
  HStack,
  Tag,
  TagLabel,
  Image,
  Divider,
  Grid,
  GridItem,
  Text,
  Link,
} from '@chakra-ui/react';
import {useGetAllTemplates} from 'api/photoroom-templates';
import {IoMdClose} from 'react-icons/io';
import {MdDone} from 'react-icons/md';
import {PackageType} from 'views/packages/types';
import {FaLink} from 'react-icons/fa';

export default function EditingViewTab(props: { packageData: PackageType }) {
  const {packageData} = props;
  const [
    {data: templates},
  ] = useGetAllTemplates();

  return (
    <Flex direction='column' alignItems='center'>
      {
        packageData.manualEditingEnabled
          && <Flex direction='column' alignItems='center'>
            <FormLabel mb={4} fontWeight='bold' textAlign='center'>Manual editing</FormLabel>

            {
              packageData.editorBrief
              && <Link isExternal fontWeight='bold' href={packageData.editorBrief} mb={4} w='100px'>
                <Flex alignItems='center'>
                  <Text mr='5px'>Open brief</Text>
                  <FaLink />
                </Flex>
              </Link>
            }

            <Flex
              direction={{base: 'column', md: 'row'}}
              mt={4}
            >
              <Flex
                w={{base: '100%', md: '50%'}}
                direction='column'
              >
                <Flex direction='column' alignItems='center' >
                  <FormLabel>Price per image</FormLabel>
                  <Text variant='custom'>
                    {`${packageData.manualEditingPrice?.amount} ${packageData.manualEditingPrice?.currency}`}
                  </Text>
                </Flex>
                <FormLabel textAlign='center'>Editors</FormLabel>
                {
                  packageData.editors.length > 0
                    ? <HStack wrap="wrap" spacing={2} mb={4} ml={2}>
                      {
                        packageData.editors?.map(
                          (e, index) => (
                            <Tag
                              key={index}
                              height='35px'
                              variant="solid"
                              bg='#a6a4ff'
                            >
                              <TagLabel>{e.name} ({e.country})</TagLabel>
                            </Tag>
                          ),
                        )
                      }
                    </HStack>
                    : <Text>Ther's no editor on package</Text>
                }
              </Flex>
              <Flex
                w={{base: '100%', md: '50%'}}
                justifyContent='center'
                alignItems='center'
                direction='column'
              >
                <FormLabel textAlign='center'>Quality control categories</FormLabel>
                <Flex wrap="wrap" mb={4} direction='column'>
                  {
                    packageData.qualityCriterias?.map(
                      (e) => (
                        <Text variant='custom' key={e._id}>
                          {e.name}
                        </Text>
                      ),
                    )
                  }
                </Flex>
              </Flex>
            </Flex>
            <Divider borderWidth="3px" borderColor="#a6a4ff" mb='30px'/>
          </Flex>
      }

      {
        packageData.backgroundRemovalEnabled
          && <Flex direction='column'alignItems='center'>
            <FormLabel fontWeight='bold' textAlign='center'>AI editing</FormLabel>

            <Box>
              <FormLabel>Price per image</FormLabel>
              <Text variant='custom'>
                {`${packageData.editingPrice?.amount} ${packageData.editingPrice?.currency}`}
              </Text>
            </Box>

            <FormLabel textAlign='center'>Editing configs</FormLabel>
            <Grid
              templateColumns={{md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}}
              gap={20}
            >
              {
                packageData.imageEditingConfigs.map((c, i) => (
                  <GridItem key={i} p={4} borderRadius="md" boxShadow="md">
                    <FormLabel>Name</FormLabel>
                    <Text variant="custom">{c.name}</Text>

                    {
                      c.templates.length > 0 && <>
                        <FormLabel>Templates</FormLabel>
                        <HStack wrap="wrap" spacing={2} mb={4}>
                          {
                            c.templates.map(
                              (t, index) => (
                                <Tag key={index} height="35px" variant="solid" bg="#a6a4ff">
                                  <TagLabel>
                                    {templates?.find((x: { _id: string }) => x._id === t)?.name}
                                  </TagLabel>
                                </Tag>
                              ),
                            )
                          }
                        </HStack>
                      </>
                    }
                    {
                      c.limit && (
                        <>
                          <FormLabel>Maximum number of images to upload</FormLabel>
                          <Text variant="custom">{c.limit}</Text>
                        </>
                      )
                    }
                    <FormLabel>Uploading is mandatory</FormLabel>
                    {
                      c.notMandatory
                        ? <IoMdClose size={30} color="red" />
                        : <MdDone size={30} color="green" />
                    }
                    {
                      c.examplePicture?.url && (
                        <>
                          <FormLabel>Thumbnail picture</FormLabel>
                          <Image
                            src={c.examplePicture.url}
                            borderRadius="md"
                            boxShadow="md"
                            maxWidth="200px"
                          />
                        </>
                      )
                    }
                  </GridItem>
                ))
              }
            </Grid>
          </Flex>
      }
    </Flex>
  );
}
