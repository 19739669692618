import React, {useEffect, KeyboardEvent} from 'react';

import {
  Button,
  InputGroup,
  Text,
  Box,
  Select,
  InputRightElement,
  Input,
  Flex,
} from '@chakra-ui/react';
import {TiDeleteOutline} from 'react-icons/ti';
import {useSearchParams} from 'react-router-dom';
import {deliveryCriteriaCategoryLabels, deliveryCriteriaStatusLabels} from './constants';
import {QualitControlFiltersFilterTypes} from './types';

const textInputs = ['name', 'client', 'templateId'];
const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: (value: string, name: keyof QualitControlFiltersFilterTypes) => void;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name as keyof QualitControlFiltersFilterTypes)}>
        <TiDeleteOutline size='20' />
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<QualitControlFiltersFilterTypes>>,
  handleSearch: (updatedFilters: QualitControlFiltersFilterTypes) => Promise<void>,
) => {
  const updatedFilters = {name: '', category: '', status: ''};
  await setFilters(updatedFilters);
  await handleSearch(updatedFilters);
};

const QualitControlFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
  filters: QualitControlFiltersFilterTypes;
  handleSearch: (updatedFilters: QualitControlFiltersFilterTypes) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<QualitControlFiltersFilterTypes>>;
}) => {
  const [, setSearchParams] = useSearchParams();

  const handleFilterChange = (value: string, name: keyof QualitControlFiltersFilterTypes) => {
    const updatedFilters = {...filters, [name]: value};
    setFilters(updatedFilters as QualitControlFiltersFilterTypes);

    if (!textInputs.includes(name) || !value) {
      handleSearch(updatedFilters);
    }
  };

  useEffect(() => {
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, encodeURIComponent(value));
      });
      return params;
    });
  }, [filters, setSearchParams]);

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  return (
    <Box w='100%'>
      <Flex
        ml={8}
        gap='0px 0px'
        justifyItems='center'
        alignItems='center'
        p='0px'
      >
        <Flex direction='column'
          mb={4}
          mr={4}
        >
          <Text>Name:</Text>
          <InputGroup w='300px'>
            {filters.name && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'name'} />
            )}
            <Input
              variant='tableSearch'
              placeholder='Search by name'
              onChange={(e) => handleFilterChange(e.target.value, 'name')}
              value={filters.name}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>

        <Flex
          direction='column'
          mb={4}
          mr={4}
          width='300px'
        >
          <Text>Status:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'status')}
            placeholder='Select status'
            value={filters.status}
          >
            {
              Object.entries(deliveryCriteriaStatusLabels).map(([k, v]) =>
                <option key={k} value={k}>
                  {v}
                </option>,
              )
            }
          </Select>
        </Flex>

        <Flex
          direction='column'
          mb={4}
          mr={4}
          width='300px'
        >
          <Text>Category:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'category')}
            placeholder='Select category'
            value={filters.category}
          >
            {
              Object.entries(deliveryCriteriaCategoryLabels).map(
                ([k, v]) =>
                  <option key={k} value={k}>
                    {v}
                  </option>,
              )
            }
          </Select>
        </Flex>


        <Flex
          justifyContent={{base: 'center', md: 'start'}}
          w='300px'
          mt={2}
        >
          <Button
            onClick={() => handleResetFilters(setFilters, handleSearch)}
            variant='photonMedium'
            w='130px'
          >
            Reset filters
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};
export default QualitControlFilters;
