import {Box, Button, Flex, Text, useToast} from '@chakra-ui/react';
import {
  useDeleteBrandOwnerModifyRequest,
  useGetBrandOwnerModifyRequestList,
  useManageBrandOwnerModifyRequest,
} from 'api/clients';
import SimpleTable from 'components/SimpleTable';
import moment from 'moment';
import {BrandOwnerModificationRequestDecisionOptions} from './constants';
import Bugsnag from '@bugsnag/js';
import {useEffect, useState} from 'react';
import {MdDeleteForever} from 'react-icons/md';

type Cell = {
  value: {
    result: string,
    by: {
      name: string
    },
    time: Date
  }
  row: {
    original: {
      _id: string
    }
  }
}

export default function ClientOwnerModifyRequestList() {
  const [
    {data, loading},
    getBrandOwnerModifyRequestList,
  ] = useGetBrandOwnerModifyRequestList();

  const [{error, loading: manageLoading}, manageBrandOwnerModifyRequest] = useManageBrandOwnerModifyRequest();
  const [{
    error: deleteError,
    loading: deleteLoading,
  }, deleteBrandOwnerModifyRequest] = useDeleteBrandOwnerModifyRequest();
  const [currentId, setCurrentId] = useState('');
  const toast = useToast();

  const handleRequest = async (decision: string, requestId: string) => {
    setCurrentId(requestId);
    try {
      await manageBrandOwnerModifyRequest(
        {
          data: {requestId, decision},
        },
      );
      getBrandOwnerModifyRequestList();
    } catch (error) {
      Bugsnag.notify(error as Error);
    }
  };

  const handleDeleteRequest = async (requestId: string) => {
    setCurrentId(requestId);
    try {
      await deleteBrandOwnerModifyRequest(
        {
          params: {requestId},
        },
      );
      getBrandOwnerModifyRequestList();
    } catch (error) {
      Bugsnag.notify(error as Error);
    }
  };

  useEffect(
    () => {
      if (error) {
        toast(
          {
            title: 'Update is unsuccessful!',
            description: error.response?.data?.message,
            status: 'error',
            duration: 3000,
          },
        );
      }
      if (deleteError) {
        toast(
          {
            title: 'Delete is unsuccessful!',
            description: error.response?.data?.message,
            status: 'error',
            duration: 3000,
          },
        );
      }
    },
    [error, deleteError, toast],
  );

  const columnsDataClientUsers = {
    'createdAt': {
      columnDef: {
        Header: 'Created',
        accessor: 'createdAt',
      },
      renderer: (cell: { value: Date }) => {
        return (
          <Text
            fontWeight='bold'
            color='black'
          >
            {moment(cell.value).format('DD/MM/YYYY HH:mm')}
          </Text>
        );
      },
    },
    'client.name': {
      columnDef: {
        Header: 'Client',
        accessor: 'client.name',
      },
    },
    'owner.name': {
      columnDef: {
        Header: 'Current owner',
        accessor: 'owner.name',
      },
    },
    'promoted.name': {
      columnDef: {
        Header: 'Promoted',
        accessor: 'promoted.name',
      },
    },
    'resolved': {
      columnDef: {
        Header: 'Resolved',
        accessor: 'resolved',
      },
      renderer: (cell: Cell) => {
        const requestId = cell.row.original._id;
        return (
          cell.value ? <>
            <Flex>
              <Text
                fontWeight='bold'
                color={cell.value?.result === BrandOwnerModificationRequestDecisionOptions.ACCEPTED ? 'green' : 'red'}
                mr='4px'>
                {cell.value?.result}
              </Text>

              {
                cell.value?.by?.name
                  && <Text
                    fontWeight='bold'
                    color='black'
                  >
                    {` by ${cell.value?.by?.name}`}
                  </Text>
              }
            </Flex>
            <Text
              fontWeight='bold'
              color='black'
            >
              {moment(cell.value?.time).format('DD/MM/YYYY')}
            </Text>
          </>
            : <Flex alignItems='center'>
              <Button
                onClick={() => handleRequest(BrandOwnerModificationRequestDecisionOptions.ACCEPTED, requestId)}
                variant='photonLight'
                mr='5px'
                w='80px'
                ml='0px'
                isLoading={currentId === requestId && manageLoading}
              >
                Accept
              </Button>
              <Button
                onClick={() => handleRequest(BrandOwnerModificationRequestDecisionOptions.REJECTED, requestId)}
                variant='photonMedium'
                w='80px'
                isLoading={currentId === requestId && manageLoading}
              >
                Reject
              </Button>
            </Flex>
        );
      },
    },
    '_id': {
      columnDef: {
        Header: ' ',
        accessor: '_id',
      },
      renderer: (cell: Cell) => {
        const requestId = cell.row.original._id;
        return (
          <Button
            onClick={() => handleDeleteRequest(requestId)}
            isLoading={currentId === requestId && deleteLoading}
          >
            <MdDeleteForever size={20}/>
          </Button>
        );
      },
    },
  };

  return <Box pt={{base: '130px', md: '80px', xl: '80px'}} mt='10px'>
    {
      data
      && <SimpleTable
        variant='underlineRows'
        label={'Client owner change requests'}
        columnsData={
          Object.values(columnsDataClientUsers).map((column: any) => column.columnDef)
        }
        renderersData={columnsDataClientUsers}
        tableData={data}
        total={data.length}
        isLoading={loading}
      />
    }
  </Box>;
}
