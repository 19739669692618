import {FormLabel, Table, Tbody, Td, Th, Thead, Tr, Flex, Text, Button, Link} from '@chakra-ui/react';
import useCountryList from 'hooks/useCountryList';
import {Dispatch, SetStateAction} from 'react';
import {MdDeleteForever} from 'react-icons/md';
import {RiMailSendLine} from 'react-icons/ri';
import {PackageType} from 'views/packages/types';

export default function EditorsTable(props: {
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
  onOpen: ()=> void
}) {
  const {packageState, setPackageState, onOpen} = props;
  const {getCountryName} = useCountryList();


  const handleRemoveCreative = (id: string)=> {
    setPackageState((s)=> ({...s, editors: s.editors.filter((p)=> p.id !== id)}));
  };

  const editors = packageState.editors || [];

  return (
    <Flex
      direction='column'
      alignItems='center'
      w='80%'
      alignSelf='center'
    >
      <FormLabel>Editors:</FormLabel>
      <Flex
        direction={{base: 'column', md: 'column', lg: 'row'}}
        mb={4}
      >
        {
          editors.length > 0
          && <Button
            variant='photonMedium'
            alignItems='center'
            w='320px'
            mb={{base: '10px', md: 0}}
          >
            <Link
              href={`mailto:${'hello@photon.rocks'}?bcc=${editors.map((p)=>p.email).join(',')}`}
              fontWeight='bold'
              target='_blank'
              mb={4}
              display='flex'
              alignItems='center'
              pt='15px'
            >
              <Text mr='5px'>Send email to all editors</Text>
              <RiMailSendLine />
            </Link>
          </Button>
        }
        <Button
          variant='photonLight'
          m='10px'
          mt={0}
          onClick={onOpen}
        >
           Add editors
        </Button>
      </Flex>

      {
        editors.length > 0
          && <Table variant="simple" mb={4} bg='white' borderRadius='15px' w={{base: '90%', md: '90%', lg: '90%'}}>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Country</Th>
                <Th>Remove</Th>
              </Tr>
            </Thead>
            <Tbody>
              {editors.map((p, index) => (
                <Tr key={index}>
                  <Td py={2}>{p.name}</Td>
                  <Td py={2}>{getCountryName(p.country)}</Td>
                  <Td py={2}>
                    <Button
                      size="sm"
                      onClick={() => handleRemoveCreative(p.id)}
                    >
                      <MdDeleteForever size={20}/>
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
      }
    </Flex>

  );
}
