import React, {useCallback, useMemo} from 'react';
import {
  Button,
  Flex,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Box,
  Spinner,
} from '@chakra-ui/react';
import {Cell, Column, useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import Card from './card/Card';
import {GrLinkNext, GrLinkPrevious} from 'react-icons/gr';
import {useListQueryContext} from 'contexts/useListQueryContext';

export default function ListTable(props: {
  columnsData: Column<object>[];
  // eslint-disable-next-line
  renderersData: Record<string, { renderer?: Function; columnDef: object }>;
  tableData: object[];
  total: number;
  label?: string;
  menuComp?: React.ReactNode;
  variant?: string;
  filterComp?: React.ReactNode;
  isLoading?: boolean
}) {
  const {
    columnsData,
    tableData,
    label,
    menuComp,
    renderersData,
    variant,
    filterComp,
    isLoading,
    total,
  } = props;

  const {
    pageNumber,
    perPage,
    handleSorting,
    setPerPage,
    setPageNumber,
  } = useListQueryContext();

  const columns = useMemo(() => columnsData, [columnsData]);
  const renderers = useMemo(() => renderersData, [renderersData]);
  const data = useMemo(() => tableData || [], [tableData]);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  const canNextPage = total > perPage * (pageNumber);
  const canPreviousPage = pageNumber > 1;
  const startRow = total !== 0 ? (pageNumber - 1) * perPage + 1 : 0;
  const endRow = Math.min(pageNumber * perPage, total);
  const actualEndRow = Math.min(endRow, total);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {pageIndex: 0, pageSize: 25},
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
  } = tableInstance;

  const renderCell = useCallback(
    // eslint-disable-next-line
    (cell: Cell<object, any>) => {
      const renderer = renderers[cell.column.id]?.renderer;

      if (renderer) {
        return renderer(cell);
      } else {
        return (
          <Text color={textColor} fontSize='sm' fontWeight='700'>
            {cell.value}
          </Text>
        );
      }
    },
    [renderers, textColor],
  );
  return (
    <>
      <Card
        flexDirection='column'
        flexGrow='1'
        w='100%'
        px='0px'
        overflowX={{sm: 'scroll', lg: 'hidden'}}
      >
        <Text
          px='25px'
          mb='20px'
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'
        >
          {label}
        </Text>
        {filterComp}
        <Flex justifyContent='right' mr='20px'>{menuComp}</Flex>
        {
          tableData && !isLoading
          && <>
            <Box overflowX='scroll'>
              <Table {...getTableProps()} variant={variant || 'simple'} color='gray.500' mb='24px'>
                <Thead>
                  {headerGroups.map((headerGroup, index) => (
                    <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                      {headerGroup.headers.map((column, index) => (
                        <Th
                          {...column.getHeaderProps(column.getSortByToggleProps())}
                          pe='10px'
                          key={index}
                          borderColor={borderColor}
                          onClick={() => handleSorting(column)}
                        >
                          <Flex
                            justify='space-between'
                            align='center'
                            fontSize={{sm: '10px', lg: '12px'}}
                            color='gray.400'
                          >
                            {column.render('Header')}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  ))}
                </Thead>
                <Tbody {...getTableBodyProps()}>
                  {page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr {...row.getRowProps()} key={index}>
                        {row.cells.map((cell, index) => (
                          <Td
                            {...cell.getCellProps()}
                            key={index}
                            fontSize={{sm: '14px'}}
                            minW={{sm: '150px', md: '200px', lg: 'auto'}}
                            borderColor='transparent'
                          >
                            {renderCell(cell)}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>

            <Flex justify='space-between' px='10px'>
              <Flex>
                <Select
                  value={perPage}
                  onChange={(e) => setPerPage(Number(e.target.value))}
                  variant='photonMedium'
                  w='75px'
                >
                  {[10, 25, 50, 100].map((size) => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Select>
              </Flex>
              <Flex>
                <Button
                  onClick={() => setPageNumber(pageNumber - 1)}
                  isDisabled={!canPreviousPage}>
                  <GrLinkPrevious />
                </Button>
                <Flex justify='center' mx='10px' mt='6px'>
                  <Text color='gray.400'>
                    {startRow}-{actualEndRow} of {total}
                  </Text>
                </Flex>
                <Button
                  onClick={() => setPageNumber(pageNumber + 1)}
                  isDisabled={!canNextPage}
                >
                  <GrLinkNext />
                </Button>
              </Flex>
            </Flex>
          </>
        }
        {
          isLoading && <Flex w='100%' justifyContent='center'>
            <Spinner/>
          </Flex>
        }
        {
          data.length === 0 && <Flex w='100%' justifyContent='center'>
            <Text>Ther's no data to show</Text>
          </Flex>
        }
      </Card>
    </>

  );
}
