import Bugsnag from '@bugsnag/js';
import {RefetchFunction} from 'axios-hooks';
import {PackageType} from 'views/packages/types';

type UploadFilesFunction = RefetchFunction<FormData, {name: string, url: string}>

const uploadFiles = async (
  files: {
    file?: File,
    name?: string,
    url?: string,
    previewUrl?: string
  }[],
  uploadFile: UploadFilesFunction,
) => {
  const existingFiles = files.filter((pic) => !pic.file).map((pic) => ({name: pic.name, url: pic.url}));
  const newFiles = files.filter((pic) => pic.file);

  const uploadedFiles = [];

  for (const pic of newFiles) {
    const formData = new FormData();
    formData.append('file', pic.file!);

    try {
      const uploadResponse = await uploadFile({data: formData});
      uploadedFiles.push(uploadResponse.data);
    } catch (error) {
      Bugsnag.notify(error as Error);
    }
  }

  return [...existingFiles, ...uploadedFiles];
};

const uploadRefPics = async (packageState: PackageType, uploadFile: UploadFilesFunction) => {
  const newFiles = packageState.refPics?.filter((pic) => pic.file);
  const updatedRefPics = newFiles?.length ? await uploadFiles(packageState.refPics, uploadFile) : packageState.refPics;
  return updatedRefPics;
};

const uploadTemplatePhotos = async (packageState: PackageType, uploadFile: UploadFilesFunction) => {
  const configsWithNewPhotos = packageState.imageEditingConfigs?.filter((c) => c.examplePicture?.file);

  if (configsWithNewPhotos?.length) {
    const examplePictures = configsWithNewPhotos.map((config) => config.examplePicture!);
    const uploadedExamplePictures = await uploadFiles(examplePictures, uploadFile);

    const updatedConfigs = packageState.imageEditingConfigs.map((config) => {
      const uploadedPicture = uploadedExamplePictures.find((pic) => pic.name === config.examplePicture?.file?.name);

      return uploadedPicture
        ? {
          ...config,
          examplePicture: uploadedPicture,
        }
        : config;
    });

    return updatedConfigs;
  }

  return packageState.imageEditingConfigs;
};


const cleanUnusedFields = (obj: Record<string, any>): Record<string, any> => {
  const cleanedData = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value === null) return acc;

    if (typeof value === 'object' && !Array.isArray(value)) {
      const cleanedObject = cleanUnusedFields(value);
      if (Object.keys(cleanedObject).length > 0) {
        acc[key] = cleanedObject;
      }
    } else {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);

  delete cleanedData.editingSettings;
  delete cleanedData.extraTypes;
  delete cleanedData.projectExistsWithPackageId;

  return cleanedData;
};

export const uploadFilesAndFormatPackageFormDataBeforeSubmit = async (packageState: PackageType, uploadFile: UploadFilesFunction ) => {
  const updatedRefPics = await uploadRefPics(packageState, uploadFile);
  const updatedConfigs = await uploadTemplatePhotos(packageState, uploadFile);

  const cleanedPackageState = cleanUnusedFields({...packageState});

  return {
    ...cleanedPackageState as PackageType,
    photographers: packageState.photographers?.map((p)=> p.id),
    editors: packageState.editors?.map((e)=> e.id),
    refPics: updatedRefPics,
    imageEditingConfigs: updatedConfigs,
    qualityCriterias: packageState.qualityCriterias?.map((c)=> c._id),
  };
};
