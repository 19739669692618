import {Text, Flex} from '@chakra-ui/react';
import useCountryList from 'hooks/useCountryList';
import {PackageType} from 'views/packages/types';

export default function PackageCard(props: {
  packageData: PackageType,
}) {
  const {packageData} = props;
  const {getCountryName} = useCountryList();

  return (
    <Flex
      p='10px'
      direction='column'
      bg='white'
      w={{base: '90vw', md: '500px'}}
      borderRadius='20px'
      m='10px'
      fontWeight='bold'
      ml='30px'
    >
      <Text fontSize='25px'>{packageData.name}</Text>
      <Text>{packageData.clientName}</Text>
      <Flex justifyContent='space-between' w='100%'>
        <Text fontSize='14px'>{getCountryName(packageData.country)}</Text>
        <Text fontSize='14px' mr='10px'>{packageData.status}</Text>
      </Flex>
    </Flex>
  );
}
