import {Flex, InputGroup, Input, InputRightElement, Button, Text} from '@chakra-ui/react';
import {TiDeleteOutline} from 'react-icons/ti';
import {defaultEditorFeedbackFilters} from './EditorFeedbackFilters';
import {DateInputFilterTypes, EditorFeedbackFilterTypes, FilterInputProps} from './types';
import {SingleDatepicker} from 'chakra-dayzed-datepicker';
import moment from 'moment';
import {DatePickerStyles} from 'views/projects/variables/styles';


export const FilterTextInput: React.FC<FilterInputProps> = ({
  label,
  filterKey,
  filters,
  handleFilterChange,
  handleSearch,
  handleEnterPress,
}) => (
  <Flex direction='column' mb={4}>
    <Text>{label}:</Text>
    <InputGroup w='300px'>
      {filters[filterKey] && (
        <RemoveInputContent handleFilterChange={handleFilterChange} name={filterKey} />
      )}
      <Input
        variant='tableSearch'
        placeholder={`Search by ${label.toLowerCase()}`}
        onChange={(e) => handleFilterChange(e.target.value, filterKey)}
        value={filters[filterKey] || ''}
        onBlur={() => handleSearch(filters)}
        onKeyDown={handleEnterPress}
      />
    </InputGroup>
  </Flex>
);


const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: (value: string, name: keyof EditorFeedbackFilterTypes) => void;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button
        size='20'
        onClick={() => handleFilterChange('', name as keyof EditorFeedbackFilterTypes)

        }>
        <TiDeleteOutline size='20' />
      </Button>
    </InputRightElement>
  );
};

export const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<EditorFeedbackFilterTypes>>,
  handleSearch: (updatedFilters: EditorFeedbackFilterTypes) => void,
) => {
  await setFilters(defaultEditorFeedbackFilters);
  await handleSearch(defaultEditorFeedbackFilters);
};


export const DateFilterInput: React.FC<{
    label: string;
    filterKey: keyof DateInputFilterTypes;
    filters: DateInputFilterTypes;
    handleFilterChange: (value: Date, name: keyof EditorFeedbackFilterTypes) => void;
  }> = ({label, filterKey, filters, handleFilterChange}) => (
    <Flex
      direction="column"
      mb={4}
      width="300px"
    >
      <Text>{label}</Text>
      <SingleDatepicker
        date={filters[filterKey] as unknown as Date}
        onDateChange={(date) => handleFilterChange(moment(date).toDate(), filterKey)}
        propsConfigs={DatePickerStyles}
        configs={{firstDayOfWeek: 1}}
      />
    </Flex>
  );
