import React from 'react';
import {Button} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';

const PackageListMenu = () => {
  const navigate = useNavigate();

  const handleAddPackage = () => {
    navigate('/intel/package/create');
  };

  return (
    <Button variant="photonLight" onClick={handleAddPackage}>
      Add new package
    </Button>
  );
};

export default PackageListMenu;
