import {FormLabel, Flex, Input} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {UseFormRegister} from 'react-hook-form';
import {PackageType} from 'views/packages/types';
import CurrencySelector from '../../CurrencySelector';

export default function PriceEditor(props: {
  handleNumberInputChange: (numberString: string, name: string) => void
  setPackageState: Dispatch<SetStateAction<PackageType>>
  register: UseFormRegister<PackageType>
  packageState: PackageType,
  label: string
  id: 'price' | 'priceOfPhotographer' | 'manualEditingPrice' | 'editingPrice'
  centeredLabel?: boolean
}) {
  const {register, label, id, handleNumberInputChange, packageState, centeredLabel} = props;
  return (
    <>
      <FormLabel
        textAlign={centeredLabel ? 'center' : 'start'}
      >
        {label}
      </FormLabel>
      <Flex>
        <Input
          variant='formInput'
          onChange={(e) => {
            const value = e.target.value;
            handleNumberInputChange(value.replace(',', '.'), `${id}.amount`);
          }}
          defaultValue={packageState[id]?.amount}
          mr={1}
        />
        <CurrencySelector register={register} id={id}/>
      </Flex>
    </>
  );
}
