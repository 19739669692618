import {FormLabel, Flex, Input, Image, Box, useToast} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {MdDeleteForever} from 'react-icons/md';
import {FilePreview, PackageType} from 'views/packages/types';

export default function PhotographerRefPics(props: {
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
}) {
  const {packageState, setPackageState} = props;
  const toast = useToast();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files;

    if (selectedFiles && selectedFiles.length > 6) {
      toast({
        title: 'You can upload 6 picture maximum.',
        status: 'warning',
        duration: 3000,
      });
    }

    const filesToProcess: FilePreview[] = [];
    const newPhotosNumber = Math.max(0, 6 - (packageState.refPics?.length || 0));

    if (newPhotosNumber > 0) {
      Array.from(selectedFiles)
        .slice(0, newPhotosNumber)
        .forEach((file) => {
          const filePreview: FilePreview = {
            file,
            previewUrl: URL.createObjectURL(file),
          };

          filesToProcess.push(filePreview);
        });
    }

    setPackageState((s) => (
      {
        ...s,
        refPics: [...s.refPics || [], ...filesToProcess],
      }
    ));
  };

  const removeRefPic = (index: number) => {
    setPackageState((s) => (
      {
        ...s,
        refPics: s.refPics.filter((p, i) => i !== index),
      }
    ));
  };

  const photos = packageState.refPics || [];

  return (
    <Flex direction='column' w='100%'>
      <FormLabel textAlign='center' mb={4}>
        Example photos
      </FormLabel>
      {
        photos.length < 6 && (
          <Flex borderRadius="md" justifyContent='center' mt='0px' h='60px'>
            <Input
              type="file"
              multiple
              accept="image/*"
              onChange={handleFileChange}
              style={{display: 'none'}}
              id="file-upload"
            />
            <label
              htmlFor="file-upload"
              style={{
                padding: '8px 12px',
                border: '1px solid white',
                borderRadius: '10px',
                backgroundColor: '#a6a4ff',
                color: 'black',
                cursor: 'pointer',
                display: 'block',
                textAlign: 'center',
                width: '200px',
                marginBottom: '20px',
              }}
            >
              Upload Files
            </label>
          </Flex>
        )
      }
      <Flex
        wrap="wrap"
        gap={4}
        mb={4}
        justifyContent="center"
      >
        {
          photos.map(
            (p, index) => (
              (p.url || p.previewUrl) && (
                <Box key={index} position="relative">
                  <Image
                    src={p.url || p.previewUrl}
                    alt={`Reference ${index}`}
                    borderRadius="md"
                    boxShadow="md"
                    width="100%"
                    maxW="250px"
                  />
                  <Box
                    position="absolute"
                    bottom="5px"
                    right="5px"
                    bg="rgba(0, 0, 0, 0.5)"
                    borderRadius="full"
                    p="2"
                    cursor="pointer"
                    onClick={() => removeRefPic(index)}
                  >
                    <MdDeleteForever size={20} color="white"/>
                  </Box>
                </Box>
              )
            ),
          )
        }
      </Flex>
    </Flex>

  );
}
