import {Box, Text} from '@chakra-ui/react';
import {FieldErrors} from 'react-hook-form';
import {PackageType} from 'views/packages/types';

export default function FormErrors(props: {
  errors: FieldErrors<PackageType>;
}) {
  const {errors} = props;
  return (
    <Box mb="10px">
      {Object.entries(errors).map(([key, error]) => {
        if (error && typeof error === 'object' && 'message' in error) {
          const errorMessage = error.message;
          return (
            typeof errorMessage === 'string' && (
              <Text key={key} align="center" color="red" fontSize="14px">
                {`${key}: ${errorMessage}`}
              </Text>
            )
          );
        }

        if (error && typeof error === 'object') {
          return Object.entries(error).map(([childKey, childError], childIndex) => {
            if (childError && typeof childError === 'object' && 'message' in childError) {
              const childErrorMessage = childError.message;
              return (
                typeof childErrorMessage === 'string' && (
                  <Text key={`${childKey}-${childIndex}`} align="center" color="red" fontSize="14px">
                    {`${key} - ${childKey}: ${childErrorMessage}`}
                  </Text>
                )
              );
            }
            return null;
          });
        }

        return null;
      })}
    </Box>
  );
}
