import React, {useEffect, KeyboardEvent, useRef} from 'react';

import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Text,
  Box,
  Select,
  InputRightElement,
  Input,
} from '@chakra-ui/react';
import {TiDeleteOutline} from 'react-icons/ti';
import {useSearchParams} from 'react-router-dom';

const textInputs = ['name', 'client', 'templateId'];

export type PhotoroomTemplateFilterTypes = {
  name: any;
  client: string;
  version: string;
  templateId: string;
  status: string;
};

const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: Function;
  name: string;
}) => {
  return (
    <InputRightElement>
      <Button size='20' onClick={() => handleFilterChange('', name)}>
        <TiDeleteOutline size='20' />
      </Button>
    </InputRightElement>
  );
};

const handleResetFilters = async (
  setFilters: React.Dispatch<React.SetStateAction<PhotoroomTemplateFilterTypes>>,
  handleSearch: (updatedFilters: PhotoroomTemplateFilterTypes) => Promise<void>,
) => {
  const updatedFilters = {name: '', client: '', version: '', templateId: '', status: ''};
  await setFilters(updatedFilters);
  await handleSearch(updatedFilters);
};

const PhotoroomTemplateFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
  filters: PhotoroomTemplateFilterTypes;
  handleSearch: (updatedFilters: PhotoroomTemplateFilterTypes) => Promise<void>;
  setFilters: React.Dispatch<React.SetStateAction<PhotoroomTemplateFilterTypes>>;
}) => {
  const [, setSearchParams] = useSearchParams();

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleFilterChange = (value: string, name: keyof PhotoroomTemplateFilterTypes) => {
    const updatedFilters = {...filters, [name]: value};
    setFilters(updatedFilters as PhotoroomTemplateFilterTypes);

    if (textInputs.includes(name) && value) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      };
      timeoutRef.current = setTimeout(() => {
        handleSearch(updatedFilters as PhotoroomTemplateFilterTypes);
      }, 1000);
    } else {
      handleSearch(updatedFilters as PhotoroomTemplateFilterTypes);
    }
  };

  useEffect(() => {
    setSearchParams((params) => {
      Object.entries(filters).forEach(([key, value]) => {
        params.set(key, encodeURIComponent(value));
      });
      return params;
    });
  }, [filters, setSearchParams]);

  const handleEnterPress = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch(filters);
    }
  };

  return (
    <Box w='100%'>
      <Grid
        templateColumns='repeat(auto-fit, minmax(320px, 1fr))'
        gap='0px 0px'
        justifyItems='center'
        alignItems='center'
        p='0px'
      >
        <Flex direction='column' mb={4}>
          <Text>Name:</Text>
          <InputGroup w='300px'>
            {filters.name && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'name'} />
            )}
            <Input
              variant='tableSearch'
              placeholder='Search by name'
              onChange={(e) => handleFilterChange(e.target.value, 'name')}
              value={filters.name}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>

        <Flex direction='column' mb={4}>
          <Text>Client:</Text>
          <InputGroup w='300px'>
            {filters.client && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'client'} />
            )}
            <Input
              variant='tableSearch'
              placeholder='Search by client'
              onChange={(e) => handleFilterChange(e.target.value, 'client')}
              value={filters.client}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>

        <Flex direction='column' mb={4} width='300px'>
          <Text>Version:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'version')}
            placeholder='Select version'
            value={filters.version}
          >
            <option key={'v1'} value={'v1'}>
              V1
            </option>
            <option key={'v2'} value={'v2'}>
              V2
            </option>
          </Select>
        </Flex>

        <Flex direction='column' mb={4}>
          <Text>Template ID:</Text>
          <InputGroup w='300px'>
            {filters.templateId && (
              <RemoveInputContent handleFilterChange={handleFilterChange} name={'templateId'} />
            )}
            <Input
              variant='tableSearch'
              placeholder='Search by template ID'
              onChange={(e) => handleFilterChange(e.target.value, 'templateId')}
              value={filters.templateId}
              onKeyDown={handleEnterPress}
              onBlur={() => handleSearch(filters)}
            />
          </InputGroup>
        </Flex>

        <Flex direction='column' mb={4} width='300px'>
          <Text>Status:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'status')}
            placeholder='Select status'
            value={filters.status}
          >
            <option key={'active'} value={'active'}>
              Active
            </option>
            <option key={'deactivated'} value={'deactivated'}>
              Deactivated
            </option>
          </Select>
        </Flex>

        <Flex justifyContent={{base: 'center', md: 'start'}} w='300px'>
          <Button
            onClick={() => handleResetFilters(setFilters, handleSearch)}
            variant='photonMedium'
            w='130px'
          >
            Reset filters
          </Button>
        </Flex>
      </Grid>
    </Box>
  );
};
export default PhotoroomTemplateFilters;
