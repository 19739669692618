import {Box, Flex, FormLabel, Input, useDisclosure} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {PackageType} from 'views/packages/types';
import {useForm} from 'react-hook-form';
import AddPhotographerModal from './AddPhotographerModal';
import PhotographersTable from './PhotographersTable';
import FormStepperButtons from '../../TabStepperButtons';
import {EditingType, ExtraType} from 'views/packages/constants';
import {zodResolver} from '@hookform/resolvers/zod';
import {BriefSchema} from '../../shemas';
import FormErrors from '../../FormErrors';

export default function PhotographersEditTab(props: {
  nextFormStep: () => void,
  prevFormStep: () => void,
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
  startFinishProcess: () => void
}) {
  const {packageState, setPackageState, nextFormStep, prevFormStep, startFinishProcess} = props;
  const {isOpen, onOpen, onClose} = useDisclosure();

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: zodResolver(BriefSchema),
    defaultValues: packageState,
  });

  const onSubmit = (data: PackageType) => {
    setPackageState((prevState) => (
      {
        ...prevState,
        ...data,
      }
    ));

    if (isLastStep) {
      startFinishProcess();
    } else {
      nextFormStep();
    }
  };

  const isLastStep = packageState.editingSettings?.includes(EditingType.NO_EDITING)
    && packageState.extraTypes.includes(ExtraType.NO_EXTRA);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex w={{base: '90%', md: '100%'}} alignSelf='center' justifyContent='center' direction='column'>
          <Flex
            direction='column'
            alignItems='center'
            mb={6}
          >
            <Box maxWidth='800px' w='100%'>
              <FormLabel textAlign='center'>Brief</FormLabel>
              <Input variant='formInput' {...register('brief')}/>
            </Box>
          </Flex>
          <PhotographersTable
            packageState={packageState}
            setPackageState={setPackageState}
            onOpen={onOpen}
          />
          <Flex w='100%' justifyContent='center' direction='column' alignItems='center'>
            <FormErrors errors={errors}/>
            <FormStepperButtons prevFormStep={prevFormStep} isLastStep={isLastStep}/>
          </Flex>
        </Flex>
      </form>
      <AddPhotographerModal
        isOpen={isOpen}
        onClose={onClose}
        packageState={packageState}
        setPackageState={setPackageState}
      />
    </>

  );
}
