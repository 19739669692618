import React, {useEffect, useState} from 'react';
import {Box, Button, IconButton, Flex, Text} from '@chakra-ui/react';
import {AiOutlineDelete} from 'react-icons/ai';
import DevelopmentTable from '../../../components/DevelopmentTable';
import {adminRoles} from '../../../variables/roles';
import RoleGuard from '../../../layouts/RoleGuard';
import {Cell} from 'react-table';
import {defaultForm} from './CreateQualityControlCriteriaModal';
import CriteriaListMenu from './QualityControlListMenu';
import CreateOrUpdateCriteriaModal from './CreateQualityControlCriteriaModal';
import QualitControlFilters from './QualitControlFilters';
import {useGetCriterias} from 'api/quality-control';
import DeleteCriteriaModal from './DeleteCriteriaModal';
import {deliveryCriteriaCategoryLabels, deliveryCriteriaStatusLabels} from './constants';
import {Criteria, QualitControlFiltersFilterTypes} from './types';


export default function QualityControl() {
  const queryParams = new URLSearchParams(location.search);
  const {
    name = '',
    status = '',
    category = '',
  } = Object.fromEntries(queryParams.entries());

  const [
    {data: templatesData, loading: templatesLoading},
    getCriteriaList,
  ] = useGetCriterias();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [formData, setFormData] = useState(defaultForm);
  const [deleteData, setDeleteData] = useState('');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [filters, setFilters] = useState<QualitControlFiltersFilterTypes>({
    name,
    status,
    category,
  });

  const callGetCriteriaList = async (updatedFilters?: QualitControlFiltersFilterTypes) => {
    getCriteriaList({params: {filters: updatedFilters || filters}});
  };

  useEffect(() => {
    callGetCriteriaList(filters);
  }, []);

  const openEditModal = (id: string) => {
    const data = Object.assign(
      {},

      templatesData.find((r: Criteria) => r._id === id),
    );

    setFormData({...data, version: data.version || 'v1'});
    setIsEditModalOpen(true);
  };

  const openDeleteModal = (id: string) => {
    setDeleteData(id);
    setIsDeleteModalOpen(true);
  };

  const columnsDataRecruitment = {
    _id: {
      columnDef: {
        Header: 'ID',
        accessor: '_id',
      },
    },
    name: {
      columnDef: {
        Header: 'Name',
        accessor: 'name',
      },
    },
    status: {
      columnDef: {
        Header: 'Status',
        accessor: 'status',
      },
      renderer: (cell: Cell<[string], string>) => <Text
        color='black'
        fontWeight='bold'
      >
        {deliveryCriteriaStatusLabels[cell.value]}
      </Text>,
    },
    category: {
      columnDef: {
        Header: 'Category',
        accessor: 'category',
      },
      renderer: (cell: Cell<[string], string>) => <Text
        color='black'
        fontWeight='bold'
      >
        {deliveryCriteriaCategoryLabels[cell.value]}
      </Text>,
    },
    actions: {
      columnDef: {
        Header: 'Actions',
        accessor: 'actions',
        width: 250,
      },
      // eslint-disable-next-line
      renderer: (cell: Cell<any, any>) => {
        const id = cell.row.original._id;
        return (
          <RoleGuard roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
            <Flex gap={10} justifyContent='flex-start' width={200}>
              <Button variant='photonDark' onClick={() => openEditModal(id)}>
                Edit
              </Button>
              <IconButton
                onClick={() => openDeleteModal(id)}
                colorScheme='photonOffWhite'
                color='photonDeepBlue'
                aria-label='Delete recruitment'
                icon={<AiOutlineDelete size={20} />}
              />
            </Flex>
          </RoleGuard>
        );
      },
    },
  };

  return (
    <Box pt={{base: '30px', md: '80px', xl: '80px'}}>
      <>
        <DevelopmentTable
          variant='underlineRows'
          menuComp={<CriteriaListMenu onRefresh={callGetCriteriaList} />}
          label={`Quality control`}
          columnsData={Object.values(columnsDataRecruitment).map(
            // eslint-disable-next-line
            (column: any) => column.columnDef,
          )}
          renderersData={columnsDataRecruitment}
          tableData={templatesData}
          total={templatesData?.length}
          filterComp={
            <QualitControlFilters
              filters={filters}
              setFilters={setFilters}
              handleSearch={callGetCriteriaList}
            />
          }
          isLoading={templatesLoading}
        />
      </>

      <CreateOrUpdateCriteriaModal
        selectedCriteria={formData}
        isOpen={isEditModalOpen}
        onClose={(successful?: boolean) => {
          setIsEditModalOpen(false);
          if (successful) {
            callGetCriteriaList();
          }
        }}
      />

      {deleteData && (
        <DeleteCriteriaModal
          deleteData={deleteData}
          isOpen={isDeleteModalOpen}
          onClose={(successful?: boolean) => {
            setIsDeleteModalOpen(false);
            setDeleteData('');
            if (successful) {
              callGetCriteriaList();
            }
          }}
        />
      )}
    </Box>
  );
}
