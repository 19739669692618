import {
  Text,
  Flex,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import {useNavigate} from 'react-router-dom';

export default function ExitPackageFormModal(props: {
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
}) {
  const {isOpen, onClose} = props;
  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate('/intel/packages');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align='center'>Are you sure you want to exit form?</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>

        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button
              mr='5px'
              w='140px'
              variant='photonMedium'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={handleSubmit}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
}


