export const PackageCategory: Record<string, string> = {
  EVENT: 'event',
  PORTRAIT: 'portrait',
  PROPERTY: 'property',
  WEDDING: 'wedding',
  FAMILY_AND_GROUP: 'familyAndGroup',
  FOOD: 'food',
  PRODUCT: 'product',
  CUSTOM: 'custom',
  HOSPITALITY: 'hospitality',
  E_COMMERCE: 'ecommerce',
  OTHER: 'other',
};

export const packageCategoryLabels: Record<string, string> = {
  [PackageCategory.CUSTOM]: 'Custom',
  [PackageCategory.E_COMMERCE]: 'E-commerce',
  [PackageCategory.EVENT]: 'Event',
  [PackageCategory.FAMILY_AND_GROUP]: 'Family & group',
  [PackageCategory.FOOD]: 'Food',
  [PackageCategory.HOSPITALITY]: 'Hospitality',
  [PackageCategory.PORTRAIT]: 'Portrait',
  [PackageCategory.PRODUCT]: 'Product',
  [PackageCategory.WEDDING]: 'Wedding',
};

export const ShootingType: Record<string, string> = {
  PHOTO: 'photo',
  VIDEO: 'video',
};

export const packageTypeLabels: Record<string, string> = {
  [ShootingType.PHOTO]: 'Photo',
  [ShootingType.VIDEO]: 'Video',
};

export const PackageStatus: Record<string, string> = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
  PENDING: 'pending',
};

export const ManagePackageOption: Record<string, string> = {
  ACTIVATE: 'activate',
  CANCEL: 'cancel',
  DELETE: 'delete',
  DUPLICATE: 'duplicate',
};

export const packageStatusLabels: Record<string, string> = {
  [PackageStatus.ENABLED]: 'Active',
  [PackageStatus.DISABLED]: 'Canceled',
  [PackageStatus.PENDING]: 'Draft',
};

export const ProjectDriver: Record<string, string> = {
  PRICING_DYNAMIC: 'pricing_dynamic',
  PRICING_DYNAMIC_FIX_OVER_LIMIT: 'pricing_dynamic_fix_over_limit',
  PRICING_DYNAMIC_WITH_CORRECTION: 'pricing_dynamic_with_correction',
  DELIVERY_ON_WORKDAY: 'delivery_on_workday',
  DELIVERY_AT_TIME: 'delivery_at_time',
  IGNORE_LATE_FEE: 'ignore_late_fee',
  PRICING_EXTRA_PICS_WITH_CORRECTIONS: 'pricing-extra-pics-with-corrections',
  PRICING_DYNAMIC_WITH_THRESHOLD_CORRECTION: 'pricing_dynamic_with_threshold_correction',
};

export const projectDriverLabels: Record<string, string> = {
  [ProjectDriver.PRICING_DYNAMIC]: 'Dynamic pricing',
  [ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT]: 'Dynamic pricing with fix over limit',
  [ProjectDriver.PRICING_DYNAMIC_WITH_CORRECTION]: 'Dynamic pricing with correction',
  [ProjectDriver.DELIVERY_ON_WORKDAY]: 'Delivery on workday',
  [ProjectDriver.DELIVERY_AT_TIME]: 'Deadline with date & time',
  [ProjectDriver.IGNORE_LATE_FEE]: 'Ignore late feet',
  [ProjectDriver.PRICING_EXTRA_PICS_WITH_CORRECTIONS]: 'Pricing extra photos with correction',
  [ProjectDriver.PRICING_DYNAMIC_WITH_THRESHOLD_CORRECTION]: 'Dynamic pricing with threshold correction',
};

export const ExtraType: Record<string, string> = {
  DYNAMIC_EXTRA: 'dynamic_extra',
  BASIC_EXTRA_IMAGES: 'basic_extra_images',
  OTHER_EXTRA: 'other_extra',
  NO_EXTRA: 'no_extra',
};

export const EditingType: Record<string, string> = {
  MANUAL_EDITING: 'manual_editing',
  AI_EDITING: 'ai_editing',
  NO_EDITING: 'no_editing',
};

export const editingTypeLabels: Record<string, string> = {
  [EditingType.MANUAL_EDITING]: 'Manual editing',
  [EditingType.AI_EDITING]: 'AI editing',
  [EditingType.NO_EDITING]: 'No editing',
};

export const extraTypeLabels: Record<string, string> = {
  [ExtraType.DYNAMIC_EXTRA]: 'Dynamic extras',
  [ExtraType.BASIC_EXTRA_IMAGES]: 'Basic extra images',
  [ExtraType.OTHER_EXTRA]: 'Other extra',
  [ExtraType.NO_EXTRA]: 'No extras',
};

export const TimeUnit: Record<string, string> = {
  HOURS: 'h',
  DAYS: 'd',
};

export const ExtraUnit: Record<string, string> = {
  PIECES: 'pc',
  HOURS: 'h',
};

export const extraUnitLabels: Record<string, string> = {
  [ExtraUnit.HOURS]: 'hour(s)',
  [ExtraUnit.PIECES]: 'piece(s)',
};

export const timeUnitLabels: Record<string, string> = {
  [TimeUnit.HOURS]: 'hour(s)',
  [TimeUnit.DAYS]: 'day(s)',
};

export const Currency: Record<string, string> = {
  HUF: 'HUF',
  EUR: 'EUR',
};


export const hourOptions = Array.from({length: 24}, (_, i) => i);

