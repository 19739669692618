import {
  useEffect,
  useState,
} from 'react';
import Bugsnag from '@bugsnag/js';

import {
  Box,
  Flex,
  Link,
  Text,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  NavLink,
  useParams,
} from 'react-router-dom';
import {Cell} from 'react-table';

import {
  AiFillStar,
  AiOutlineExclamation,
} from 'react-icons/ai';
import {TbPackageOff} from 'react-icons/tb';

import {useUpdateCreativeContact} from 'api/projects';
import {
  Contact,
  GetPhotographerSearchDataType,
} from '../../types';
import {onboardingOrPackageStatus} from '../../constants';
import ExtendedSearchTableMenu from './ExtendedSearchTableMenu';
import {
  CustomIconThreeOption,
  CustomIconTwoOption,
  CustomInput,
  extendContactsWithBackgroundColor,
} from './RowEditors';
import SimpleTable from 'components/SimpleTable';


export default function AvailableCreativesTable({
  data,
  label,
  refetch,
  radius,
  setRadius,
  alreadyBriefed,
  setAlreadyBriefed,
  titleIcon,
  isSearchByWorkingArea,
}: {
  data: Contact[];
  label: string;
  refetch?: GetPhotographerSearchDataType;
  radius?: number
  setRadius?: React.Dispatch<React.SetStateAction<number>>
  alreadyBriefed?: boolean
  setAlreadyBriefed?: React.Dispatch<React.SetStateAction<boolean>>
  titleIcon?: React.ReactNode
  isSearchByWorkingArea?: boolean
}) {
  const toast = useToast();
  const {projectId} = useParams();
  const [rows, setRows] = useState<Contact[]>(data);

  const [{error}, updateContact] = useUpdateCreativeContact(projectId);

  const saveContact = async (id: string, name?: string, value?: number | string | boolean) => {
    const contact = rows.find((r: Contact) => r.creativeId === id);

    const updateData = {
      creativeId: contact?.creativeId || id,
      name: contact?.name,
      adminFlag: !!contact?.adminFlag,
      whatsApp: !!contact?.whatsApp,
      email: !!contact?.email,
      interested: contact?.interested,
    } as Contact;

    if (contact?.alternativeDates) {
      updateData.alternativeDates = contact?.alternativeDates;
    }

    if (contact?.travelCosts) {
      updateData.travelCosts = contact?.travelCosts;
    }

    await handleUpdateContact(name ? {...updateData, [name]: value} : updateData);
  };

  const handleUpdateContact = async (data: Contact) => {
    try {
      await updateContact({data});
      toast({
        title: `${data.name} contact info updated`,
        status: 'success',
        isClosable: true,
      });
      await refetch();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  useEffect(
    () => {
      if (error) {
        toast({
          title: 'Error updating contact info',
          status: 'error',
          isClosable: true,
        });
      }
    },
    [error],
  );

  useEffect(
    () => {
      setRows(data);
    },
    [data],
  );

  const handleAdminFlagChange = (id: string, value: boolean) => {
    setRows(
      (prev: Contact[]) => prev.map(
        (r: Contact) => r.creativeId === id ? {...r, adminFlag: value} : r,
      ),
    );

    saveContact(id, 'adminFlag', value);
  };

  const columnsData = {
    adminFlag: {
      columnDef: {
        Header: 'Flag',
        accessor: 'adminFlag',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId} = cell.row.original;
        const row = rows.find((r) => r.creativeId === creativeId);

        return <AiFillStar
          color={row?.adminFlag ? '#6b52ff' : 'lightgrey'}
          size='25'
          onClick={() => handleAdminFlagChange(creativeId, !row.adminFlag)}
        />;
      },
    },
    name: {
      columnDef: {
        Header: 'Photographer',
        accessor: 'name',
      },
      renderer: (cell: Cell<Contact>) => (
        <Link
          as={NavLink} to={`/intel/creative/${cell.row.original.creativeId}`}
          fontWeight='bold'
          color='black'
        >
          {cell.value}
        </Link>
      ),

    },
    tags: {
      columnDef: {
        Header: 'To do',
        accessor: 'tags',
      },
      renderer: (cell: Cell) => {
        return (
          <Flex>
            {cell.value.map((tag: string) => {
              if (tag === onboardingOrPackageStatus.REGISTRATION_NOT_FINISHED) {
                return (
                  <Tooltip label="Registration is not finished" key={tag} hasArrow>
                    <span>
                      <AiOutlineExclamation size={25}/>
                    </span>
                  </Tooltip>
                );
              } else if (tag === onboardingOrPackageStatus.NOT_ON_PACKAGE) {
                return (
                  <Tooltip label="Not on package" key={tag} hasArrow>
                    <span>
                      <TbPackageOff size={25}/>
                    </span>
                  </Tooltip>
                );
              } else {
                return null;
              }
            })}
          </Flex>
        );
      },
    },
    closestArea: {
      columnDef: {
        Header: isSearchByWorkingArea ? 'Closest area' : 'Home town',
        accessor: 'closestArea',
      },
      renderer: (cell: Cell<Contact>) => {
        const {closestArea, distance, homeTown} = cell.row.original;
        return closestArea ? (
          <Box color='black'>
            <Text>{`${closestArea?.name} + ${closestArea?.radius} km`}</Text>
            <Text>{`distance: ${closestArea?.distance} km`}</Text>
          </Box>
        ) : (
          <Box color='black'>
            <Text>{homeTown}</Text>
            <Text>{`distance: ${distance} km`}</Text>
          </Box>
        );
      },
    },
    travelCosts: {
      columnDef: {
        Header: 'Travel costs',
        accessor: 'travelCosts',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId, travelCosts} = cell.row.original;
        return <CustomInput
          key={creativeId}
          initialValue={travelCosts || ''}
          name='travelCosts'
          id={creativeId}
          onUpdate={(value, name, id) => {
            setRows((prev: Contact[]) =>
              prev.map((r: Contact) => (r.creativeId === id ? {...r, [name]: value} : r)),
            );
          }}
          handleSave={saveContact}
        />;
      },
    },
    alternativeDates: {
      columnDef: {
        Header: 'Alternative dates',
        accessor: 'alternativeDates',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId, alternativeDates} = cell.row.original;

        return <CustomInput
          key={creativeId}
          initialValue={alternativeDates || ''}
          name='alternativeDates'
          id={creativeId}
          onUpdate={(value, name, id) => {
            setRows((prev: Contact[]) =>
              prev.map((r: Contact) => (r.creativeId === id ? {...r, [name]: value} : r)),
            );
          }}
          handleSave={saveContact}
        />;
      },
    },
    whatsApp: {
      columnDef: {
        Header: 'WhatsApp',
        accessor: 'whatsApp',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId} = cell.row.original;

        return <CustomIconTwoOption
          initialValue={cell.value} name='whatsApp'
          id={creativeId}
          onUpdate={(value, name, id) => {
            setRows(
              (prev: Contact[]) => prev.map(
                (r: Contact) => r.creativeId === id
                  ? {...r, [name]: value}
                  : r,
              ),
            );
          }}
          handleSave={saveContact}
        />;
      },
    },
    email: {
      columnDef: {
        Header: 'Email',
        accessor: 'email',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId} = cell.row.original;
        return <CustomIconTwoOption
          initialValue={cell.value} name='email'
          id={creativeId}
          onUpdate={(value, name, id) => {
            setRows((prev: Contact[]) =>
              prev.map((r: Contact) => (r.creativeId === id ? {...r, [name]: value} : r)),
            );
          }}
          handleSave={saveContact}
        />;
      },
    },
    interested: {
      columnDef: {
        Header: 'Interested',
        accessor: 'interested',
      },
      renderer: (cell: Cell<Contact>) => {
        const {creativeId, interested} = cell.row.original;
        return (<CustomIconThreeOption
          initialValue={interested}
          name='interested'
          id={creativeId}
          onUpdate={(value, name, id) => {
            setRows((prev: Contact[]) =>
              prev.map((r: Contact) => (r.creativeId === id ? {...r, [name]: value} : r)),
            );
          }}
          handleSave={saveContact}
        />);
      },
    },
  };

  let menuComp;
  if (!isSearchByWorkingArea) {
    menuComp = <ExtendedSearchTableMenu
      refetch={refetch}
      radius={radius}
      setRadius={setRadius}
      alreadyBriefed={alreadyBriefed}
      setAlreadyBriefed={setAlreadyBriefed}
    />;
  }

  return <Box mb='30px'>
    <SimpleTable
      variant='underlineRows'
      menuComp={menuComp}
      label={label}
      columnsData={Object.values(columnsData).map((column: any) => column.columnDef)}
      renderersData={columnsData}
      tableData={extendContactsWithBackgroundColor(data)}
      total={data?.length}
      titleIcon={titleIcon}
      generateRowId={({creativeId}) => creativeId}
    />
  </Box>;
}
