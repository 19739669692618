import {Box, Flex, FormLabel, Input, Switch} from '@chakra-ui/react';
import {zodResolver} from '@hookform/resolvers/zod';
import {Dispatch, SetStateAction} from 'react';
import {useForm} from 'react-hook-form';
import {EditingType, ProjectDriver, ShootingType} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';
import {getDeliveryDeadlinesAndPricingSchema} from '../../shemas';
import DeliveryDeadlineEditor from './DeliveryDeadlineEditor';
import PriceEditor from './PriceEditor';
import DurationEditor from './DurationEditor';
import FormStepperButtons from '../../TabStepperButtons';
import FormErrors from '../../FormErrors';

export default function PackageBasicDataEditTab(props: {
  nextFormStep: () => void,
  prevFormStep: () => void,
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
}) {
  const {packageState, setPackageState, nextFormStep, prevFormStep} = props;
  const {projectDrivers} = packageState;

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    resolver: zodResolver(getDeliveryDeadlinesAndPricingSchema(packageState)),
    defaultValues: packageState,
  });

  const handleSwitch = (checked: boolean)=> {
    if (checked) {
      if (!packageState.projectDrivers?.includes(ProjectDriver.DELIVERY_ON_WORKDAY)) {
        setPackageState(
          (prev)=> (
            {
              ...prev, projectDrivers: [...projectDrivers, ProjectDriver.DELIVERY_ON_WORKDAY],
            }
          ),
        );
      }
    } else {
      setPackageState(
        (prev)=> (
          {
            ...prev,
            projectDrivers: projectDrivers.filter((d)=> d !==ProjectDriver.DELIVERY_ON_WORKDAY ),
          }
        ),
      );
    }
  };

  const handleNumberInputChange = (stringValue: string, name: string) => {
    const value = parseFloat(stringValue);
    setValue(name as keyof PackageType, value);
  };

  const onSubmit = (data: PackageType) => {
    setPackageState((prevState) => (
      {
        ...prevState,
        ...data,
      }
    ));
    nextFormStep();
  };

  const deliveryAtTimeEnabled = packageState.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME);

  return (
    <Box w='100%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          direction={{base: 'column', md: 'row'}}
          justifyContent='space-evenly'
          alignItems={{base: 'center', md: 'start'}}
          w='100%'
        >
          <Box w='400px'>
            <DurationEditor
              handleNumberInputChange={handleNumberInputChange}
              setPackageState={setPackageState}
              packageState={packageState}
              register={register}
              label= {`Duration`}
              id={'duration'}
            />

            {
              packageState.shootingType === ShootingType.VIDEO
                && <DurationEditor
                  handleNumberInputChange={handleNumberInputChange}
                  packageState={packageState}
                  setPackageState={setPackageState}
                  register={register}
                  label= {`Length of video`}
                  id={'videoLength'}
                />
            }

            <DeliveryDeadlineEditor packageState={packageState}
              setPackageState={setPackageState}
              register={register}
              handleNumberInputChange={handleNumberInputChange}
              setValue={setValue}
              label= {`Photographer's delivery deadline`}
              id={'photographerDeliveryTime'}
              deliveryAtTimeEnabled={deliveryAtTimeEnabled}
            />

            <DeliveryDeadlineEditor packageState={packageState}
              setPackageState={setPackageState}
              register={register}
              handleNumberInputChange={handleNumberInputChange}
              setValue={setValue}
              label= {`Delivery deadline`}
              id={'deliveryTime'}
              deliveryAtTimeEnabled={deliveryAtTimeEnabled}
            />

            {
              deliveryAtTimeEnabled
              && <>
                <FormLabel>No weekend delivery to the client</FormLabel>
                <Switch
                  size='md'
                  defaultChecked={projectDrivers?.includes(ProjectDriver.DELIVERY_ON_WORKDAY)}
                  onChange={(e)=>handleSwitch(e.target.checked)}
                />
              </>
            }
          </Box>

          <Box w='400px'>
            <FormLabel>Retouched images</FormLabel>
            <Input
              variant='formInput'
              type='number'
              onChange={(e)=>handleNumberInputChange(e.target.value, `retouchedPicsNum`)}
              defaultValue={packageState.retouchedPicsNum} />

            <FormLabel>Images to be delivered</FormLabel>
            <Input
              variant='formInput'
              type='number'
              onChange={(e)=>handleNumberInputChange(e.target.value, `deliveredPicsNum`)}
              defaultValue={packageState.deliveredPicsNum}
            />

            <PriceEditor
              packageState={packageState}
              setPackageState={setPackageState}
              register={register}
              handleNumberInputChange={handleNumberInputChange}
              label= {`Price`}
              id={'price'}
            />

            <PriceEditor
              packageState={packageState}
              setPackageState={setPackageState}
              register={register}
              handleNumberInputChange={handleNumberInputChange}
              label= {`Price of photographer`}
              id={'priceOfPhotographer'}
            />

            {
              packageState.editingSettings.includes(EditingType.MANUAL_EDITING)
              && <PriceEditor
                packageState={packageState}
                setPackageState={setPackageState}
                register={register}
                handleNumberInputChange={handleNumberInputChange}
                label={`Manual editing per images price`}
                id={'manualEditingPrice'}
              />
            }

            <PriceEditor
              packageState={packageState}
              setPackageState={setPackageState}
              register={register}
              handleNumberInputChange={handleNumberInputChange}
              label={`Price per image`}
              id={'editingPrice'}
            />
          </Box>
        </Flex>

        <Flex
          w='100%'
          justifyContent='center'
          direction='column'
          alignItems='center'
        >
          <FormErrors errors={errors}/>
          <FormStepperButtons prevFormStep={prevFormStep}/>
        </Flex>
      </form>
    </Box>
  );
}
