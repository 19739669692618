import {EditingType, ExtraType, ProjectDriver} from 'views/packages/constants';
import {EditingTypeValue, PackageType} from 'views/packages/types';

export const getDefaultExtraTypes = (packageState: PackageType) => {
  const {projectDrivers, extras} = packageState;
  const extraTypes = [];
  if (projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC)) {
    extraTypes.push(ExtraType.DYNAMIC_EXTRA);
  }
  if (extras?.some((extra) => extra.name === 'extraPics')) {
    extraTypes.push(ExtraType.BASIC_EXTRA_IMAGES);
  }
  if (extras?.some((extra) => extra.name !== 'extraPics')) {
    extraTypes.push(ExtraType.OTHER_EXTRA);
  }
  if (
    !projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC)
    && (extras?.length < 1 || !extras)
  ) {
    extraTypes.push(ExtraType.NO_EXTRA);
  }
  return extraTypes;
};

export const getDefaultEditingSettings = (packageState: PackageType) => {
  const settings: EditingTypeValue[] = [];
  if (packageState) {
    if (packageState?.manualEditingEnabled) {
      settings.push(EditingType.MANUAL_EDITING);
    }
    if (packageState?.backgroundRemovalEnabled) {
      settings.push(EditingType.AI_EDITING);
    }
    if (settings?.length === 0) {
      settings.push(EditingType.NO_EDITING);
    }
  }
  return settings;
};

