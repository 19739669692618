import React from 'react';
import MenuLayout from '../../../layouts/MenuLayout';
import RoleGuard from '../../../layouts/RoleGuard';
import {adminRoles} from '../../../variables/roles';
import QualityControl from './QualityControl';


export default function QualityControlPage() {
  return (
    <RoleGuard page roles={[adminRoles.RolePhotonAdmin.key, adminRoles.RolePhotonSuperAdmin.key]}>
      <MenuLayout page={<QualityControl />} />
    </RoleGuard>
  );
}
