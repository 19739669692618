export const DeliveryCriteriaStatus= {
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
};

export const deliveryCriteriaStatusLabels={
  [DeliveryCriteriaStatus.ACTIVE]: 'Active',
  [DeliveryCriteriaStatus.DEACTIVATED]: 'Inactive',
};

export const DeliveryCriteriaCategory= {
  QUALITY: 'quality',
  QUANTITY_COVER_HERO: 'cover/hero',
  QUANTITY_CLOSE_UP: 'close-up',
};

export const deliveryCriteriaCategoryLabels= {
  [DeliveryCriteriaCategory.QUALITY]: 'Quality',
  [DeliveryCriteriaCategory.QUANTITY_COVER_HERO]: 'Quantity: cover/hero',
  [DeliveryCriteriaCategory.QUANTITY_CLOSE_UP]: 'Quantity: close-up',
};

export const textInputs = ['projectName', 'clientName', 'packageName', 'creative', 'editor'];


