import {Box, Flex, FormLabel, Image, Text, Textarea} from '@chakra-ui/react';
import {PackageType} from 'views/packages/types';
import {getDefaultExtraTypes, getDefaultEditingSettings} from '../edit/edit-tabs/basic/helpers';
import {editingTypeLabels, extraTypeLabels, ProjectDriver} from 'views/packages/constants';

export default function PackageBasicDataViewTab(props: { packageData: PackageType }) {
  const {packageData} = props;
  const extraTypes = getDefaultExtraTypes(packageData);
  const editingSettings = getDefaultEditingSettings(packageData);

  return (
    <>
      <Flex mt='50px' direction={{base: 'column', md: 'row'}} justifyContent={'space-around'}>
        <Box>
          <FormLabel>Name</FormLabel>
          <Text variant='custom'>{packageData.name}</Text>

          <FormLabel>Client</FormLabel>
          <Text variant='custom'>{packageData.clientName}</Text>

          <FormLabel>Type</FormLabel>
          <Text variant='custom'>{packageData.shootingType}</Text>

          <FormLabel>Category</FormLabel>
          <Text variant='custom'>{packageData.category}</Text>
        </Box>

        <Box>
          <FormLabel>Description</FormLabel>
          <Textarea
            readOnly
            variant='custom'
            borderRadius='15px'
            mb={4}
          >
            {packageData.description}
          </Textarea>

          <FormLabel>Description for photographers</FormLabel>
          <Textarea
            readOnly
            variant='custom'
            borderRadius='15px'
            mb={4}
          >
            {packageData.photographerDescription}
          </Textarea>

          <FormLabel>Settings</FormLabel>
          <FormLabel>Extras</FormLabel>
          <Text variant="custom">
            {extraTypes.map((type: string) => extraTypeLabels[type]).join(', ')}
          </Text>

          <FormLabel>Delivery deadlines</FormLabel>
          <Text variant="custom">
            {
              packageData.projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME)
                ? 'Deadline with date & time'
                : 'Deadline in duration'
            }
          </Text>

          <FormLabel>Editing</FormLabel>
          <Text variant="custom">
            {editingSettings.map((type: string) => editingTypeLabels[type]).join(', ')}
          </Text>
        </Box>

      </Flex>
      <FormLabel
        textAlign='center'
        mt='20px'
      >
        Example photos
      </FormLabel>
      {
        packageData.refPics.length > 0 ? <Flex
          wrap="wrap"
          gap={4}
          mb={4}
        >
          {
            packageData.refPics.map(
              (p, index) => (
                <Box
                  key={index}
                  // width={{base: '50%', sm: '40%', md: '30%', lg: '18%'}}
                  minW={'200px'}
                  maxW={'250px'}
                >
                  <Image
                    src={p.url}
                    alt={`Reference ${index}`}
                    borderRadius="md"
                    boxShadow="md"
                    width="100%"
                  />
                </Box>
              ),
            )
          }
        </Flex>
          : <Text textAlign='center'>Ther's no reference picture on package</Text>
      }
    </>
  );
}
