import React from 'react';
import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Text,
  Box,
  Select,
  InputRightElement,
} from '@chakra-ui/react';
import {TiDeleteOutline} from 'react-icons/ti';
import {
  citySelectorStyles,
  useLoadCityOptionsWithDebounce,
} from 'views/creative/helpers/getCities';
import {AsyncSelect} from 'chakra-react-select';
import useCountryList from 'hooks/useCountryList';
import {CountryItem} from 'services/country-list';


export type RecruitmentListFilterTypes = {
  country: any;
  city: string;
  status: string;
};

const RemoveInputContent = ({
  handleFilterChange,
  name,
}: {
  handleFilterChange: Function;
  name: string;
}) => <InputRightElement>
  <Button size='20' onClick={() => handleFilterChange('', name)}>
    <TiDeleteOutline size='20' />
  </Button>
</InputRightElement>;


const RecruitmentListFilters = ({
  filters,
  setFilters,
}: {
  filters: Partial<RecruitmentListFilterTypes>;
  setFilters: (updatedFilters: Partial<RecruitmentListFilterTypes>) => void;
}) => {
  const loadCityOptionsWithDebounce = useLoadCityOptionsWithDebounce();
  const {countryList} = useCountryList();

  const handleFilterChange = (value: string, name: keyof RecruitmentListFilterTypes) => {
    let updatedFilters: Partial<RecruitmentListFilterTypes>;

    if (name === 'country') {
      updatedFilters = {...filters, city: '', [name]: value};
    } else {
      updatedFilters = {...filters, [name]: value};
    }

    setFilters(updatedFilters as RecruitmentListFilterTypes);
  };

  return <Box w='100%' >
    <Grid
      templateColumns='repeat(auto-fit, minmax(320px, 1fr))'
      gap='0px 0px'
      justifyItems='center'
      alignItems='center'
      p='0px'
    >
      {
        <Flex direction='column' mb={4}>
          <Text>City:</Text>
          <InputGroup zIndex={1}>
            <AsyncSelect
              placeholder='Select city'
              loadOptions={loadCityOptionsWithDebounce}
              // eslint-disable-next-line
              onChange={(option: any) => {
                const [city, country] = option.value.name.split(',').map((item: string) => item.trim());
                setFilters({...filters, city, country});
              }}
              chakraStyles={citySelectorStyles}
              isClearable={!!filters.city}
              value={
                filters?.city
                  ? {value: filters.city, label: filters.city}
                  : {value: '', label: 'Select city'}
              }
              components={{
                DropdownIndicator: () => null,
                ClearIndicator: () => (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'city'} />
                ),
              }}
            />
          </InputGroup>
        </Flex>
      }

      {
        <Flex direction='column' mb={4} width='300px'>
          <Text>Country:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'country')}
            placeholder='Select country'
            value={filters?.country || ''}
          >
            {
              countryList.map(
                (country: CountryItem) => <option key={country.value} value={country.value}>
                  {country.label}
                </option>,
              )
            }
          </Select>
        </Flex>
      }

      {
        <Flex direction='column' mb={4} width='300px'>
          <Text>Status:</Text>
          <Select
            onChange={(e) => handleFilterChange(e.target.value, 'status')}
            placeholder='Select status'
            value={filters?.status || ''}
          >
            <option key={'active'} value={'active'}>
              Active
            </option>
            <option key={'inactive'} value={'inactive'}>
              Inactive
            </option>
          </Select>
        </Flex>
      }
      <Flex justifyContent={{base: 'center', md: 'start'}} w='300px'>
        <Button
          onClick={() => setFilters({city: '', country: '', status: ''})}
          variant='photonMedium'
          w='130px'
        >
          Reset filters
        </Button>
      </Flex>
    </Grid>
  </Box>;
};

export default RecruitmentListFilters;
