import MenuLayout from '../../layouts/MenuLayout';
import RoleGuard from '../../layouts/RoleGuard';
import {adminRoles} from '../../variables/roles';
import PackageView from './PackageView';

export default function PackageViewPage() {
  return (
    <RoleGuard
      page
      roles={[
        adminRoles.RolePhotonJury.key,
        adminRoles.RolePhotonManager.key,
        adminRoles.RolePhotonAdmin.key,
        adminRoles.RolePhotonSuperAdmin.key,
      ]}
    >
      <MenuLayout page={<PackageView />} />
    </RoleGuard>
  );
}
