import {Box, Button, Flex, FormLabel, Input, Select, Textarea, Wrap} from '@chakra-ui/react';
import {useGetClientNames} from 'api/clients';
import {Dispatch, SetStateAction, useState} from 'react';
import {PackageType} from 'views/packages/types';
import {EditingType, editingTypeLabels, ExtraType, extraTypeLabels, packageCategoryLabels, packageTypeLabels, ProjectDriver, projectDriverLabels} from 'views/packages/constants';
import {ChevronRightIcon} from '@chakra-ui/icons';
import {zodResolver} from '@hookform/resolvers/zod';
import {useForm} from 'react-hook-form';
import {BasicDataSchema} from '../../shemas';
import {getDefaultEditingSettings, getDefaultExtraTypes} from './helpers';
import FormErrors from '../../FormErrors';
import PhotographerRefPics from '../photographers/PhotographerRefPics';

type ClientOption = {
_id: string,
name: string
}

const settingButtonStyle = {
  px: 3,
  m: 0,
  mr: 1,
};

export default function PackageBasicDataEditTab(props: {
  nextFormStep: () => void,
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
}) {
  const {packageState, setPackageState, nextFormStep} = props;
  const [{data: clientNames}] = useGetClientNames();
  const [extraTypes, setExtraTypes] = useState(packageState?.extraTypes ?? getDefaultExtraTypes(packageState));
  const [editingSettings, setEditingSettings] = useState(packageState.editingSettings ?? getDefaultEditingSettings(packageState));
  const {projectDrivers} = packageState;

  const {
    register,
    handleSubmit,
    formState: {errors},
    watch,
  } = useForm({
    resolver: zodResolver(BasicDataSchema),
    defaultValues: packageState,
  });

  const onSubmit = (data: PackageType) => {
    let updatedDrivers = projectDrivers || [];

    if (
      extraTypes?.includes(ExtraType.DYNAMIC_EXTRA)
      && !updatedDrivers?.includes(ProjectDriver.PRICING_DYNAMIC)
    ) {
      updatedDrivers = [...updatedDrivers, ProjectDriver.PRICING_DYNAMIC];
      setPackageState(
        {
          ...packageState,
          projectDrivers: [...projectDrivers || [], ProjectDriver.PRICING_DYNAMIC],
        },
      );
    }

    if (!extraTypes?.includes(ExtraType.DYNAMIC_EXTRA)) {
      updatedDrivers = updatedDrivers.filter(
        (d)=> (
          d !== ProjectDriver.PRICING_DYNAMIC
          && d !== ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT
          && d !== ProjectDriver.PRICING_DYNAMIC_WITH_CORRECTION
        ),
      );
    }

    setPackageState((prevState) => (
      {
        ...prevState,
        ...data,
        editingSettings: editingSettings,
        extraTypes: extraTypes,
        manualEditingEnabled: editingSettings?.includes(EditingType.MANUAL_EDITING),
        backgroundRemovalEnabled: editingSettings?.includes(EditingType.AI_EDITING),
        projectDrivers: updatedDrivers,
        extras: (extraTypes?.includes(ExtraType.DYNAMIC_EXTRA) || extraTypes?.includes(ExtraType.NO_EXTRA)) ? [] : prevState.extras,
      }
    ));
    nextFormStep();
  };


  const handleEditingSettingChange = (name: string) => {
    setEditingSettings((prevSettings) => {
      let newSettings = prevSettings;

      if (name === EditingType.NO_EDITING) {
        newSettings = [name];
      } else if (!prevSettings.includes(name)) {
        newSettings = [...prevSettings, name].filter((s) => s !== EditingType.NO_EDITING);
      }

      return newSettings;
    });
  };

  const handleDeliverySettingChange = (name: string) => {
    if (
      name === ProjectDriver.DELIVERY_AT_TIME
      && !projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME)
    ) {
      setPackageState(
        {
          ...packageState,
          projectDrivers: [...projectDrivers || [], ProjectDriver.DELIVERY_AT_TIME],
        },
      );
    } else {
      setPackageState(
        {
          ...packageState,
          projectDrivers: projectDrivers.filter((d)=> d !== ProjectDriver.DELIVERY_AT_TIME),
        },
      );
    }
  };

  const handleExtraSettingsChange = (name: string) => {
    setExtraTypes((prevTypes) => {
      let updatedTypes = [...prevTypes];

      if (!updatedTypes?.includes(name)) {
        updatedTypes.push(name);
      } else {
        updatedTypes = updatedTypes.filter(
          (e) => e !== name,
        );
      }

      if (
        name === ExtraType.DYNAMIC_EXTRA
        || name === ExtraType.NO_EXTRA
      ) {
        updatedTypes = [name];
      }

      if (
        name === ExtraType.BASIC_EXTRA_IMAGES
        || name === ExtraType.OTHER_EXTRA
      ) {
        updatedTypes = updatedTypes.filter(
          (e) => e !== ExtraType.NO_EXTRA && e !== ExtraType.DYNAMIC_EXTRA,
        );
      }

      if (updatedTypes.length === 0) {
        updatedTypes = [ExtraType.NO_EXTRA];
      }

      return updatedTypes;
    });
  };

  const watchClientId = watch('clientId');

  return (
    <Box w='100%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          direction={{base: 'column', md: 'row'}}
          justifyContent='space-evenly'
          alignItems={{base: 'center', md: 'start'}}
          w='100%'
        >
          <Box w={{base: '90%', md: '45%'}} maxW='600px'>
            <FormLabel>Name</FormLabel>
            <Input variant='formInput' {...register('name')} value={watch('name')}/>

            <FormLabel>Client</FormLabel>
            <Select
              {...register('clientId')}
              bg='white'
              borderRadius='15px'
              mb={4}
              value={watchClientId}
            >
              <option value={''}>
                {'Select client'}
              </option>
              {clientNames?.map((c: ClientOption) => (
                <option key={c._id} value={c._id}>
                  {c.name}
                </option>
              ))}
            </Select>

            <FormLabel>Type</FormLabel>
            <Select
              {...register('shootingType')}
              placeholder='Select shooting type'
              bg='white'
              borderRadius='15px'
              mb={4}
            >
              {
                Object.entries(packageTypeLabels).map(
                  ([value, label]: [string, string]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ),
                )
              }
            </Select>

            <FormLabel>Category</FormLabel>
            <Select
              {...register('category')}
              placeholder='Select category'
              bg='white'
              borderRadius='15px'
              mb={4}
            >
              {
                Object.entries(packageCategoryLabels).map(
                  ([value, label]: [string, string]) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))
              }
            </Select>


          </Box>

          <Box
            w={{base: '90%', md: '45%'}}
            maxW='600px'
          >
            <FormLabel>Settings</FormLabel>
            <FormLabel>Extras</FormLabel>
            <Wrap mb={4}>
              {Object.values(ExtraType).map((type: string)=> <Button
                key={type}
                variant={extraTypes.includes(type) ? 'photonLight' :'photonMedium'}
                onClick={()=>handleExtraSettingsChange(type)}
                {...settingButtonStyle}
              >
                {extraTypeLabels[type]}
              </Button>,
              )}
            </Wrap>

            <FormLabel>Delivery deadlines</FormLabel>
            <Wrap mb={4}>
              <Button
                variant={projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME) ? 'photonLight' :'photonMedium'}
                onClick={()=>handleDeliverySettingChange(ProjectDriver.DELIVERY_AT_TIME)}
                {...settingButtonStyle}
              >
                {projectDriverLabels[ProjectDriver.DELIVERY_AT_TIME]}
              </Button>

              <Button
                variant={!projectDrivers?.includes(ProjectDriver.DELIVERY_AT_TIME) ? 'photonLight' :'photonMedium'}
                onClick={()=>handleDeliverySettingChange('')}
                {...settingButtonStyle}
              >
                Deadline in duration
              </Button>
            </Wrap>

            <FormLabel>Editing</FormLabel>
            <Wrap mb={10}>
              {
                Object.values(EditingType).map(
                  (type)=> <Button
                    key={type}
                    variant={editingSettings?.includes(type) ? 'photonLight' :'photonMedium'}
                    onClick={()=>handleEditingSettingChange(type)}
                    {...settingButtonStyle}
                  >
                    {editingTypeLabels[type]}
                  </Button>,
                )
              }
            </Wrap>

          </Box>
        </Flex>
        <Flex
          justifyContent='space-evenly'
          alignItems={{base: 'center', md: 'start'}}
          direction={{base: 'column', md: 'row'}}
        >
          <Box
            w={{base: '90%', md: '45%'}}
            maxW='600px'
          >
            <FormLabel>Description</FormLabel>
            <Textarea
              variant='custom'
              borderRadius='15px'
              mb={4}
              {...register('description')}
            />
          </Box>
          <Box
            w={{base: '90%', md: '45%'}}
            maxW='600px'
          >
            <FormLabel>Description for photographers</FormLabel>
            <Textarea
              variant='custom'
              borderRadius='15px'
              mb={4}
              {...register('photographerDescription')}
            />
          </Box>
        </Flex>

        <PhotographerRefPics
          packageState={packageState}
          setPackageState={setPackageState}
        />

        <Flex
          w='100%'
          justifyContent='center'
          direction='column'
          alignItems='center'
          my='30px'
        >
          <FormErrors errors={errors}/>
          <Button
            w='100px'
            ml='0px'
            variant="photonFormDark"
            pr="28px"
            rightIcon={<ChevronRightIcon w="30px" h="30px" />}
            type='submit'
          >
            Next
          </Button>
        </Flex>
      </form>
    </Box>
  );
}
