import {FormLabel, Input, Box, GridItem, Switch} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {ProjectDriver} from 'views/packages/constants';
import {Currency as CurrencyType, PackageType} from 'views/packages/types';
import {DynamicPriceEditor} from './PriceEditor';
import {UseFormRegister} from 'react-hook-form';
import CurrencySelector from '../../CurrencySelector';

export default function DynamicExtraEditor(props: {
    packageState: PackageType
    setPackageState: Dispatch<SetStateAction<PackageType>>
    handleSwitch: (checked: boolean, driver: string, removeDriver?: string) => void
    handleNumberInputChange: (numberString: string, name: string) => void
    register: UseFormRegister<PackageType>
    currency: CurrencyType
}) {
  const {
    packageState,
    setPackageState,
    handleSwitch,
    handleNumberInputChange,
    register,
    currency,
  } = props;


  const handleThresholdCorrectionSwitch =(checked: boolean)=> {
    handleSwitch(
      checked,
      ProjectDriver.PRICING_DYNAMIC_WITH_THRESHOLD_CORRECTION,
      ProjectDriver.PRICING_DYNAMIC_WITH_CORRECTION,
    );
  };

  const handleNormalCorrectionSwitch =(checked: boolean)=> {
    handleSwitch(
      checked,
      ProjectDriver.PRICING_DYNAMIC_WITH_CORRECTION,
      ProjectDriver.PRICING_DYNAMIC_WITH_THRESHOLD_CORRECTION,
    );
  };

  return (
    <>
      <GridItem display='flex' flexDirection='column' alignItems='center' >
        <Box w='400px'>
          <FormLabel>Over limit fixed extra calculation</FormLabel>
          <Switch
            mb={4}
            size='md'
            isChecked={packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT)}
            onChange={(e)=>handleSwitch(e.target.checked, ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT)}
          />
          <FormLabel>Compensation by expected number of photos</FormLabel>
          <Switch
            mb={4}
            size='md'
            isChecked={packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_WITH_CORRECTION)}
            onChange={(e)=>handleNormalCorrectionSwitch(e.target.checked)}
          />
          <FormLabel mb='0px'>Compensation for -7 images submitted for client,</FormLabel>
          <FormLabel> -15 images submitted for photographer</FormLabel>
          <Switch
            mb={4}
            size='md'
            isChecked={packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_WITH_THRESHOLD_CORRECTION)}
            onChange={(e)=>handleThresholdCorrectionSwitch(e.target.checked)}
          />

          <FormLabel>Multiplier</FormLabel>
          <Input
            variant='formInput'
            onChange={(e)=>handleNumberInputChange(e.target.value, `dynamicPricing.rate`)}
            defaultValue={packageState.dynamicPricing?.rate}
          />

          <FormLabel>Currency</FormLabel>
          <CurrencySelector register={register} id={'dynamicPricing'}/>
        </Box>
      </GridItem>

      <GridItem display='flex' flexDirection='column' alignItems='center'>
        <Box w='400px' >
          <DynamicPriceEditor
            setPackageState={setPackageState}
            handleNumberInputChange={handleNumberInputChange}
            label= {`Starting extra image price for client`}
            id={'dynamicPricing.unitPriceForClient'}
            defaultValue={packageState.dynamicPricing?.unitPriceForClient}
            currency={currency}
          />

          <DynamicPriceEditor
            setPackageState={setPackageState}
            handleNumberInputChange={handleNumberInputChange}
            label= {`Starting extra image price for photographer`}
            id={'dynamicPricing.unitPriceForCreative'}
            defaultValue={packageState.dynamicPricing?.unitPriceForCreative}
            currency={currency}
          />

          {
            packageState.manualEditingEnabled
            && <DynamicPriceEditor
              setPackageState={setPackageState}
              handleNumberInputChange={handleNumberInputChange}
              label= {`Starting extra image price for editor`}
              id={'dynamicPricing.unitPriceForEditor'}
              defaultValue={packageState.dynamicPricing?.unitPriceForEditor}
              currency={currency}
            />
          }

          {
            packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC_FIX_OVER_LIMIT)
            && <>
              <FormLabel>Dynamic extra image upper limit</FormLabel>
              <Input
                type='number'
                variant='formInput'
                onChange={(e)=>handleNumberInputChange(e.target.value, `dynamicPricing.upperLimit`)}
                defaultValue={packageState.dynamicPricing?.upperLimit}
              />

              <DynamicPriceEditor
                setPackageState={setPackageState}
                handleNumberInputChange={handleNumberInputChange}
                label= {`Fixed extra image price for client over limit `}
                id={'dynamicPricing.aboveLimitUnitPriceForClient'}
                defaultValue={packageState.dynamicPricing?.aboveLimitUnitPriceForClient}
                currency={currency}
              />

              <DynamicPriceEditor
                setPackageState={setPackageState}
                handleNumberInputChange={handleNumberInputChange}
                label= {`Fixed extra image price for photographer over limit `}
                id={'dynamicPricing.aboveLimitUnitPriceForCreative'}
                defaultValue={packageState.dynamicPricing?.aboveLimitUnitPriceForCreative}
                currency={currency}
              />

              {
                packageState.manualEditingEnabled
                 && <DynamicPriceEditor
                   setPackageState={setPackageState}
                   handleNumberInputChange={handleNumberInputChange}
                   label= {`Fixed extra image price for editor over limit `}
                   id={'dynamicPricing.aboveLimitUnitPriceForEditor'}
                   defaultValue={packageState.dynamicPricing?.aboveLimitUnitPriceForEditor}
                   currency={currency}
                 />
              }
            </>
          }
        </Box>
      </GridItem>
    </>
  );
}
