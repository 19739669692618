import {FormLabel, Input, Text, InputGroup, InputRightElement} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {Extra, PackageType} from 'views/packages/types';

export function DynamicPriceEditor(props: {
  handleNumberInputChange: (numberString: string, name: string) => void
  setPackageState: Dispatch<SetStateAction<PackageType>>
  label: string
  id: 'dynamicPricing.unitPriceForClient'
  | 'dynamicPricing.unitPriceForCreative'
  | 'dynamicPricing.unitPriceForEditor'
  | 'dynamicPricing.aboveLimitUnitPriceForClient'
  | 'dynamicPricing.aboveLimitUnitPriceForCreative'
  | 'dynamicPricing.aboveLimitUnitPriceForEditor'
  | 'creativeAmount'
  | 'amount'
  defaultValue: number
  currency: string
}) {
  const {label, id, handleNumberInputChange, currency, defaultValue} = props;
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          variant='formInput'
          onChange={(e) => handleNumberInputChange(e.target.value, id)}
          defaultValue={defaultValue}
          pr="3rem"
        />
        <InputRightElement width="3rem" pr='20px'>
          <Text>{currency}</Text>
        </InputRightElement>
      </InputGroup>

    </>
  );
}


export function PriceEditor(props: {
  packageState: PackageType
  label: string
  id: 'creativeAmount' | 'amount'
  extraIndex: number
  handleExtraDataChange: (
    value: string,
     field: keyof Extra,
     index: number,
     convertToNumber?: boolean
   ) => void,
}) {
  const {packageState, label, id, handleExtraDataChange, extraIndex} = props;
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <InputGroup>
        <Input
          variant='formInput'
          onChange={(e)=> handleExtraDataChange(e.target.value, id, extraIndex, true)}
          defaultValue={packageState.extras[extraIndex][id]}
        />
        <InputRightElement width="3rem" pr='20px'>
          <Text>{packageState.extras[extraIndex].currency}</Text>
        </InputRightElement>
      </InputGroup>

    </>
  );
}
