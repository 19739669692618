import {Text, Flex, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay} from '@chakra-ui/react';
import {useGetAvailableEditorsForPackage} from 'api/editors';
import {AsyncSelect} from 'chakra-react-select';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {loadEditorOptionsWithDebounce} from 'utils/load-editor-options';
import {creativeSelectorStyles} from 'views/creative/helpers/getCities';
import {Editor, PackageType, Photographer} from 'views/packages/types';

type EditorOption = {
  value: Photographer,
  label: string
}

export default function AddEditorsModal(props: {
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
  isOpen: boolean;
  onClose: (successfulDelete?: boolean) => void;
}) {
  const {packageState, isOpen, onClose, setPackageState} = props;
  const loadEditorOptions = loadEditorOptionsWithDebounce();
  const [{data: editorsData}, getEditors] = useGetAvailableEditorsForPackage();
  const [selectedEditors, setSelectedEditors] = useState([]);

  const handleChange = (data: EditorOption[])=> {
    setSelectedEditors(data.map((c: EditorOption)=>c.value));
  };

  useEffect(() => {
    getEditors({params: {excludedIds, search: ''}});
  }, [getEditors]);

  const addEditorsToPackage = () => {
    setPackageState(
      (prev)=> (
        {
          ...prev,
          editors: [...prev.editors || [], ...selectedEditors],
        }
      ),
    );
    setSelectedEditors([]);
    onClose();
  };

  const excludedIds = [...packageState.editors || [], ...selectedEditors].map((c)=>c.id);
  const defaultOptions = editorsData
    ?.filter((c: Editor) => !excludedIds.includes(c.id))
    .map((c: Editor) => ({value: c, label: c.name}));

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={'6xl'}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text align='center'>Add editors to package</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex justifyContent='center'>
            <AsyncSelect
              isMulti
              placeholder='Select editors'
              loadOptions={(searchText, callback) =>
                loadEditorOptions(searchText, excludedIds, editorsData, callback)
              }
              onChange={(e) => handleChange(e as EditorOption[])}
              chakraStyles={creativeSelectorStyles}
              components={{
                DropdownIndicator: () => null,
              }}
              value={
                selectedEditors?.map(
                  (c) => (
                    {value: c, label: c.name}
                  ),
                )
              }
              defaultOptions={defaultOptions}
            />
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Flex w='100%' justifyContent='center'>
            <Button
              mr='5px'
              w='140px'
              variant='photonMedium'
              onClick={() => onClose()}
            >
              Cancel
            </Button>
            <Button
              w='140px'
              variant='photonDark'
              onClick={addEditorsToPackage}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>

  );
}


