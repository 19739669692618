import {
  Button,
  Grid,
  Flex,
  InputGroup,
  Input,
  Select,
  Text,
  Box,
} from '@chakra-ui/react';
import {useRef} from 'react';
import React from 'react';
import {RemoveInputContent} from 'components/RemoveInputContent';
import {PackageListFilterTypes} from '../types';
import {packageCategoryLabels, packageStatusLabels, packageTypeLabels} from '../constants';
import {defaultPackageFilters} from '../PackageListPage';

const PackageListFilters = ({
  filters,
  handleSearch,
  setFilters,
}: {
    filters: PackageListFilterTypes;
    handleSearch: (resetFilters: PackageListFilterTypes) => void;
    setFilters: React.Dispatch<React.SetStateAction<PackageListFilterTypes>>;
  }) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleFilterChange = (value: string, name: keyof PackageListFilterTypes) => {
    const updatedFilters = {...filters, [name]: value};
    setFilters(updatedFilters as PackageListFilterTypes);

    if ((name === 'search' || name === 'client') && value) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      };
      timeoutRef.current = setTimeout(() => {
        handleSearch(updatedFilters as PackageListFilterTypes);
      }, 1000);
    } else {
      handleSearch(updatedFilters as PackageListFilterTypes);
    }
  };

  return (
    <Box w='100%'>
      <Grid
        templateColumns='repeat(auto-fit, minmax(300px, 1fr))'
        gap='10px 10px'
        m='0px 60px 0px 20px'
        justifyItems='center'
        alignItems='center'
      >
        {
          'search' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Name:</Text>
              <InputGroup w='300px'>
                {filters.search && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'search'} />
                )}
                <Input
                  variant='tableSearch'
                  placeholder='Search by name or email'
                  onChange={(e) => handleFilterChange(e.target.value, 'search')}
                  value={filters.search}
                />
              </InputGroup>
            </Flex>
          )
        }
        {
          'client' in filters && (
            <Flex direction='column' mb={4}>
              <Text>Client:</Text>
              <InputGroup w='300px'>
                {filters.client && (
                  <RemoveInputContent handleFilterChange={handleFilterChange} name={'client'} />
                )}
                <Input
                  variant='tableSearch'
                  placeholder='Search by client'
                  onChange={(e) => handleFilterChange(e.target.value, 'client')}
                  value={filters.client}
                />
              </InputGroup>
            </Flex>
          )
        }
        {
          'status' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Status:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'status')}
                placeholder='All'
                value={filters.status}
              >
                {
                  Object.entries(packageStatusLabels).map(
                    ([value, label]: [string, string]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))
                }
              </Select>
            </Flex>
          )
        }
        {
          'category' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Category:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'category')}
                placeholder='All'
                value={filters.category}
              >
                {
                  Object.entries(packageCategoryLabels).map(
                    ([value, label]: [string, string]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ),
                  )
                }
              </Select>
            </Flex>
          )
        }
        {
          'shootingType' in filters && (
            <Flex direction='column' mb={4} width='300px'>
              <Text>Type:</Text>
              <Select
                onChange={(e) => handleFilterChange(e.target.value, 'shootingType')}
                placeholder='All'
                value={filters.shootingType}
              >
                {
                  Object.entries(packageTypeLabels).map(
                    ([value, label]: [string, string]) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ),
                  )
                }
              </Select>
            </Flex>
          )
        }
      </Grid>
      <Flex direction='row' width='300px' m='10px 0px 10px 20px'>
        <Button
          onClick={() => {
            setFilters(defaultPackageFilters);
            handleSearch(defaultPackageFilters);
          }}
          variant='photonMedium'
          ml='20px'
          w='130px'
        >
              Reset filters
        </Button>
      </Flex>
    </Box>
  );
};
export default PackageListFilters;
