import {FormLabel, Flex, Input, Select} from '@chakra-ui/react';
import {Dispatch, SetStateAction} from 'react';
import {UseFormRegister} from 'react-hook-form';
import {TimeUnit, timeUnitLabels} from 'views/packages/constants';
import {PackageType} from 'views/packages/types';

export default function DurationEditor(props: {
  setPackageState: Dispatch<SetStateAction<PackageType>>
  handleNumberInputChange: (numberString: string, name: string) => void
  register: UseFormRegister<PackageType>
  packageState: PackageType,
  label: string
  id: 'videoLength' | 'duration'
}) {
  const {register, label, id, handleNumberInputChange, packageState} = props;

  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Flex>
        <Input
          variant='formInput'
          type='number'
          onChange={(e)=>handleNumberInputChange(e.target.value, `${id}.amount`)}
          defaultValue={packageState[id]?.amount}
          mr={1}
        />
        <Select
          {...register(`${id}.unit`)}
          bg='white'
          borderRadius='15px'
          mb={4}
        >
          <option key={''} value={''}>
            {`Select unit`}
          </option>
          {
            Object.values(TimeUnit).map(
              (unit: string) => (
                <option key={unit} value={unit}>
                  {timeUnitLabels[unit]}
                </option>
              ),
            )
          }
        </Select>
      </Flex>
    </>
  );
}
