import {
  Button,
  Flex,
  Box,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  useToast,
} from '@chakra-ui/react';
import {useEffect} from 'react';
import React from 'react';
import {
  ManagePackageOption,
  PackageStatus,
} from '../constants';
import {CiMenuKebab} from 'react-icons/ci';
import {useManagePackage} from 'api/packages';
import Bugsnag from '@bugsnag/js';
import {useNavigate} from 'react-router-dom';

const PackageManager = ({
  packageId,
  status,
  refetch,
}: {
  packageId: string;
  status: string;
  refetch:any;
}) => {
  const [{error}, managePackage] = useManagePackage(packageId);
  const toast = useToast();
  const navigate = useNavigate();

  const handleManagePackage = async (type: string) => {
    try {
      const res = await managePackage({data: {type}});
      toast({
        title: res.data.message,
        status: 'success',
        duration: 3000,
      });
      if (type === ManagePackageOption.DUPLICATE) {
        navigate('/intel/packages');
      }
      refetch();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  useEffect(() => {
    if (error) {
      toast({
        title: error.response?.data?.message,
        status: 'error',
        duration: 3000,
      });
    }
  }, [error, toast]);

  return (
    <Box w='100%'>
      <Popover>
        <PopoverTrigger>
          <Button ml='5px' variant='photonMedium'>
            <CiMenuKebab />
          </Button>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverCloseButton />
          <PopoverBody mt='20px'>
            <Flex direction='column'>
              <Button
                isDisabled={status === PackageStatus.ENABLED}
                onClick={() => handleManagePackage(ManagePackageOption.ACTIVATE)}
              >
                  Activate
              </Button>
              <Button
                isDisabled={status !== PackageStatus.ENABLED}
                onClick={() => handleManagePackage(ManagePackageOption.CANCEL)}
              >
                  Cancel
              </Button>
              <Button
                isDisabled={status === PackageStatus.ENABLED}
                onClick={() => handleManagePackage(ManagePackageOption.DELETE)}
              >
                  Delete
              </Button>
              <Button
                onClick={() => handleManagePackage(ManagePackageOption.DUPLICATE)}
              >
                Duplicate
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
export default PackageManager;
