import {useMemo, useState, useEffect, useRef} from 'react';

import {
  Button,
  Flex,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Textarea,
  Switch,
  Link,
  useToast,
} from '@chakra-ui/react';
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {GrLinkNext, GrLinkPrevious} from 'react-icons/gr';
import moment from 'moment';
import RatingFilters from './RatingFilters';
import {ProjectRatingFilters} from '../../dashboard/types';
import {NavLink, useSearchParams} from 'react-router-dom';
import React from 'react';
import {useSendAdminReview} from '../../../api/projects';
import Card from '../../../components/card/Card';

export type Row = {
  original: any;
};

const textInputs = ['search', 'creative', 'editor'];

export default function RatingTable(props: {
  columnsData: any;
  tableData: any;
  total: number;
  page: number;
  perPage: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  callBack: any;
  filters: ProjectRatingFilters;
  setFilters: React.Dispatch<React.SetStateAction<ProjectRatingFilters>>;
}) {
  const {
    columnsData,
    tableData,
    total,
    page,
    perPage,
    setPage,
    setPerPage,
    callBack: callGetProjects,
    setFilters,
    filters,
  } = props;
  const [, setSearchParams] = useSearchParams();
  const [actualProject, setActualProject] = useState<any>({});
  const [adminReview, setAdminReview] = useState<string>('');
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData || [], [tableData]);
  const pageSizes = [1, 5, 10, 25, 50, 100];
  const [showFilters, setShowFilters] = useState(true);
  const toast = useToast();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const {
    isOpen: isHandleModal1Open,
    onOpen: onHandleModal1Open,
    onClose: onHandleModal1Close,
  } = useDisclosure();

  const {
    isOpen: isHandleModal2Open,
    onOpen: onHandleModal2Open,
    onClose: onHandleModal2Close,
  } = useDisclosure();

  const [{error: apiError}, sendAdminReview] = useSendAdminReview(actualProject?._id);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page: pageData,
    prepareRow,
    setPageSize,
    state: {pageSize},
  } = tableInstance;

  const startRow = total !== 0 ? (page - 1) * perPage + 1 : 0;
  const endRow = Math.min(page * perPage, total);
  const actualEndRow = Math.min(endRow, total);

  const handlePageSizeChange = (e: number) => {
    setPage(1);
    setPerPage(e);
  };

  useEffect(() => {
    setSearchParams((params) => {
      params.set('page', page.toString());
      return params;
    });
  }, [page]);

  useEffect(() => {
    setPageSize(perPage);
    setSearchParams((params) => {
      params.set('perPage', perPage.toString());
      return params;
    });
  }, [perPage]);

  const handleSearch = async (resetFilters: ProjectRatingFilters) => {
    try {
      if (resetFilters) {
        const filters = resetFilters;
        callGetProjects(page, pageSize, filters);
      } else {
        callGetProjects(page, pageSize, filters);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleFilterChange = (value: string | Date, name: keyof ProjectRatingFilters) => {
    const updatedFilters = {...filters, [name]: value};
    setFilters(updatedFilters as ProjectRatingFilters);
    const queryString = Object.entries(updatedFilters)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as any)}`)
      .join('&');
    window.history.pushState(null, '', `?${queryString}`);

    if (textInputs.includes(name) && value) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      };
      timeoutRef.current = setTimeout(() => {
        handleSearch(updatedFilters as ProjectRatingFilters);
      }, 1000);
    } else {
      handleSearch(updatedFilters as ProjectRatingFilters);
    }
  };

  const handleButtonClick = (projectData: any): void => {
    setActualProject(projectData);
    onHandleModal1Open();
  };

  const handleSendAdminReview = async () => {
    await sendAdminReview({
      data: {
        adminReview,
      },
    });
    if (!apiError) {
      toast({
        title: 'Az értékelést sikeresen lezártad',
        status: 'success',
        duration: 3000,
      });
    }
    callGetProjects(page, pageSize, filters);
    onHandleModal1Close();
    onHandleModal2Close();
  };

  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  return (
    <Card flexDirection='column'>
      <Flex width='200px' justifyContent={'space-around'} mb='20px'>
        <Text color='gray.400'>{'Show ratings'}</Text>
        <Switch
          isChecked={showFilters}
          colorScheme='brandScheme'
          size='md'
          onChange={() => setShowFilters((prevShowFilters) => !prevShowFilters)}
        />
      </Flex>
      {showFilters && (
        <RatingFilters
          handleFilterChange={handleFilterChange}
          filters={filters}
          handleSearch={handleSearch}
          setFilters={setFilters}
        />
      )}

      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{sm: '10px', lg: '12px'}}
                    color='gray.400'
                  >
                    {column.render('Header')}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>

        <Tbody {...getTableBodyProps()}>
          {pageData.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  const currentRow: Row = cell.row;
                  let data;
                  if (cell.column.Header === ' ') {
                    data = (
                      <Flex>
                        <Button
                          variant='photonMedium'
                          onClick={() => handleButtonClick(currentRow.original)}
                        >
                          {currentRow.original.adminRatingReview?.date ? 'View' : 'Manage'}
                        </Button>
                      </Flex>
                    );
                  } else if (cell.column.id === 'projectName') {
                    data = (
                      <Link
                        as={NavLink}
                        to={`/intel/project/${currentRow.original._id}`}
                        textDecoration='none'
                      >
                        <Text fontWeight='bold' color='black' textAlign='left'>
                          {cell.value}
                        </Text>
                      </Link>
                    );
                  } else if (cell.column.id === 'dateOfRating') {
                    data = (
                      <Flex align='center'>
                        <Text me='10px' color={textColor} fontWeight='700'>
                          {moment(cell.value).format('YYYY.MM.DD.  HH:mm')}
                        </Text>
                      </Flex>
                    );
                  } else if (cell.column.id === 'clientObj') {
                    data = (
                      <Link
                        textDecoration='none'
                        fontWeight='bold'
                        color='black'
                        href={`/intel/client/${cell.value.id}`}
                        target='_blank'
                      >
                        {cell.value.name}
                      </Link>
                    );
                  } else if (cell.column.id === 'photographerObj') {
                    data = (
                      <Link
                        as={NavLink}
                        textDecoration='none'
                        fontWeight='bold'
                        color='black'
                        to={`/intel/creative/${cell.value.id}`}
                      >
                        {cell.value.name}
                      </Link>
                    );
                  } else if (cell.column.id === 'editorObj') {
                    data = (
                      <Link
                        textDecoration='none'
                        fontWeight='bold'
                        color='black'
                        href={`/intel/editor/${cell.value.id}`}
                        target='_blank'
                      >
                        {cell.value.name}
                      </Link>
                    );
                  } else if (cell.column.id === 'photographerReview.message') {
                    data = (
                      <Flex align='center'>
                        <Text me='10px' color={textColor} fontWeight='700'>
                          {cell.value && cell.value.length > 30
                            ? `${cell.value.substring(0, 28)}...`
                            : cell.value}
                        </Text>
                      </Flex>
                    );
                  } else {
                    data = (
                      <Text color={textColor} fontSize='sm' fontWeight='700'>
                        {cell.value}
                      </Text>
                    );
                  }
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{sm: '14px'}}
                      minW={{sm: '150px', md: '200px', lg: 'auto'}}
                      borderColor='transparent'
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <Flex justify='space-between' m='10px' px='10px'>
        <Flex>
          <Select
            value={pageSize}
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
            variant='photonMedium'
            w='75px'
          >
            {pageSizes.map((size) => (
              <option key={size} value={size}>
                {size}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex>
          <Button onClick={() => setPage(page - 1)} bg='white' isDisabled={!page || page === 1}>
            <GrLinkPrevious />
          </Button>
          <Flex justify='center' mx='10px' mt='6px'>
            <Text color='gray.400'>
              {startRow}-{actualEndRow} of {total}
            </Text>
          </Flex>
          <Button
            onClick={() => setPage(page + 1)}
            bg='white'
            isDisabled={!page || endRow === total}
          >
            <GrLinkNext />
          </Button>
        </Flex>
      </Flex>

      <Modal isOpen={isHandleModal1Open && actualProject} onClose={onHandleModal1Close} size='xl'>
        <ModalOverlay />
        <ModalContent minWidth='1000px'>
          <ModalHeader>{actualProject?.projectName}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ModalTable project={actualProject} />
          </ModalBody>
          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onHandleModal1Close}>
              Cancel
            </Button>
            {!actualProject?.adminRatingReview?.date && (
              <Button variant='photonDark' mr={3} onClick={onHandleModal2Open} width='200px'>
                Send feedback
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isHandleModal2Open} onClose={onHandleModal2Close} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Review rating</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              sx={{height: '200px'}}
              onChange={(e) => setAdminReview(e.target.value)}
              placeholder='Write review here...'
            />
          </ModalBody>
          <ModalFooter>
            <Button variant='photonMedium' mr={3} onClick={onHandleModal2Close}>
              Cancel
            </Button>
            <Button variant='photonDark' mr={3} onClick={handleSendAdminReview}>
              Send
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
const ModalTable = (props: { project: any }) => {
  const {project} = props;
  return (
    <Card shadow='md' borderWidth='1px' m='5px'>
      {project && (
        <Table>
          <Tbody>
            <Tr>
              <Th width='250px'>Client</Th>
              <Td fontSize='18px' textAlign='center'>
                {project.client}
              </Td>
            </Tr>
            <Tr>
              <Th>Time of rating</Th>
              <Td fontSize='18px' textAlign='center'>
                {moment(project.dateOfRating).format('YYYY.MM.DD.  HH:mm')}
              </Td>
            </Tr>
            <Tr>
              <Th>Creative</Th>
              <Td fontSize='18px' textAlign='center'>
                {project.photographer}
              </Td>
            </Tr>
            <Tr>
              <Th>Editor</Th>
              <Td fontSize='18px' textAlign='center'>
                {project.editor}
              </Td>
            </Tr>
            <Tr>
              <Th>Rating</Th>
              <Td fontSize='18px' textAlign='center'>
                {project.photographerReview.rating}
              </Td>
            </Tr>
            <Tr>
              <Th>Rating message</Th>
              <Td fontSize='18px' textAlign='center'>
                {project.photographerReview.message}
              </Td>
            </Tr>
            {
              project.storage
              && <Tr>
                <Th>Google drive link</Th>
                <Td fontSize='18px' textAlign='center'>
                  <Link href={project.storage.url} isExternal>
                    <Button variant='photonDark' width='200px'>
                      Open Drive
                    </Button>
                  </Link>
                </Td>
              </Tr>
            }
            {
              project.adminRatingReview?.date
              && <>
                <Tr>
                  <Th>Rating review</Th>
                  <Td fontSize='18px' textAlign='center'>
                    {project.adminRatingReview.message ?? '---'}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Time of review</Th>
                  <Td fontSize='18px' textAlign='center'>
                    {moment(project.adminRatingReview.date).format('YYYY.MM.DD.  HH:mm')}
                  </Td>
                </Tr>
                <Tr>
                  <Th>Name of reviewer</Th>
                  <Td fontSize='18px' textAlign='center'>
                    {project.adminRatingReview.userName}
                  </Td>
                </Tr>
              </>
            }
          </Tbody>
        </Table>
      )}
    </Card>
  );
};
