import React, {useCallback, useEffect, useState} from 'react';
import moment from 'moment';
import {Box, Button, Text, useToast} from '@chakra-ui/react';
import {Cell} from 'react-table';
import {Criteria, EditorFeedbackFilterTypes} from './types';
import {useGetEditorFeedbacks, useUpdateProject} from 'api/projects';
import ListTable from 'components/ListTable';
import EditorFeedbackFilters from './EditorFeedbackFilters';
import {useGetCriterias} from 'api/quality-control';
import {DeliveryCriteriaCategory, DeliveryCriteriaStatus} from './constants';
import {QualitControlFiltersFilterTypes} from 'views/settings/quality-control/types';
import {Link} from 'react-router-dom';
import {MdDone} from 'react-icons/md';
import {IoMdClose} from 'react-icons/io';
import Bugsnag from '@bugsnag/js';
import {useListQueryContext} from 'contexts/useListQueryContext';
import {clearFilters} from 'utils/clear-filters';


export default function EditorFeedbacks() {
  const {
    pageNumber,
    perPage,
    filters,
    setFilters,
    updateFilterSearchParams,
    sorting,
    setPageNumber,
  } = useListQueryContext<EditorFeedbackFilterTypes>();

  const toast = useToast();
  const [currentId, setCurrentId] = useState('');

  const [
    {
      error: updateProjectError,
      loading: updateProjectLoading,
    },
    updateProject,
  ] = useUpdateProject(currentId);

  const [
    {data: criteriasData},
    getCriteriaList,
  ] = useGetCriterias();

  const [
    {data: editorFeedbacks, loading, error: getFeedbacksError},
    getEditorFeedbacks,
  ] = useGetEditorFeedbacks();

  const callGetEditorFeedbacks = useCallback(
    async (
      page: number,
      perPage: number,
      sorting: { field: string; order: number },
      filters: EditorFeedbackFilterTypes,
    ) => {
      try {
        const cleanedFilters = clearFilters(filters);
        await getEditorFeedbacks({
          params: {
            page,
            perPage,
            sorting,
            filters: cleanedFilters,
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
    [getEditorFeedbacks],
  );

  const callGetCriteriaList = async (updatedFilters?: QualitControlFiltersFilterTypes) => {
    getCriteriaList({params: {filters: updatedFilters || filters}});
  };

  useEffect(() => {
    callGetCriteriaList({status: DeliveryCriteriaStatus.ACTIVE});
  }, []);

  const handleSearch =(updatedFilters?: EditorFeedbackFilterTypes)=> {
    setPageNumber(1);
    callGetEditorFeedbacks(1, perPage, sorting, updatedFilters || filters);
    updateFilterSearchParams(updatedFilters);
  };

  useEffect(() => {
    callGetEditorFeedbacks(pageNumber, perPage, sorting, filters);
  }, [pageNumber, perPage, sorting]);

  useEffect(
    () => {
      if (getFeedbacksError || updateProjectError) {
        const error = getFeedbacksError || updateProjectError;
        toast(
          {
            title: getFeedbacksError ? 'Get projects error': 'Update projects error',
            description: error.message,
            status: 'error',
            duration: 3000,
          },
        );
      }
    },
    [getFeedbacksError, updateProjectError],
  );

  const getQualityColumns = ()=> {
    return criteriasData?.filter(
      (c: Criteria)=>c.category === DeliveryCriteriaCategory.QUALITY,
    )
      .reduce(
        // eslint-disable-next-line
        (acc: any, item: Criteria) => {
          acc[item._id] = {
            columnDef: {
              Header: item.name,
              accessor: item._id,
            },
            // eslint-disable-next-line
            renderer: (cell: any) => {
              const markedQualityCriterias = cell.row.original.qualityControl?.markedCriterias;
              return (
                markedQualityCriterias?.includes(item._id) && <IoMdClose size={30} color='red' />
              );
            },
          };
          return acc;
        }, {},
      );
  };

  const handleClick = async (currentValue: boolean) => {
    try {
      await updateProject({data: {qualityControlHandledByAdmin: !currentValue}});
      handleSearch();
    } catch (e) {
      Bugsnag.notify(e as Error);
    }
  };

  const columnsData = {
    'projectName': {
      columnDef: {
        Header: 'Project name',
        accessor: 'projectName',
      },
      renderer: (cell: Cell<{ _id: string }, boolean>) => {
        const id = cell.row.original._id;
        return (
          <Link to={`/intel/project/${id}`}>
            <Text color="black" fontWeight="bold" w='150px'>
              {cell.value}
            </Text>
          </Link>
        );
      },
    },
    'clientName': {
      columnDef: {
        Header: 'Client name',
        accessor: 'clientName',
      },
      renderer: (cell: Cell<{ clientId: string }, boolean>) => {
        const id = cell.row.original.clientId;
        return (
          <Link to={`/intel/client/${id}`}>
            <Text color="black" fontWeight="bold">
              {cell.value}
            </Text>
          </Link>
        );
      },
    },
    'shootingTime': {
      columnDef: {
        Header: 'Shooting date (local)',
        accessor: 'shootingTime',
      },
      renderer: (cell: Cell<{ ianaCode?: string }, Date>) => {
        const ianaCode = cell.row.original.ianaCode;
        return (
          <Text color="black" fontWeight="bold">
            {moment(cell.value).tz(ianaCode || 'Europe/Budapest').format('DD/MM/YYYY HH:mm')}
          </Text>
        );
      },
    },
    'editorDeliveryDate': {
      columnDef: {
        Header: 'Delivery date (editor)',
        accessor: 'editorDeliveryDate',
      },
      renderer: (cell: Cell<[string], Date>) => {
        return (
          <Text color='black' fontWeight='bold'>{moment(cell.value).format('DD/MM/YYYY HH:mm')}</Text>
        );
      },
    },
    'photographer': {
      columnDef: {
        Header: 'Photographer',
        accessor: 'photographer',
      },
      renderer: (cell: Cell<{ name: string, _id: string }, { name: string, _id: string }>) => {
        return (
          <Link to={`/intel/creative/${cell.value._id}`}>
            <Text color="black" fontWeight="bold">
              {cell.value.name}
            </Text>
          </Link>
        );
      },
    },
    'editor': {
      columnDef: {
        Header: 'Editor',
        accessor: 'editor',
      },
      renderer: (cell: Cell<{ name: string, _id: string }, { name: string, _id: string }>) => {
        return (
          <Link to={`/intel/editor/${cell.value._id}`}>
            <Text color="black" fontWeight="bold">
              {cell.value.name}
            </Text>
          </Link>
        );
      },
    },
    ...getQualityColumns(),
    'coverHeroCriteriasNumber': {
      columnDef: {
        Header: 'Number of missing cover/hero images',
        accessor: 'coverHeroCriteriasNumber',
      },
    },
    'closeUpCriteriasNumber': {
      columnDef: {
        Header: 'Number of missing close-up images',
        accessor: 'closeUpCriteriasNumber',
      },
    },
    'storage': {
      columnDef: {
        Header: 'Drive',
        accessor: 'storage',
      },
      renderer: (cell: Cell<{ url: string }, { url: string }>) => {
        return (
          <Link to={cell.value.url} target='_blank'><Button variant='photonMedium'>Drive</Button></Link>
        );
      },
    },
    'qualityControl.handledByAdmin': {
      columnDef: {
        Header: 'Done',
        accessor: 'qualityControl.handledByAdmin',
      },
      renderer: (cell: Cell<{ _id: string }, boolean>) => {
        const projectId = cell.row.original._id;
        return (
          <Button
            onClick={()=>handleClick(!!cell.value)}
            onFocus={()=>setCurrentId(projectId)}
            isLoading={currentId === projectId && updateProjectLoading}
          >
            {cell.value ? <MdDone size={30} color='green' /> : <IoMdClose size={30} color='grey' />}
          </Button>
        );
      },
    },
  };

  return (
    <Box pt={{base: '30px', md: '80px', xl: '80px'}}>
      <>
        {criteriasData && <ListTable
          variant='underlineRows'
          label={'Editor feedbacks'}
          columnsData={Object.values(columnsData).map(
          // eslint-disable-next-line
          (column: any) => column.columnDef,
          )}
          renderersData={columnsData}
          tableData={editorFeedbacks}
          total={editorFeedbacks?.length}
          filterComp={
            <EditorFeedbackFilters
              filters={filters}
              setFilters={setFilters}
              handleSearch={handleSearch}
            />
          }
          isLoading={loading}
        />}
      </>
    </Box>
  );
}
