import {Text, FormLabel, Flex, Input, Divider, Checkbox, Grid} from '@chakra-ui/react';
import {Dispatch, SetStateAction, useEffect} from 'react';
import {UseFormRegister} from 'react-hook-form';
import {PackageType} from 'views/packages/types';
import EditorsTable from './EditorsTable';
import {useGetCriterias} from 'api/quality-control';
import {Criteria} from 'views/settings/quality-control/types';

export default function ManualEditing(props: {
  setPackageState: Dispatch<SetStateAction<PackageType>>
  packageState: PackageType,
  register: UseFormRegister<PackageType>
  onOpen: ()=>void
}) {
  const {packageState, setPackageState, register, onOpen} = props;

  const [
    {data: criteriaData},
    getCriteriaList,
  ] = useGetCriterias();


  useEffect(() => {
    getCriteriaList({params: {filters: {status: 'active'}}});
  }, []);

  const handleQualityControlChange = (checked: boolean, criteria: Criteria) => {
    setPackageState(
      (prev) =>{
        const currentCriterias = prev.qualityCriterias || [];
        return {
          ...prev,
          qualityCriterias: checked
            ? [...currentCriterias, criteria]
            : currentCriterias.filter((item) => item._id !== criteria._id),
        };
      },
    );
  };

  return (
    <Flex direction='column' mb='30px'>
      <Text
        fontWeight='bold'
        align='center'
        mb={4}
      >
        Manual editing
      </Text>

      <Flex
        direction={'column'}
        alignItems='center'
        w='100%'
      >

        <FormLabel textAlign='center'>
          Editor brief
        </FormLabel>
        <Input
          variant='formInput'
          maxW='800px'
          {...register('editorBrief')}
        />

      </Flex>

      <EditorsTable
        packageState={packageState}
        setPackageState={setPackageState}
        onOpen={onOpen}
      />

      <Flex
        direction={'column'}
        alignItems='center'
        w='100%'
      >
        <FormLabel textAlign='center'>Quality control categories</FormLabel>
        <Grid
          my="5px"
          templateColumns="repeat(2, 1fr)"
          gap={4}
          bg='white'
          p={2}
          borderRadius='15px'
          mb={4}
        >
          {
            criteriaData?.map(
              (criteria: Criteria) => (
                <Checkbox
                  m="5px"
                  value={criteria._id}
                  key={criteria._id}
                  onChange={(e) => handleQualityControlChange(e.target.checked, criteria)}
                  isChecked={packageState.qualityCriterias?.some((c)=> c._id === criteria._id)}
                >
                  {criteria.name}
                </Checkbox>
              ),
            )
          }
        </Grid>
      </Flex>
      <Divider borderWidth="3px" borderColor="#a6a4ff" />
    </Flex>
  );
}
