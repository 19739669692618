import useAxios from './basic/use-axios';

export const useGetEditors = () =>
  useAxios(
    {
      url: '/admin/editor/list',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetEditorCerts = () =>
  useAxios(
    {
      url: '/admin/editor/certs',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetEditor = (editorId: string) =>
  useAxios(
    {
      url: `/admin/editor/${editorId}`,
      method: 'GET',
    },
  );

export const useUpdateEditor = (editorId: string) =>
  useAxios(
    {
      url: `/admin/editor/${editorId}`,
      method: 'PATCH',
    },
    {manual: true},
  );

export const useCreateEditor = () =>
  useAxios(
    {
      url: `/admin/editor`,
      method: 'POST',
    },
    {manual: true},
  );

export const useGetEditorCountries = () =>
  useAxios(
    {
      url: `/admin/editor/countries`,
      method: 'GET',
    },
  );

export const useDeleteEditor = (editorId: string) =>
  useAxios(
    {
      url: `/admin/editor/${editorId}`,
      method: 'DELETE',
    },
    {manual: true},
  );

export const useManageUserStatus = (editorId: string) =>
  useAxios(
    {
      url: `/admin/editor/${editorId}/manage`,
      method: 'PUT',
    },
    {manual: true},
  );

export const useGetAllEditorCerts = () =>
  useAxios(
    {
      url: '/admin/editor/all-certs',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetAllEditorCertCSV = () =>
  useAxios(
    {
      url: '/admin/editor/all-certs/csv',
      method: 'GET',
    },
    {manual: true},
  );

export const useGetAvailableEditorsForPackage = () =>
  useAxios(
    {
      url: `/admin/editor/available-for-package`,
      method: 'GET',
    },
    {manual: true},
  );

export const useRegenerateEditorCert = () =>
  useAxios(
    {
      url: `/admin/editor/regenerate/cert`,
      method: 'POST',
    },
    {manual: true},
  );
