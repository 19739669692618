
import Bugsnag from '@bugsnag/js';
import {
  Box,
  Button,
  Flex,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from '@chakra-ui/react';
import {useGetPackageById} from 'api/packages';
import {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import PackageBasicDataViewTab from './components/view/PackageBasicDataViewTab';
import DeliveryDeadlinesAndPricingViewTab from './components/view/DeliveryDeadlinesAndPricingViewTab';
import PhotographersViewTab from './components/view/PhotographersViewTab';
import ExtrasViewTab from './components/view/ExtrasViewTab';
import EditingViewTab from './components/view/EditingViewTab';
import {ProjectDriver} from './constants';
import PackageManager from './components/PackageManager';
import PackageCard from './components/PackageDataCard';
import {IoArrowBack} from 'react-icons/io5';
import {useLocalStorage} from 'hooks/useLocalStorage';

const defaultTabs = [
  'General information',
  'Delivery deadlines & pricing',
  'Photographers',
];

export default function PackageView() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [previousPath] = useLocalStorage('previousPath', '/intel/packages');
  const {packageId} = useParams();
  const toast = useToast();
  const [tabs, setTabs] = useState(defaultTabs);
  const navigate = useNavigate();

  const [
    {
      data: packageData,
      error,
    },
    getPackage,
  ] = useGetPackageById(packageId);

  useEffect(
    () => {
      if (error) {
        const errorMessage = error.message;
        toast({
          title: 'Could not get package',
          description: errorMessage,
          status: 'error',
          duration: 3000,
        });
        Bugsnag.notify(new Error(errorMessage));
      }
    },
    [error, toast],
  );

  useEffect(
    () => {
      const optionalTabs = [];
      if (showExtrasTab) {
        setTabs((t)=>[...t]);
        optionalTabs.push('Extras');
      }

      if (showEditingTab) {
        optionalTabs.push('Editing');
      }

      setTabs([...defaultTabs, ...optionalTabs]);
    },
    [packageData],
  );

  const showExtrasTab = packageData?.extras?.length > 0
  || packageData?.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC);

  const showEditingTab = packageData?.manualEditingEnabled
  || packageData?.backgroundRemovalEnabled;

  const handleIconClick = () => {
    const path = previousPath.split('/intel')[1];
    navigate(`/intel${path}`);
  };


  return (
    <Box pt={{base: '30px', md: '80px', xl: '80px'}} mt={{base: '80px', md: 0}}>
      {packageData
        ? <>

          <Flex
            justifyContent='space-between'
            alignItems='center'
          >
            <Box
              cursor={'pointer'}
              position="absolute"
              left="20px"
              onClick={handleIconClick}
            >
              <IoArrowBack size={25} />
            </Box>
            <PackageCard packageData={packageData}/>
            <Flex alignSelf='end'>
              <Link to={`/intel/package/${packageData._id}/edit`}>
                <Button variant='photonLight' mt='0px'>Edit</Button>
              </Link>
              <PackageManager packageId={packageData._id} status={packageData.status} refetch={getPackage} />
            </Flex>

          </Flex>

          <Tabs index={selectedTabIndex}>
            <TabList>
              {tabs.map((label, index) => (
                <Tab
                  key={index}
                  w={{base: '20%', md: 'auto'}}
                  onClick={() => setSelectedTabIndex(index)}
                >
                  {label}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              <TabPanel>
                <PackageBasicDataViewTab packageData={packageData}/>
              </TabPanel>
              <TabPanel>
                <DeliveryDeadlinesAndPricingViewTab packageData={packageData}/>
              </TabPanel>
              <TabPanel>
                <PhotographersViewTab packageData={packageData}/>
              </TabPanel>
              {
                showExtrasTab && <TabPanel>
                  <ExtrasViewTab packageData={packageData}/>
                </TabPanel>
              }
              {
                showEditingTab && <TabPanel>
                  <EditingViewTab packageData={packageData}/>
                </TabPanel>
              }
            </TabPanels>
          </Tabs>
        </>
        : <Flex justifyContent='center' mt='20px'>
          <Spinner/>
        </Flex>
      }


    </Box>
  );
}
