

import {Checkbox, Flex, InputGroup, Text} from '@chakra-ui/react';
import {getPackages} from 'api/packages';
import {AsyncSelect} from 'chakra-react-select';
import {getCurrentPackageName, loadPackageOptionsWithDebounce} from 'utils/load-package-options';
import {citySelectorStyles} from 'views/creative/helpers/getCities';
import {RemoveInputContent} from './RemoveInputContent';
import {useEffect, useState} from 'react';
import {ProjectListFilterTypes} from 'views/projects/types';
import {Moment} from 'moment';
import {EditorFeedbackFilterTypes} from 'views/editor-feedbacks/types';

export default function PackageFilterInput(props: {
    packageId: string,
    handleFilterChange:
    ((value: string [] | string | Moment | boolean, name: keyof ProjectListFilterTypes) => void)
    | ((value: string | Date | boolean, name: keyof EditorFeedbackFilterTypes) => void )
}) {
  const {packageId, handleFilterChange} = props;
  const [showInactivePackages, setShowInactivePackages] = useState(false);
  const loadPackageOptions = loadPackageOptionsWithDebounce();
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const packages = await getPackages();
      setPackages(packages);
    };

    fetchData();
  }, []);


  return (
    <Flex direction='column' mb={4}>
      <Flex justifyContent='space-between'>
        <Text>Package name:</Text>
        <Checkbox
          size={'sm'}
          isChecked={showInactivePackages}
          onChange={(e) => {
            setShowInactivePackages(e.target.checked);
          }}
        >
          <Text fontSize='13px'>
            Show inactive packages
          </Text>
        </Checkbox>
      </Flex>

      <InputGroup zIndex={1}>
        <AsyncSelect
          placeholder='Select package'
          loadOptions={(searchText, callback) =>
            loadPackageOptions(searchText, callback, showInactivePackages, getPackages)
          }
          onChange={(e) => handleFilterChange(e.value, 'packageId')}
          chakraStyles={citySelectorStyles}
          isClearable={!!packageId}
          components={{
            DropdownIndicator: () => null,
            ClearIndicator: () => (
              <RemoveInputContent
                handleFilterChange={handleFilterChange}
                name={'packageId'}
              />
            ),
          }}
          value={
            packageId
              ? {
                label: getCurrentPackageName(packageId, packages),
                value: packageId,
              }
              : {label: 'Select package name', value: ''}
          }
        />
      </InputGroup>
    </Flex>
  );
}


