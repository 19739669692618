import {Text, Box, Grid, Flex, Button} from '@chakra-ui/react';
import {EditingType, ExtraType, ProjectDriver} from 'views/packages/constants';
import {
  Currency as CurrencyType,
  ExtraUnit as ExtraUnitType,
  PackageType,
} from 'views/packages/types';
import FormErrors from '../../FormErrors';
import FormStepperButtons from '../../TabStepperButtons';
import {zodResolver} from '@hookform/resolvers/zod';
import {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {getExtrasSchema} from '../../shemas';
import ExtraEditor from './ExtraEditor';
import DynamicExtraEditor from './DynamicExtraEditor';

export default function ExtrasEditTab(props: {
  nextFormStep: () => void,
  prevFormStep: () => void,
  packageState: PackageType,
  setPackageState: Dispatch<SetStateAction<PackageType>>
  startFinishProcess: ()=>void
}) {
  const {packageState, setPackageState, nextFormStep, prevFormStep, startFinishProcess} = props;
  const [extraErrors, setExtraErrors] = useState({});

  const {
    register,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
  } = useForm({
    resolver: zodResolver(getExtrasSchema(packageState)),
    defaultValues: packageState,
  });


  const onSubmit = (data: PackageType) => {
    const newErrors: Record<string, Record<string, { message: string }>> = {};


    packageState.extras?.forEach((extra) => {
      const extraErrorFields: Record<string, { message: string }> = {};

      const hasAmount = !!extra.amount;
      const hasCreativeAmount = !!extra.creativeAmount;

      if (!hasAmount && !hasCreativeAmount) {
        extraErrorFields.amount = {message: 'Client or photographer price is required'};
      }

      Object.entries(extra).forEach(([key, value]) => {
        if (!value && key !== 'amount' && key !== 'creativeAmount') {
          extraErrorFields[key] = {message: 'Field is required'};
        }
      });

      if (Object.keys(extraErrorFields).length > 0) {
        newErrors[extra.name] = extraErrorFields;
      }
    });


    if (Object.keys(newErrors).length > 0) {
      setExtraErrors(newErrors);
      return;
    }

    setPackageState((prevState) => (
      {
        ...prevState,
        ...data,
      }
    ));

    if (isLastStep) {
      startFinishProcess();
    } else {
      nextFormStep();
    }
  };

  const handleSwitch = (checked: boolean, driver: string, removeDriver?: string) => {
    setPackageState((prev) => {
      let updatedDrivers = [...prev.projectDrivers];

      if (checked) {
        if (removeDriver) {
          updatedDrivers = updatedDrivers.filter((d) => d !== removeDriver);
        }
        if (!updatedDrivers.includes(driver)) {
          updatedDrivers.push(driver);
        }
      } else {
        updatedDrivers = updatedDrivers.filter((d) => d !== driver);
      }

      return {
        ...prev,
        projectDrivers: updatedDrivers,
      };
    });
  };

  const handleNumberInputChange = (stringValue: string, name: string) => {
    const value = parseFloat(stringValue.replace(',', '.'));
    setValue(name as keyof PackageType, value);
  };

  const addNewExtra = ()=>{
    const newExtraObj = {
      name: '',
      invoiceName: '',
      currency: 'EUR' as CurrencyType,
      unit: 'pc' as ExtraUnitType,
      amount: 0,
      creativeAmount: 0,
    };
    setPackageState(
      (prev)=> (
        {...prev,
          extras: [...prev.extras || [], newExtraObj],
        }
      ),
    );
  };

  useEffect(() => {
    if (
      packageState
      && packageState.extraTypes?.includes(ExtraType.BASIC_EXTRA_IMAGES)
      && !packageState.extras?.some((e) => e.name === 'extraPics')
    ) {
      setPackageState((prev) => {
        if (prev.extras?.some((e) => e.name === 'extraPics')) return prev;

        const newExtraObj = {
          name: 'extraPics',
          invoiceName: 'Extra images',
          currency: 'EUR' as CurrencyType,
          amount: 0,
          creativeAmount: 0,
        };

        return {
          ...prev,
          extras: [...prev.extras || [], newExtraObj],
        };
      });
    }
  }, []);

  const watchCurrency = watch('dynamicPricing.currency');
  const isLastStep = packageState.editingSettings.includes(EditingType.NO_EDITING);

  return (
    <Box w='100%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          direction='column'
          mb='30px'
          alignItems='center'
        >
          <Text
            align='center'
            fontSize='16px'
            mb='10px'
            fontWeight='bold'
          >
            {packageState.extraTypes?.includes(ExtraType.DYNAMIC_EXTRA) && 'Dynamic extras'}
            {!packageState.extraTypes?.includes(ExtraType.DYNAMIC_EXTRA) && (
              <>
                { packageState.extraTypes?.includes(ExtraType.BASIC_EXTRA_IMAGES)
                    && !packageState.extraTypes?.includes(ExtraType.OTHER_EXTRA)
                    && 'Basic extra images'}
                {
                  packageState.extraTypes?.includes(ExtraType.BASIC_EXTRA_IMAGES)
                    && packageState.extraTypes?.includes(ExtraType.OTHER_EXTRA)
                    && 'Basic extra images & Other extras'
                }
                {
                  !packageState.extraTypes?.includes(ExtraType.BASIC_EXTRA_IMAGES)
                    && packageState.extraTypes?.includes(ExtraType.OTHER_EXTRA) && 'Other extras'
                }
              </>
            )}
          </Text>

          {
            packageState.extraTypes?.includes(ExtraType.OTHER_EXTRA)
              && <Button w='150px' variant='photonMedium' mt='0px' onClick={addNewExtra}>Add new extra</Button>
          }
        </Flex>

        <Grid
          templateColumns={{md: 'repeat(1, 1fr)', lg: 'repeat(2, 1fr)'}}
          gap={4}
          autoFlow="row dense"
        >
          {
            packageState.projectDrivers?.includes(ProjectDriver.PRICING_DYNAMIC)
                && <DynamicExtraEditor
                  packageState={packageState}
                  setPackageState={setPackageState}
                  handleSwitch={handleSwitch}
                  handleNumberInputChange={handleNumberInputChange}
                  register={register}
                  currency={watchCurrency}/>
          }

          <ExtraEditor
            packageState={packageState}
            setPackageState={setPackageState}
            handleSwitch={handleSwitch}
          />
        </Grid>

        <Flex
          w='100%'
          justifyContent='center'
          direction='column'
          alignItems='center'
        >
          <FormErrors errors={{...errors, ...extraErrors}}/>
          <FormStepperButtons prevFormStep={prevFormStep} isLastStep={isLastStep}/>
        </Flex>
      </form>
    </Box>
  );
}
